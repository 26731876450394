import React, { useState } from 'react';
import "../../styles/components/Slider.css";
import defaultPic from "../../Images/nopic.jpeg";
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

const ImageSliderSm = ({ slides }) => {
    const [current, setCurrent] = useState(0);
    const length = slides.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    return (
        <section className='slider_sm'>
            {
                length === 0 ?
                    <div className='slider_sm'>
                        <img src={defaultPic} className="image_sm" alt="no pic" />
                    </div> :
                    slides.map((slide, index) => {
                        return (
                            <div
                                className={index === current ? 'active_sm' : 'slide_sm'}
                                key={index}
                            // style={{ backgroundImage: slide?.src ? `url(${process.env.REACT_APP_CLOUDFRONT}/${slide?.src})` : `url(${process.env.REACT_APP_CLOUDFRONT}/${slide?.image})`, backgroundSize: 'cover' }}
                            >
                                {length > 1 && <div className='arrow_div_sm'> <div className='left-arrow_sm' onClick={prevSlide}><ArrowCircleLeftOutlinedIcon fontSize={'24px'} style={{ color: 'white', background: 'rgba(0,0,0,0.5)', borderRadius: '50%' }} /></div>
                                    <div className='right-arrow_sm' onClick={nextSlide}><ArrowCircleRightOutlinedIcon fontSize={'24px'} style={{ color: 'white', background: 'rgba(0,0,0,0.5)', borderRadius: '50%' }} /></div> </div>}
                                {index === current && (
                                    <img src={slide.src ? `${process.env.REACT_APP_CLOUDFRONT}/${slide?.src}` : `${process.env.REACT_APP_CLOUDFRONT}/${slide?.image}`} alt='travel' className='image_sm' />
                                )}
                            </div>
                        );
                    })}
        </section>
    );
};

export default ImageSliderSm;