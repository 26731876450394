import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import { useDispatch } from 'react-redux';
import './App.css';
import { AppBar } from "./components/AppBar/AppBar";
import { AuthForm } from "./components/AuthModal";
import ChangePasswordForm from "./components/ChangePassword/ChangePassword";
import { Footer } from "./components/Footer/Footer";
import TransitionsModal from "./components/Modal";
import { Navbar } from './components/Navbar/Navbar';
import { ProfileForm } from "./components/ProfileForm/ProfileForm";
import { EventDetails } from "./Views/EventDetails/EventDetails";
import { HomePage } from './Views/HomePage/HomePage';
import { NotFoundPage } from "./Views/NotFoundPage/NotFoundPage";
import { OrganizerDetails } from "./Views/OrganizerDetails/OrganizerDetails";
import { SearchMap } from "./Views/SearchMap/SearchMap";
import { UpcomingLeaguesPage } from "./Views/UpcomingLeagues/UpcomingLeagues";
import { UpcomingTournamentPage } from "./Views/UpcomingTournament/UpcomingTournament";
import { insertCoordinates } from "./store/features/Data";
import { PickUpPage } from "./Views/PickUpPage/PickUpPage";
import { localStorageEnums } from "./constants/LocalStorageEnums";
import { BackToTop } from "./components/backToTop";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TermsAndConditions } from "./Views/TCP/TermsAndConditions";
import { PrivacyPolicy } from "./Views/TCP/PrivacyPolicy";
import { CategoryPage } from "./Views/CategoryPage/CategoryPage";
import { isMobile } from "./Utils/getDeviceInfo";
import ContinueInBrowser from "./components/ContinueInBrowser";
import { DeleteAccountForm } from "./components/DeleteAccount/DeleteAccountForm";

function App() {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = React.useState(false);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [openDeleteAccount, setOpenDeleteAccount] = React.useState(false);

  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const [top, setTop] = React.useState("-500vh");
  const isMobileTrue = isMobile(window);
  const isContinueInBrowser = window.sessionStorage.getItem("continueInBrowser") || false;
  const [openMobileModal, setOpenMobileModal] = React.useState(isMobile(window));

  const pageScroll = () => {
    window.onscroll = function (ev) {
      if (window.scrollY > 300) {
        setTop("80vh");
      }
      else {
        setTop("-100%");
      }
    };
  };

  React.useEffect(() => {
    try {
      window.navigator.geolocation.getCurrentPosition(async ({ coords }) => {
        const { latitude, longitude } = coords;
        dispatch(insertCoordinates({ lat: latitude, lng: longitude }));
        window.localStorage.setItem("lat", latitude);
        window.localStorage.setItem("lng", longitude);
        window.localStorage.setItem(localStorageEnums.LOCATIONSTATUS, "granted");
      });
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    try {
      window.navigator.permissions.query({ name: 'geolocation' }).then(function (status) {
        if (status.state === "granted") {
          window.localStorage.setItem(localStorageEnums.LOCATIONSTATUS, "granted");
        }
        else {
          window.localStorage.setItem(localStorageEnums.LOCATIONSTATUS, "denied");
        }
      })
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    dispatch(insertCoordinates({ lat: window.localStorage.getItem("lat"), lng: window.localStorage.getItem("lng") }));
    // eslint-disable-next-line
  }, [window.localStorage.getItem(localStorageEnums.LOCATIONSTATUS)]);

  const continueInBrowserFunc = () => {
    setOpenMobileModal(false);
    window.sessionStorage.setItem("continueInBrowser", true);
  }

  React.useEffect(() => {
    pageScroll();
    //eslint-disable-next-line
  }, [window.scrollY]);

  const theme = createTheme({
    typography: {
      fontFamily: 'Montserrat, sans- serif',
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'Montserrat', sans-serif';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }
      `,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div>
          <BackToTop top={top} />
          <Navbar handleOpen={() => setOpenModal(!openModal)} handleOpenDeleteAccount={() => setOpenDeleteAccount(!openDeleteAccount)} handleOpenProfile={() => setOpenProfile(!openProfile)} />
          <Switch>
            <Route path="/" exact>
              <HomePage />
            </Route>
            <Route path="/search-by-maps" exact>
              <SearchMap />
            </Route>
            <Route path="/upcoming-leagues" exact>
              <UpcomingLeaguesPage />
            </Route>
            <Route path="/upcoming-tournaments" exact>
              <UpcomingTournamentPage />
            </Route>
            <Route path="/pickup-games" exact>
              <PickUpPage />
            </Route>
            <Route path="/upcoming-leagues/:oid" exact>
              <UpcomingLeaguesPage />
            </Route>
            <Route path="/upcoming-tournaments/:oid" exact>
              <UpcomingTournamentPage />
            </Route>
            <Route path="/pickup-games/:oid" exact>
              <PickUpPage />
            </Route>
            <Route path="/terms-and-conditions" exact>
              <TermsAndConditions />
            </Route>
            <Route path="/privacy-policy" exact>
              <PrivacyPolicy />
            </Route>
            <Route path="/event/:event/:id" exact>
              <EventDetails handleOpen={() => setOpenModal(!openModal)} />
            </Route>
            <Route path="/category/:type/:id" exact>
              <CategoryPage />
            </Route>
            <Route path="/organizer-details/:id" exact>
              <OrganizerDetails handleOpen={() => setOpenModal(!openModal)} />
            </Route>
            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
          <AppBar handleOpenProfile={() => setOpenProfile(!openProfile)} />
          <TransitionsModal open={openModal} handleClose={() => setOpenModal(false)}>
            <AuthForm handleClose={() => setOpenModal(false)} />
          </TransitionsModal>
          <TransitionsModal open={openProfile} handleClose={() => setOpenProfile(false)}>
            <ProfileForm setOpenChangePassword={() => setOpenChangePassword(true)} handleClose={() => setOpenProfile(false)} />
          </TransitionsModal>
          <TransitionsModal open={openDeleteAccount} handleClose={() => setOpenDeleteAccount(false)}>
            <DeleteAccountForm handleClose={() => setOpenDeleteAccount(false)} />
          </TransitionsModal>
          <TransitionsModal open={openChangePassword} handleClose={() => setOpenChangePassword(false)}>
            <ChangePasswordForm handleClose={() => setOpenModal(false)} />
          </TransitionsModal>
          <ContinueInBrowser open={isMobileTrue && openMobileModal && !isContinueInBrowser} handleClose={continueInBrowserFunc} />
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
