import React from "react";
import GoogleMapReact from 'google-map-react';
import "../../styles/components/googleMap.css";
import { multiSportColor } from "../../constants/colors";
import MarkerPinWithoutHover from "./MarkerWithoutHover";

export default function MapWithoutHover(props) {
    const defaultProps = {
        center: {
            lat: props?.lat,
            lng: props?.lng
        },
        zoom: 11,
        focus: {
            lat: props?.events.length > 0 ? Number(props?.events[0]?.latitude) : props?.lat,
            lng: props?.events.length > 0 ? Number(props?.events[0]?.longitude) : props?.lng
        }
    };

    return (
        <div style={{ height: props?.height, width: '100%', marginTop: '15px', marginBottom: '15px' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GCP_KEY }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                center={defaultProps.focus}
                options={{ scrollwheel: false }}
            >
                {props?.events?.map((event, index) => {
                    return <MarkerPinWithoutHover
                        defaultMarker={false}
                        key={index}
                        link={"#"}
                        lat={event?.latitude}
                        lng={event?.longitude}
                        name={event?.name}
                        src={event?.data[0].iconMedia}
                        color={event.data?.length > 1 ? multiSportColor :  event?.data[0].bgColor}
                        event={event}
                    />
                })}
            </GoogleMapReact>
        </div>
    );
}