import React from 'react'
import ImageSlider from '../../components/Slider/Slider';
import "./OrganizerDetails.css";
import league from "../../Images/league.png";
import tournament from "../../Images/tournament.png";
import pickup from "../../Images/pickup.png";
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import { FavUnFavOrg, fetchEventsListByOID, fetchOrganizerInfoByID } from '../../Services/OrganizerDetails.services';
import swal from 'sweetalert';
import defaultLogo from "../../Images/assmbly_logo.jpeg"
import { abbreviate } from '../../Utils/abbreviators';
import { UpcomingLeagues } from '../../components/HomePageComponents/UpcomingLeagues';
import { UpcomingTournaments } from '../../components/HomePageComponents/UpcomingTournaments';
import { PickUpGames } from '../../components/HomePageComponents/PickupGame';
import { TYPES } from '../../constants/SportTypes';
import { useParams } from 'react-router-dom';
import { LoadingSpinnerComponent } from '../../components/LoadingSpinnerComponent';
import MapWithoutHover from '../../components/GoogleMaps/MapWithoutHover';
import { localStorageEnums } from '../../constants/LocalStorageEnums';
import { ListItemIcon } from '@mui/material';
import { useStyles } from '../../styles/components/styles';
import { filteredEvents, getCategoryUsingFor, getUserDetailsPageView, mergeDuplicateOrganizersEvents } from '../../Utils/helpers';
import { useSelector } from 'react-redux';
import { SEGMENT_ACTIONS } from '../../Utils/Favored/actions';
import { combineCustomTrackSegmentPixel, combinePageViewSegmentPixel } from '../../Utils/Favored';

export const OrganizerDetails = (props) => {
    const classes = useStyles();
    const { id } = useParams();
    const searchText = useSelector((state) => state.dataManager.searchText);
    const [leagueLists, setLeagueLists] = React.useState([]);
    const [tournamentLists, setTournamentLists] = React.useState([]);
    const [pickupLists, setPickupLists] = React.useState([]);
    const [favourite, setFavourite] = React.useState(false);
    const [sportsList, setSportsList] = React.useState([]);
    const [sportsMapList, setSportsMapList] = React.useState([]);
    const [organizerData, setOrganizerData] = React.useState();
    const [sportId, setSportId] = React.useState([]);
    const [checkboxData, setCheckBoxData] = React.useState({
        league: false,
        pickup: false,
        tournament: false,
    });
    const [spinner, setSpinner] = React.useState(false);
    const userData = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA));


    React.useEffect(() => {
        const fetchOrganizerById = async () => {
            try {
                setSpinner(true);
                const response = await fetchOrganizerInfoByID(id);
                const data = await response.data;
                const { name, email, phone, website, addressLine1, addressLine2, description, organizerLogo, organizerImage, user, sportsEvent, event } = data?.data?.organizer;
                if (response.status === 200) {
                    setOrganizerData({
                        name,
                        email,
                        phone,
                        website,
                        addressLine1,
                        addressLine2,
                        description,
                        organizerLogo,
                        organizerImage,
                        user
                    });
                    setFavourite(data?.data?.isFavorite);
                    setCheckBoxData({
                        league: sportsEvent?.name?.includes("league"),
                        pickup: sportsEvent?.name?.includes("pickup"),
                        tournament: sportsEvent?.name?.includes("tournament"),
                    });
                    const sports = [];
                    event.forEach((val) => {
                        if (val?.latitude && val?.longitude && val?.sport?.length) {
                            val?.sport?.forEach((sport) => {
                                const singleData = {
                                    id: sport?.id,
                                    latitude: val?.latitude,
                                    longitude: val?.longitude,
                                    name: sport?.name,
                                    iconMedia: sport?.iconMedia,
                                    eventData: val.eventData,
                                    bgColor: sport?.bgColor
                                };
                                sports.push(singleData);
                            });
                        }
                    });
                    setSportsMapList(sports);
                    const result = sports.reduce((unique, o) => {
                        if (!unique.some((obj) => obj.name === o.name)) {
                            unique.push(o);
                        }
                        return unique;
                    }, []);
                    setSportsList(result);
                    let ids = [];
                    result?.forEach((sport) => {
                        ids?.push(sport?.id);
                    })
                    setSportsArray(ids);
                    setSportId(ids);
                }
                setSpinner(false);
            } catch (err) {
                setSpinner(false);
                swal("Error", err.response?.data?.message || "", "error");
            }
        }
        if (id)
            fetchOrganizerById();
        // eslint-disable-next-line
    }, [id]);

    const openWebsite = (website) => {
        if (website.includes("http")) {
            window.open(
                website,
                '_blank',
                'noopener,noreferrer'
            )
        } else {
            window.open(
                `https://${website}`,
                '_blank',
                'noopener,noreferrer'
            )
        }
    }

    React.useEffect(() => {
        const fetchList = async () => {
            try {
                setSpinner(true);
                const response = await fetchEventsListByOID(id);
                if (response.status === 200) {
                    const data = await response.data;
                    setPickupLists(data.data?.data?.filter(event => event?.type === TYPES.PICKUP));
                    setLeagueLists(data.data?.data?.filter(event => event?.type === TYPES.LEAGUE));
                    setTournamentLists(data.data?.data?.filter(event => event?.type === TYPES.TOURNAMENT));
                }
                setSpinner(false);
            } catch (err) {
                setSpinner(false);
                swal('Error', err.response?.data?.message || "", 'error');
            }
        };
        if (id)
            fetchList();
        // eslint-disable-next-line
    }, [id]);

    const toggleFavourite = async (value) => {
        if (!window.localStorage.getItem(localStorageEnums.TOKEN)) {
            props?.handleOpen();
        }
        else {
            try {
                setFavourite(value);
                const segmentObj = {
                    organizerId: id,
                    userId: userData?.id,
                    usingFor: getCategoryUsingFor(userData?.usingFor || ""),
                    isFavorite: value
                };
                await FavUnFavOrg(id, value);
                combineCustomTrackSegmentPixel(value ? SEGMENT_ACTIONS.CLICKS_FAVOURITE_BTN : SEGMENT_ACTIONS.CLICKS_UNFAVOURITE_BTN, segmentObj);
            }
            catch (err) {
                swal("Error", err.response?.data?.message || "", "error");
            }
        }
    }


    React.useEffect(() => {
        window.scrollTo(0, 0);
        combinePageViewSegmentPixel(getUserDetailsPageView(userData, "Organizer Details"));
        document.getElementById("searchInput").value = searchText;
        // eslint-disable-next-line
    }, []);

    const [sportsArray, setSportsArray] = React.useState('');

    const handleChange = (value) => {
        let lists = [];
        if (value[value.length - 1] === "all") {
            if (sportsArray.length !== sportsList.length) {
                for (let i = 0; i < sportsList.length; i += 1) {
                    lists.push(sportsList[i].id)
                }
                setSportsArray(lists);
                setSportId(lists);
            }
            else {
                setSportsArray([]);
                setSportId([])
            }
            return;
        }
        setSportsArray(
            typeof value === 'number' ? value.split(',') : value
        );
        setSportId(value);
    }

    const isAllSelected =
        sportsList.length > 0 && sportsArray.length === sportsList.length;

    return (
        <div className='event_details_main_body'>
            <div className='event_details_main'>
                <div className='event_details_flex_carousel'>
                    <div className='carousel_div'>
                        <LoadingSpinnerComponent loader={spinner}>
                            <ImageSlider slides={organizerData?.organizerImage || []} />
                        </LoadingSpinnerComponent>
                    </div>
                    <div className='event_details_div'>
                        {organizerData ?
                            <>
                                <div className='event_details_flex_row'>
                                    <span className='event_detail_heading'>{organizerData?.name || ""}</span>
                                    <span className='heart_div' onClick={() => toggleFavourite(!favourite)}>
                                        {favourite ? <FavoriteIcon style={{ color: 'white' }} /> : <FavoriteBorderOutlinedIcon style={{ color: 'white' }} />}
                                    </span>
                                </div>

                                <div className='divider_with_spacing' />

                                <div className='event_details_venue_amenities'>
                                    <p>Sport Events</p>
                                    <div className='event_details_venue_amenities_flex'>
                                        {checkboxData.league && <div className='sport_type_card'>
                                            <img alt="logo" src={league} className='sport_card_div' /> <span>League</span>
                                        </div>}
                                        {checkboxData.pickup && <div className='sport_type_card'>
                                            <img alt="logo" src={pickup} className='sport_card_div' /> <span>Pickup Games</span>
                                        </div>}
                                        {checkboxData.tournament && <div className='sport_type_card'>
                                            <img alt="logo" src={tournament} className='sport_card_div' /> <span>Tournament</span>
                                        </div>}
                                    </div>
                                </div>
                                <div className='divider_with_spacing' />
                                <FormControl style={{ width: '100%' }} color="warning">
                                    <InputLabel id="demo-multiple-checkbox-label">{sportId.length > 0 ? "Sport Type" : "Multisport"}</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        color='warning'
                                        value={sportId}
                                        label={sportId.length > 0 ? "Sport Type" : "Multisport"}
                                        onChange={(e) => handleChange(e.target.value)}
                                        multiple
                                        input={<OutlinedInput label={sportId.length > 0 ? "Sport Type" : "Multisport"} />}
                                        renderValue={() => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                                {
                                                    sportId.length > 1 ? <span>Multisport</span> : sportId?.map((value, index) => (
                                                        <span key={index} style={{ color: '#000' }}>
                                                            {sportsList?.find((addon) => addon?.id === value)?.name}{' '}
                                                        </span>
                                                    ))
                                                }
                                            </Box>
                                        )}
                                    >
                                        <MenuItem
                                            value="all"
                                            classes={{
                                                root: isAllSelected ? classes.selectedAll : ""
                                            }}
                                        >
                                            <ListItemIcon>
                                                <Checkbox
                                                    classes={{ indeterminate: classes.indeterminateColor }}
                                                    checked={isAllSelected}
                                                    color="warning"
                                                    indeterminate={
                                                        sportsArray.length > 0 && sportsArray.length < sportsList.length
                                                    }
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.selectAllText }}
                                                primary="Multisport"
                                            />
                                        </MenuItem>
                                        {sportsList?.map((addon) => (
                                            <MenuItem
                                                key={addon?.id}
                                                value={addon?.id}
                                                style={{
                                                    fontWeight: sportId?.find((add) => addon.id === add) ? '600' : '300',
                                                    backgroundColor: sportId?.find((add) => addon.id === add) ? '#faf5e6' : '#fff',
                                                }}
                                            >
                                                <Checkbox color='warning' checked={sportsArray?.indexOf(addon?.id) > -1} />
                                                <ListItemText
                                                    primary={addon?.name}
                                                />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <div className='divider_with_spacing' />
                                <MapWithoutHover height={"250px"} lat={Number("29.772600")} lng={Number("-95.514500")} events={mergeDuplicateOrganizersEvents(sportId.length === 0 ? sportsMapList : sportsMapList?.filter((event) => sportsArray.includes(event?.id)))} />
                                <div className='divider_with_spacing' />
                                <div className='organizer_flex_div'>
                                    <div className='organizer_flex_div_left'>
                                        <div className='event_detail_organizer'>
                                            <img src={organizerData?.organizerLogo ? `${process.env.REACT_APP_CLOUDFRONT}/${organizerData?.organizerLogo}` : defaultLogo} style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }} alt="Hi" /> <span>{organizerData?.name || ""}</span>
                                        </div>
                                        <p className='organizer_description'>{organizerData?.description || ""}</p>
                                    </div>
                                    <div className='organizer_flex_div_right'>
                                        <p>Contact Info</p>
                                        <div className='organizer_contact_info_flex_colum'>
                                            <div className='organizer_contact_info_flex_list'>
                                                <CallOutlinedIcon className='icon_color' />
                                                <div>
                                                    <span className='organizer_contact_info_flex_list_header'>Phone</span>
                                                    <a className='no_decoration_a_href' href={`tel:${organizerData?.phone}`}>
                                                        <span className='organizer_contact_info_flex_list_detail'>{organizerData?.phone || ""}</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className='organizer_contact_info_flex_list'>
                                                <EmailOutlinedIcon className='icon_color' />
                                                <div>
                                                    <span className='organizer_contact_info_flex_list_header'>Email</span>
                                                    <a className='no_decoration_a_href' href={`mailto:${organizerData?.email}`}>
                                                        <span className='organizer_contact_info_flex_list_detail'>{organizerData?.email || ""}</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className='organizer_contact_info_flex_list'>
                                                <LanguageOutlinedIcon className='icon_color' />
                                                <div>
                                                    <span className='organizer_contact_info_flex_list_header'>Webiste</span>
                                                    <div className='no_decoration_a_href' onClick={() => openWebsite(organizerData?.website)} title={organizerData?.website}>
                                                        <span className='organizer_contact_info_flex_list_detail'>{organizerData?.website ? abbreviate(organizerData?.website, 30) : ""}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> :

                            <div className='loader_div'>
                                <LoadingSpinnerComponent loader={spinner}>
                                    <p>No data available</p>
                                </LoadingSpinnerComponent>
                            </div>}
                    </div>
                </div>
            </div>
            <UpcomingLeagues events={filteredEvents(leagueLists, searchText) || []} background="white" id={id} />
            <UpcomingTournaments events={filteredEvents(tournamentLists, searchText) || []} background="#e9e9e9a6" id={id} />
            <PickUpGames events={filteredEvents(pickupLists, searchText) || []} background="white" id={id} />
        </div>
    )
}
