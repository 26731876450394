import React from 'react'

export const GenderCategoryInfo = (props) => {
    return (
        <div className='event_details_location'>
            <div className='event_details_location_flex'>
                <img alt='sports' src={`${props?.src}`} className='filter_png_img_gender' />
                <span className='event_details_date_title' title={props?.title}>{props.title}<br />
                </span>
            </div>
        </div>
    )
}
