function toRad (number) {
    return number * Math.PI / 180;
};

export const getDistance = (lat1, lon1, lat2, lon2) => {
    var R = 3956; // km 6361 km OR 3956 mi
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat_1 = toRad(lat1);
    var lat_2 = toRad(lat2);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat_1) * Math.cos(lat_2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d.toFixed(2);
};