import React from 'react'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import SportsOutlinedIcon from '@mui/icons-material/SportsOutlined';
import ThirtyFpsOutlinedIcon from '@mui/icons-material/ThirtyFpsOutlined';
import { abbreviate } from '../../Utils/abbreviators';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { DATE_FORMAT } from '../../constants/DateFormat';


export const HoveredCard = ({ event, name, toggle, category }) => {
    return (
        <Link to={`/event/${event?.type}/${event?.eventId}`} className='marker_div_event_detail' style={{ opacity: !toggle ? '0' : '1' }}>
            {event?.img &&
                <div style={{ backgroundImage: `url('${`${process.env.REACT_APP_CLOUDFRONT}/${event?.img}`}')`, backgroundSize: 'cover' }}>
                    <img alt={name} className='marker_div_event_detail_img' src={`${process.env.REACT_APP_CLOUDFRONT}/${event?.img}`} />
                </div>
            }
            <div className='marker_div_event_detail_text'>
                <span className='marker_div_event_detail_heading' title={event?.name}>{abbreviate(event?.name, 12)}</span>
                <div className='event_details_location_flex'>
                    <img alt='sports' src={`${process.env.REACT_APP_CLOUDFRONT}/${category?.icon}`} className='filter_png_img_xs' />
                    <span title={category?.name}>{abbreviate(category.name, 40)}<br />
                    </span>
                </div>
                <div>
                    <CalendarTodayOutlinedIcon className='icon_xs' /> <span>{moment(event?.startDate).format(DATE_FORMAT)} {event?.endDate ? ` - ${moment(event?.endDate).format(DATE_FORMAT)}` : ""}</span>
                </div>
                <div>
                    <PlaceOutlinedIcon className='icon_xs' /> <span title={event?.address}>{abbreviate(event?.address, 25)}<br /></span>
                </div>
                {event?.games ? <div>
                    <DirectionsRunOutlinedIcon className='icon_xs' /> <span>Games: {event?.games}</span>
                </div> : null}
                <div>
                    <SportsOutlinedIcon className='icon_xs' /> <span>Referee: {event?.refree ? "Yes" : "No"}</span>
                </div>
                <div>
                    <ThirtyFpsOutlinedIcon className='icon_xs' /> <span>{event?.ageLimit}</span>
                </div>
            </div>
        </Link>
    )
}
