import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import "../../styles/components/ContinueInBrowser.css";
import logo from "../../Images/assmbly_logo.jpeg";

const style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, 0%)',
    width: window.innerWidth - 50,
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none',
    outline: 'none',
    p: 4,
    overflowY: 'scroll',
    borderRadius: '8px',
    maxHeight: '100vh'
};

export default function ContinueInBrowser({ open, handleClose }) {

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <img className='logo_modal' src={logo} alt="logo" />
                        <div className='open_in_app_btn'>
                            <a href='https://assymbly.page.link/data' style={{ textDecoration: 'none', color: '#fff' }}>Open in App</a>
                        </div>
                        <div className='open_in_browser_btn' onClick={handleClose}>
                            <p>Continue in Browser</p>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
