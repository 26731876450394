import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useStyles } from '../../styles/components/styles';
import swal from 'sweetalert';
import { MESSAGES } from '../../constants/ValidationMsgs';
import { isStrongPassword } from '../../Utils/validators';
import { AuthResetPasswordServices } from '../../Services/Auth.services';
import { LoadingText } from '../LoadingText';


export const ResetPassword = (props) => {
    const classes = useStyles();

    const [values, setValues] = useState({
        password: '',
        confirmPassword: '',
        showPassword2: false,
        showPassword3: false
    });
    const [errors, setErrors] = useState({
        cPassword: false,
        password: false,
        confirmPassword: false
    });

    const [spinner, setSpinner] = useState(false);


    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value.trim() });
        if (prop === 'password') {
            setErrors({
                ...errors,
                password: event.target.value.length < 6
            });
        }
        if (prop === 'confirmPassword') {

            setErrors({
                ...errors,
                confirmPassword: values.password !== event.target.value
            });
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (
                values.password === values.confirmPassword &&
                values.password.length >= 6
            ) {
                setSpinner(true);
                const response = await AuthResetPasswordServices(props.email, props.otp, values);
                if (response.status === 201) {
                    setSpinner(false);
                    swal("Success", "Password changed successfully.", "success").then(() => {
                        window.location.reload();
                    })
                }
            }
            else {
                setErrors({
                    password: !isStrongPassword(values.password),
                    confirmPassword: values.password !== values.confirmPassword || !isStrongPassword(values.confirmPassword)
                })
            }
            setSpinner(false);
        } catch (err) {
            setSpinner(false);
            swal("Error", err.response?.data?.message || "", "error");
        }
    };
    return (
        <div className='login_div'>
            <div className={classes.assignmentGrid}>
                <h1>Reset your password</h1>
                <FormControl className={classes.fullWidthTextField} color="primary" variant="outlined">
                    <InputLabel
                        style={{
                            color: errors.password ? '#D10000' : 'grey'
                        }}
                        htmlFor="outlined-adornment-password"
                    >
                        New Password
                    </InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        color='warning'
                        type={values.showPassword2 ? 'text' : 'password'}
                        value={values.password}
                        className={classes.fullWidth}
                        onChange={handleChange('password')}
                        label="New Password"
                        autoComplete="off"
                        error={errors.password}
                    />
                    {errors.password && (
                        <FormHelperText error id="accountId-error">
                            {values?.password?.length === 0
                                ? MESSAGES.PASSWORDREGQUIRED
                                : MESSAGES.INVALIDPASSWORD}
                        </FormHelperText>
                    )}
                </FormControl>
                <FormControl className={classes.fullWidthTextField} color="warning" variant="outlined">
                    <InputLabel
                        style={{
                            color: errors.confirmPassword ? '#D10000' : 'grey'
                        }}
                        htmlFor="outlined-adornment-confirm-password"
                    >
                        Confirm Password
                    </InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-confirm-password"
                        type={values.showPassword3 ? 'text' : 'password'}
                        value={values.confirmPassword}
                        className={classes.fullWidth}
                        onChange={handleChange('confirmPassword')}
                        label="Confirm Password"
                        autoComplete="off"
                        error={errors.confirmPassword}
                    />
                    {(errors.confirmPassword && (values.confirmPassword !== values.password || !values.confirmPassword)) && (
                        <FormHelperText error id="accountId-error">
                            {values?.confirmPassword?.length === 0
                                ? MESSAGES.CONFIRMPASSWORDREQUIRED
                                : MESSAGES.INVALIDCONFIRMPASSWORD}
                        </FormHelperText>
                    )}
                </FormControl>
                <button className='auth_button' onClick={handleSubmit} disabled={spinner} >
                    <LoadingText text="Reset Password" loader={spinner} />

                </button>
            </div>
        </div>
    )
}
