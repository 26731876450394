import { getUserDetailsIdentify } from "../../helpers";

export const PixelPageView = (obj = {}) => {
    window.fbq("track", "PageView", obj);
}

export const PixelCustomTrack = (action, obj) => {
    window.fbq("trackCustom", action, obj);
}

export const PixelIdentifyUser = (action, data) => {
    window.fbq("trackCustom", action, getUserDetailsIdentify(data));
}