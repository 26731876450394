import React from 'react'
import TextField from '@mui/material/TextField';
import "../../styles/components/ProfileForm.css";
import { deleteAccountAPI } from '../../Services/Auth.services';
import swal from 'sweetalert';
import { localStorageEnums } from '../../constants/LocalStorageEnums';
import { LoadingModal } from '../LoadingModal';
import { LoadingText } from '../LoadingText';

export const DeleteAccountForm = (props) => {
    const [loader, setLoader] = React.useState(false);


    let userData = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA));
    const [deleteValue, setDeleteValue] = React.useState("");
    const [deleteValueErr, setDeleteValueErr] = React.useState(false);
   
    const handleChange = (value) => {
        setDeleteValue(value);
        if((value)?.toLowerCase() === "delete"){
            setDeleteValueErr(false);
        }
    }
    const submitForm = async () => {
        try {
            if(deleteValue !== "DELETE"){
                setDeleteValueErr(true);
                return;
            }

            setLoader(true);
            const response = await deleteAccountAPI({id: userData?.id});
            const data = await response.data;
            if (response.status === 200) {
                swal("Success", data.message, "success").then(() => {
                    window.localStorage.clear();
                    window.location.reload();
                });
                setLoader(false);
            }
        } catch (err) {
            setLoader(false);
            swal("Error", err.response?.data?.message || "", "error");
        }
    }

    return (
        <div>
            <h1>Delete Account</h1>
            <LoadingModal loader={false}>
                <div className='auth_form_div'>
                    <div className='login_textfield_div'>
                        <TextField
                            color='warning'
                            id="outlined-basic"
                            style={{ width: '100%' }}
                            label="Delete Account" variant="outlined"
                            value={deleteValue}
                            error={deleteValueErr}
                            autoComplete="off"
                            helperText={deleteValueErr ? "Please type DELETE correctly." : "Type DELETE inorder to delete your account."}
                            onChange={(e) => handleChange(e.target.value)} />
                    </div>
                    <br />
                    <button className='auth_button' onClick={submitForm} disabled={loader}>
                        <LoadingText text="Delete my account" loader={loader} />
                    </button>
                </div>
            </LoadingModal>
        </div>
    )
}
