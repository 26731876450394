import React from 'react'
import { Link, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import { PickupGamesCard } from '../../components/Cards/PickupGamesCard';
import { LoadingComponent } from '../../components/LoadingComponent';
import { filteredEvents, getUserDetailsPageView } from '../../Utils/helpers';
import { fetchFilteredEventsBySportsIDService } from '../../Services/Events.services';
import { combinePageViewSegmentPixel } from '../../Utils/Favored';
import { localStorageEnums } from '../../constants/LocalStorageEnums';

export const CategoryPage = () => {
    const { id, type } = useParams();
    const coordinates = useSelector((state) => state.dataManager.coordinates);
    const searchText = useSelector((state) => state.dataManager.searchText);
    const [leagueList, setLeagueLists] = React.useState([]);
    const [pickupList, setPickupLists] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);
    const [tournamentList, setTournamentLists] = React.useState([]);
    const userData = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA));

    React.useEffect(() => {
        const fetchList = async () => {
            try {
                setSpinner(true);
                const response = await fetchFilteredEventsBySportsIDService([id]);
                if (response.status === 201) {
                    const data = await response.data;
                    const { league = {}, pickup = {}, tournament = {} } = data?.data;
                    if (data?.data) {
                        setLeagueLists(league?.events || []);
                        setPickupLists(pickup?.events || []);
                        setTournamentLists(tournament?.events || []);
                    }
                }
                setSpinner(false);
            } catch (err) {
                swal('Error', err.response?.data?.message || "", 'error');
                setSpinner(false);
            }
        };
        fetchList();
        // eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        window.scrollTo(0, 0);
        combinePageViewSegmentPixel(getUserDetailsPageView(userData, "Category"));
        document.getElementById("searchInput").value = searchText;
        // eslint-disable-next-line
    }, [])


    return (
        <div className='main_div_leagues'>
            <Helmet>
                <title>{type} Games | Assymbly</title>
            </Helmet>
            <div className='upcoming_leagues_background_img'>
                <div className='leagues_details'>
                    <div className='bg_event_date_details'>
                        {type} Games
                    </div>
                    <div className='breadcrumb'>
                        <Link className="router_link" to="/">Home</Link> | <span className='lighter_breadcrumb'> {type} Games </span>
                    </div>
                </div>
            </div>
            <LoadingComponent loader={spinner}>
                {filteredEvents([...tournamentList, ...pickupList, ...leagueList], searchText)?.length === 0 &&
                    <div className='spinner_center_div'>
                        <p>No events available.</p>
                    </div>}
            </LoadingComponent>
            <div className='pickup_page_grid'>
                {filteredEvents([...tournamentList, ...pickupList, ...leagueList], searchText)?.map((event) => <PickupGamesCard key={event?.id} coordinates={coordinates} latitude={event?.eventVenue[0]?.venue?.latitude} longitude={event?.eventVenue[0]?.venue?.longitude} id={event.id} type={event?.type} src={`${process.env.REACT_APP_CLOUDFRONT}/${event.eventImage[0]?.src}`} title={event.name} organizer={event?.organizer?.name} charge={event.individualPrice} dynamicLink={event.dynamicLink} />)}
            </div>
        </div>
    )
}
