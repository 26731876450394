import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

export const LoadingSpinnerComponent = ({ children, loader }) => {
    return (
        <>
            {loader ? < CircularProgress color='warning' /> : children}
        </>
    )
}
