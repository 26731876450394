import React from 'react'
import { PickupGamesCard } from '../../../components/Cards/PickupGamesCard';
import { Link } from 'react-router-dom';

export const PickUpGamesLists = (props) => {

    return (
        <div className='hompage_subcomponent' style={{ backgroundColor: `${props.background}` }}>
            <div className='hompage_subcomponent_header'>
                <div>
                    <span className='location_title'>Pickup Games</span>
                </div>
                <div className='view_all_link'>
                    <Link className='nodecoration_color_link' to={props.id ? `/pickup-games/${props?.id}` : `/pickup-games`}>
                        View All
                    </Link>
                </div>
            </div>
            {props?.events?.length > 0 ?
                <div className='pickup_grid'>
                    {props?.events?.map((event, index) =>
                        <PickupGamesCard key={event?.eventId} featured={index === 0} id={event?.eventId} type={event?.type} src={`${process.env.REACT_APP_CLOUDFRONT}/${event?.logoUrl}`} organizer={event?.organizer?.name} title={event?.name} date={event?.startDate} club={event?.organizer?.name} />)}
                </div> :
                <div className='no_events_available_div'>
                    <p>No Events available.</p>
                </div>}
        </div>
    )
}
