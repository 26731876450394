export const MESSAGES = {
    INVALIDEMAIL: 'Please enter valid email.',
    INVALIDPASSWORD: 'Password must be at least 8 characters long and must contain 1 uppercase, 1 lowercase, 1 special character and 1 digit.',
    EMAILREQUIRED: 'Please enter email.',
    PASSWORDREGQUIRED: 'Please enter password.',
    CONFIRMPASSWORDREQUIRED: 'Please enter confirm password.',
    INVALIDCONFIRMPASSWORD: 'Password and Confirm Password does not match.',
    FULLNAMEREQUIRED: 'Please enter full name.',
    RESEND_OTP_SUCCESS: "OTP resent successfully.",
    AGREE_TANDC: "Please accept the terms and conditions to register."
}