import React from 'react'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { abbreviate } from '../../../Utils/abbreviators';

export const EVentLocation = (props) => {
  return (
      <div className='event_details_location'>
          <div className='event_details_location_flex'>
              <PlaceOutlinedIcon style={{ color: "#E074B2" }} /> <span className='event_details_date_title' title={props?.address}>{abbreviate(props.address,40)}<br />
              <span className='event_details_date_subtitle'>{props?.city}, {props?.state}, {props?.country}, {props?.postcode}</span>
              </span>
          </div>
          <a className='event_details_location_right' target="_blank" rel="noreferrer" href={`http://maps.google.com/?q=${props?.latitude},${props?.longitude}`}>
              <div className='event_details_location_button'><FmdGoodIcon className='location_bubble' /> Get directions</div>
          </a>
      </div>
  )
}
