import React from 'react'
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from 'react-redux';
import "../../styles/HomePage.css";
import { getVideoService } from '../../Services/Events.services';
import { insertCoordinates } from '../../store/features/Data';
import { LoadingComponent } from '../../components/LoadingComponent';
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { SportsNearby } from '../../components/HomePageComponents/SportsNearby';
import { SportsCategory } from '../../components/HomePageComponents/SportsCategory';
import { UpcomingLeagues } from '../../components/HomePageComponents/UpcomingLeagues';
import { UpcomingTournaments } from '../../components/HomePageComponents/UpcomingTournaments';
import { PickUpGames } from '../../components/HomePageComponents/PickupGame';
import { SearchBar } from '../../components/SearchBar';
import TransitionsModal from '../../components/Modal';
import { SearchBox } from '../../components/SearchBox';
import { getCityService } from '../../Services/Auth.services';
import { combinePageViewSegmentPixel } from '../../Utils/Favored';
import { localStorageEnums } from '../../constants/LocalStorageEnums';
import { getUserDetailsPageView } from '../../Utils/helpers';

export const HomePage = () => {
  const dispatch = useDispatch();
  const leagues = useSelector((state) => state.dataManager.leagues);
  const tournaments = useSelector((state) => state.dataManager.tournaments);
  const searchText = useSelector((state) => state.dataManager.searchText);
  const loader = useSelector((state) => state.dataManager.loader);
  const pickup = useSelector((state) => state.dataManager.pickup);
  const sports = useSelector((state) => state.dataManager.sports);
  const categories = useSelector((state) => state.dataManager.categories);
  const [videoSrc, setVideoSrc] = React.useState('');
  const [homePageText, setHomePageText] = React.useState({
    heading: "",
    desc: ""
  });
  const userData = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA));
  const [city, setCity] = React.useState('');
  const [openSearchBox, setOpenSearchBox] = React.useState(false);
  const [coordinates, setCoordinates] = React.useState({
    lat: '',
    lng: ''
  });

  React.useEffect(() => {
    combinePageViewSegmentPixel(getUserDetailsPageView(userData, "Home"));
    document.getElementById("searchInput").value = searchText;
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [])


  React.useEffect(() => {
    const fetchVideo = async () => {
      const response = await getVideoService();
      const data = await response.data;
      const videoArray = data?.data;
      const lastestVideoIndex = videoArray.length - 1;
      setVideoSrc(videoArray[lastestVideoIndex]);
      setHomePageText({ heading: videoArray[lastestVideoIndex].title, desc: videoArray[lastestVideoIndex].description })
    }
    fetchVideo();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    try {
      window.navigator.geolocation.getCurrentPosition(async ({ coords }) => {
        const { latitude, longitude } = coords;
        setCoordinates({
          lat: latitude,
          lng: longitude
        });
        dispatch(insertCoordinates({ lat: latitude, lng: longitude }));
      });
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (videoSrc?.fileType === ("video")) {
      document.getElementById("myVideo").play();
    }
  }, [videoSrc]);

  React.useEffect(() => {
    const fetchCity = async () => {
      try {
        const response = await getCityService(coordinates);
        setCity(response?.city || '');
      } catch (err) {
        console.log(err);
      }
    }
    fetchCity();
  }, [coordinates]);

  return (
    <div>
      <Helmet>
        <title>Home | Assymbly</title>
      </Helmet>
      <div className='homepage_background_video'>
        {videoSrc?.fileType === ("video") && <video width="100%" loop id="myVideo" muted>
          <source width="100%" src={`${process.env.REACT_APP_CLOUDFRONT}/${videoSrc?.url}`} type="video/mp4" />
        </video>}
        {videoSrc?.fileType === ("image") &&
          <div className='homepage_background_img'>
            <div className='bg_event_details' style={{ backgroundImage: `url('${process.env.REACT_APP_CLOUDFRONT}/${videoSrc?.url}')` }}>
              <div className='bg_details'>
                <div className='bg_event_header'>
                  {homePageText.heading}
                </div>
                <div className='bg_event_desc'>
                  {homePageText.desc}
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      <TransitionsModal open={openSearchBox} handleClose={() => setOpenSearchBox(false)}>
        <SearchBox handleClose={() => setOpenSearchBox(false)} sportsList={sports?.data || []} hideEventType={false} clearFilter={true} />
      </TransitionsModal>
      <SearchBar sportsList={sports?.data || []} hideEventType={false} clearFilter={true} />
      <div className='homepage_feeds_body'>
        <LoadingComponent loader={loader}>
          <SportsCategory sportsList={categories || []} />
        </LoadingComponent>
        <LoadingComponent loader={loader}>
          <SportsNearby city={city} coordinates={coordinates} leagues={(leagues?.events || [])} pickup={(pickup?.events || [])} tournament={(tournaments?.events || [])}>
            <Button className='search_box_mobile_view_button' onClick={() => setOpenSearchBox(true)}><FilterAltIcon style={{ color: 'black' }} /></Button>
          </SportsNearby>
        </LoadingComponent>

        <LoadingComponent loader={loader}>
          <><UpcomingLeagues background="#e9e9e9a6" events={(leagues?.events || [])} coordinates={coordinates} />
            <PickUpGames events={(pickup?.events || [])} coordinates={coordinates} />
            <UpcomingTournaments background="#e9e9e9a6" events={(tournaments?.events || [])} coordinates={coordinates} /></>
        </LoadingComponent>

      </div>
    </div>
  )
}
