import React from 'react'
import "../../styles/SearchMap.css";
import { Helmet } from "react-helmet";
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { SearchBar } from '../../components/SearchBar';
import { useDispatch, useSelector } from 'react-redux';
import TransitionsModal from '../../components/Modal';
import { SearchBox } from '../../components/SearchBox';
import { insertCoordinates } from '../../store/features/Data';
import SimpleMap from '../../components/GoogleMaps';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadingComponent } from '../../components/LoadingComponent';
import { UpcomingCard } from '../../components/Cards/UpcomingCard';
import { PickupGamesCard } from '../../components/Cards/PickupGamesCard';
import { combinePageViewSegmentPixel } from '../../Utils/Favored';
import { localStorageEnums } from '../../constants/LocalStorageEnums';
import { getUserDetailsPageView } from '../../Utils/helpers';

export const SearchMap = () => {
    const dispatch = useDispatch();
    const leagues = useSelector((state) => state.dataManager.leagues);
    const loader = useSelector((state) => state.dataManager.loader);
    const tournaments = useSelector((state) => state.dataManager.tournaments);
    const searchText = useSelector((state) => state.dataManager.searchText);
    const pickup = useSelector((state) => state.dataManager.pickup);
    const coordinatesRedux = useSelector((state) => state.dataManager.coordinates);
    const [spinnerCoord, setSpinnerCoord] = React.useState(true);
    const [coordinates, setCoordinates] = React.useState({
        lat: '',
        lng: '',
        denied: true
    });
    const userData = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA));
    const [eventsList, setEventsLists] = React.useState([]);
    const [openSearchBox, setOpenSearchBox] = React.useState(false);

    React.useEffect(() => {
        const fetchCoordinates = () => {
            try {
                window.navigator.geolocation.getCurrentPosition(async ({ coords }) => {
                    setSpinnerCoord(true);
                    const { latitude, longitude } = coords;
                    setCoordinates({
                        lat: latitude,
                        lng: longitude,
                        denied: false
                    });
                    setSpinnerCoord(false);
                    dispatch(insertCoordinates({ lat: latitude, lng: longitude }));
                }, (err) => {
                    setCoordinates({
                        lat: 29.772600,
                        lng: -95.514500,
                        denied: true
                    });
                    setSpinnerCoord(false);
                });
            } catch (err) {
                setSpinnerCoord(false);
                console.log(err);
            }
        }
        if (!coordinatesRedux.lat) {
            fetchCoordinates();
        }
        else {
            setCoordinates(coordinatesRedux);
            setSpinnerCoord(false);
        }
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        let leaguesArr = leagues?.events || [];
        let pickupArr = pickup?.events || [];
        let tournamentArr = tournaments?.events || [];
        setEventsLists([...leaguesArr, ...pickupArr, ...tournamentArr]);
        // eslint-disable-next-line
    }, [leagues, pickup, tournaments]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById("searchInput").value = searchText;
        combinePageViewSegmentPixel(getUserDetailsPageView(userData, "Search by Map"));
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <Helmet>
                <title>Search | Assymbly</title>
            </Helmet>
            <div className='searchmap_background_img'>
                {(!loader && !spinnerCoord) ? <SimpleMap height={"70vh"} mainMarkerTitle={"Your Location"} mainMarkerColor="blue" denied={coordinates.denied} lat={Number(coordinates?.lat || 0)} lng={Number(coordinates?.lng || 0)} events={(eventsList || [])} /> : <CircularProgress color='warning' />}
            </div>
            <TransitionsModal open={openSearchBox} handleClose={() => setOpenSearchBox(false)}>
                <SearchBox handleClose={() => setOpenSearchBox(false)} hideEventType={false} clearFilter={true} />
            </TransitionsModal>
            <Button className='search_box_mobile_view_button' onClick={() => setOpenSearchBox(true)}><FilterAltIcon style={{ color: 'black' }} /></Button>
            <SearchBar hideEventType={false} clearFilter={true} />
            <div className='searchmap_search_results_div'>
                <span className='searchmap_search_results'>Search results</span>
                <span className='searchmap_search_results_orange'>{(leagues?.events?.length + pickup?.events?.length + tournaments?.events?.length) || 0} results found</span>
            </div>
            <LoadingComponent loader={loader}>
                <div className='search_map_grey_div'><div className='searchmap_search_results_subdiv'>
                    <p className='searchmap_heading_lists'>Upcoming Leagues</p>
                </div>
                    {(leagues?.events || []).length !== 0 ? <div className='searchmap_grid'>
                        {(leagues?.events || [])?.map((event, index) => <UpcomingCard key={event?.id} coordinates={coordinates} latitude={event?.eventVenue[0]?.venue?.latitude} longitude={event?.eventVenue[0]?.venue?.longitude} id={event.id} type={event?.type} src={`${process.env.REACT_APP_CLOUDFRONT}/${event.eventImage[0]?.src}`} name={event.name} location={event?.eventVenue[0]?.venue?.address} date={event?.eventVenue[0]?.startDate} club={event.organizer.name} dynamicLink={event.dynamicLink} featured={index === 0} />)}
                    </div> :
                        <div className='no_events_available_div'>
                            <p>No Events available.</p>
                        </div>}
                </div>

                <div className='search_map_white_div'>
                    <div className='searchmap_search_results_subdiv'>
                        <p className='searchmap_heading_lists'>Pickup Games</p>
                    </div>
                    {(pickup?.events || []).length !== 0 ? <div className='searchmap_grid_sm'>
                        {(pickup?.events || [])?.map((event) => <PickupGamesCard
                            key={event?.id} coordinates={coordinates} dynamicLink={event?.dynamicLink} latitude={event?.eventVenue[0]?.venue?.latitude} longitude={event?.eventVenue[0]?.venue?.longitude} id={event.id} type={event?.type} src={`${process.env.REACT_APP_CLOUDFRONT}/${event.eventImage[0]?.src}`} title={event.name} organizer={event?.organizer?.name} charge={event.individualPrice}
                        />)}
                    </div> :
                        <div className='no_events_available_div'>
                            <p>No Events available.</p>
                        </div>}
                </div>
                <div className='search_map_grey_div'>
                    <div className='searchmap_search_results_subdiv'>
                        <p className='searchmap_heading_lists'>Upcoming Tournaments</p>
                    </div>
                    {(tournaments?.events || []).length !== 0 ? <div className='searchmap_grid'>
                        {(tournaments?.events || [])?.map((event, index) => <UpcomingCard key={event?.id} coordinates={coordinates} latitude={event?.eventVenue[0]?.venue?.latitude} longitude={event?.eventVenue[0]?.venue?.longitude} id={event.id} type={event?.type} src={`${process.env.REACT_APP_CLOUDFRONT}/${event.eventImage[0]?.src}`} name={event.name} location={event?.eventVenue[0]?.venue?.address} date={event?.eventVenue[0]?.startDate} club={event.organizer.name} dynamicLink={event.dynamicLink} featured={index === 0} />)}
                    </div> :
                        <div className='no_events_available_div'>
                            <p>No Events available.</p>
                        </div>}
                </div>
            </LoadingComponent>
        </div>
    )
}
