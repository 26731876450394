import React from 'react';
import '../../styles/components/MarkerPin.css';
import { HoveredCardOrg } from './hoveredCardForOrganizer';

const MarkerPinWithoutHover = (props) => {
    const { color, name, src, event, defaultMarker } = props;
    const [toggle, setToggle] = React.useState(false);
    return (
        defaultMarker ?
            <>
                <div
                    className={` ${src === null ? "default_pin" : "pin"} bounce`}
                    style={{ backgroundColor: color, cursor: 'pointer' }}
                />
                <div className="pulse" /></> :
            <div onMouseOver={() => setToggle(true)} onMouseOut={() => setToggle(false)}>
                <div
                    className={` ${src === null ? "default_pin" : "pin"} bounce`}
                    style={{ backgroundColor: color, cursor: 'pointer' }}
                />
                {src && event.data.length === 1 ? <img alt={name} className='marker_img img_bounce' src={`${process.env.REACT_APP_CLOUDFRONT}/${src}`} /> : <h2 className='marker_img img_bounce'>+{event.data.length}</h2>}
                {(event.data?.length > 0 && toggle) && <div className='scroll_events_hovered_div'> {event.data?.map(ev => <HoveredCardOrg key={ev.events.id} toggle={toggle} event={ev.events} name={ev.category} category={{
                    icon: ev.iconMedia,
                    name: ev.name
                }} />)}</div>}
                <div className="pulse" />
            </div >
    );
};

export default MarkerPinWithoutHover;