import React from 'react'
import "../../styles/UpcomingTournament.css"
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { UpcomingCard } from '../../components/Cards/UpcomingCard';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { fetchEventsList } from '../../Services/Events.services';
import { insertLeagues, insertPickUp, insertTournaments } from '../../store/features/Data';
import { fetchEventsListByOID } from '../../Services/OrganizerDetails.services';
import { TYPES } from '../../constants/SportTypes';
import { useParams } from 'react-router-dom';
import { LoadingComponent } from '../../components/LoadingComponent';
import { SearchBar } from '../../components/SearchBar';
import TransitionsModal from '../../components/Modal';
import { SearchBox } from '../../components/SearchBox';
import { Button } from '@mui/material';
import { combinePageViewSegmentPixel } from '../../Utils/Favored';
import { localStorageEnums } from '../../constants/LocalStorageEnums';
import { getUserDetailsPageView } from '../../Utils/helpers';

export const UpcomingTournamentPage = () => {
    const { oid } = useParams();
    const dispatch = useDispatch();
    const tournaments = useSelector((state) => state.dataManager.tournaments);
    const searchText = useSelector((state) => state.dataManager.searchText);
    const loader = useSelector((state) => state.dataManager.loader);
    const coordinates = useSelector((state) => state.dataManager.coordinates);
    const [tournamentLists, setTournamentLists] = React.useState({});
    const [openSearchBox, setOpenSearchBox] = React.useState(false);
    const userData = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA));
    const [spinner, setSpinner] = React.useState(false);

    React.useEffect(() => {
        const fetchList = async () => {
            try {
                setSpinner(true);
                const response = await fetchEventsList();
                if (response.status === 200) {
                    const data = await response.data;
                    if (data?.data) {
                        const { league = {}, pickup = {}, tournament = {} } = data?.data;
                        setTournamentLists(tournament);
                        dispatch(insertLeagues(league));
                        dispatch(insertPickUp(pickup));
                        dispatch(insertTournaments(tournament));
                    }
                }
                setSpinner(false);
            } catch (err) {
                setSpinner(false);
                swal('Error', err.response?.data?.message || "", 'error');
            }
        };
        if (!oid) {
            if (Object.keys(tournaments).length !== 0) {
                setTournamentLists(tournaments);
            }
            else {
                fetchList();
            }
        }
        // eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        setSpinner(true);
        setTournamentLists(tournaments);
        setSpinner(false);
        // eslint-disable-next-line
    }, [tournaments]);

    React.useEffect(() => {
        const fetchList = async () => {
            try {
                setSpinner(true);
                const response = await fetchEventsListByOID(oid);
                if (response.status === 200) {
                    const data = await response.data;
                    setTournamentLists({ events: data.data?.data?.filter(event => event?.type === TYPES.TOURNAMENT) || [] });
                }
                setSpinner(false);
            } catch (err) {
                setSpinner(false);
                swal('Error', err.response?.data?.message || "", 'error');
            }
        };
        if (oid)
            fetchList();
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        combinePageViewSegmentPixel(getUserDetailsPageView(userData, "Upcoming Tournaments"));
        document.getElementById("searchInput").value = searchText;
        // eslint-disable-next-line
    }, []);

    return (
        <div className='main_div_leagues'>
            <Helmet>
                <title>Upcoming Tournaments | Assymbly</title>
            </Helmet>
            <div className='upcoming_leagues_background_img'>
                <div className='leagues_details'>
                    <div className='bg_event_date_details'>
                        Upcoming Tournaments
                    </div>
                    <div className='breadcrumb'>
                        <Link className="router_link" to="/">Home</Link> | <span className='lighter_breadcrumb'> Upcoming Tournaments </span>
                    </div>
                </div>
            </div>
            <TransitionsModal open={openSearchBox} handleClose={() => setOpenSearchBox(false)}>
                <SearchBox handleClose={() => setOpenSearchBox(false)} hideEventType={true} />
            </TransitionsModal>
            <Button className='search_box_mobile_view_button' onClick={() => setOpenSearchBox(true)}><FilterAltIcon style={{ color: 'black' }} /></Button>
            <SearchBar hideEventType={true} clearFilter={true} />
            {!(spinner || loader) &&(tournamentLists?.events || [])?.length === 0 &&
                    <div className='spinner_center_div'>
                        <p>No events available.</p>
                    </div>
                }
            <LoadingComponent loader={spinner || loader}>
                <div className='upcoming_leagues_grid'>
                    {(tournamentLists?.events || [])?.map((event) => <UpcomingCard key={event?.id} coordinates={coordinates} latitude={event?.eventVenue[0]?.venue?.latitude} longitude={event?.eventVenue[0]?.venue?.longitude} id={event.id} type={event?.type} src={`${process.env.REACT_APP_CLOUDFRONT}/${event.eventImage[0]?.src}`} name={event.name} location={event?.eventVenue[0]?.venue?.address} date={event?.eventVenue[0]?.startDate} club={event.organizer.name} dynamicLink={event.dynamicLink} />)}
                </div>
            </LoadingComponent>
        </div>
    )
}
