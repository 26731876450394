import React from 'react'
import { Link } from 'react-router-dom';
import { getDistance } from '../../Utils/DistanceCalculator';
import { localStorageEnums } from '../../constants/LocalStorageEnums';
import { abbreviate } from '../../Utils/abbreviators';
import pin from "../../Images/local.png";
import "../../styles/components/Cards.css";
import ShareIcon from '@mui/icons-material/Share';
import socialShareIcon from "../../Images/social-media.png"
import StyledSnackbar from '../StyledSnackbar';
import TransitionsModal from '../Modal';
import Button from '@mui/material/Button';

export const SportsNearbyCard = (props) => {
    const [open, setOpen] = React.useState(false);
    const locationStatus = window.localStorage.getItem(localStorageEnums.LOCATIONSTATUS);
    const [openModal, setOpenModal] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const [snackbarData, setSnackBarData] = React.useState({
        xPos: 'center',
        yPos: 'bottom',
        title: '',
        status: 'success'
    });

    const copyCall = () => {
        setOpenModal(false);
        navigator.clipboard.writeText(props?.dynamicLink);
        setSnackBarData({
            xPos: 'center',
            yPos: 'bottom',
            title: 'Copied to clipboard',
            status: 'success'
        });
        handleClick();
    };
    return (
        <Link className='no_decoration_link_card' style={{ backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 46.44%, rgba(0, 0, 0, 0.9) 81.03%) , url('${props.src}') !important` }}>
            <TransitionsModal open={openModal} handleClose={() => setOpenModal(false)}>
                <div className='dynamic_link_img_div'>
                    <img className='dynamic_link_img' alt='social_share_img' src={socialShareIcon} />
                </div>
                <div className='dynamic_link_div'>
                    <span className='dynamic_link'>{abbreviate(props?.dynamicLink, 36)}</span>
                    <Button color='warning' onClick={copyCall}>Copy</Button>
                </div>
            </TransitionsModal>
            {props?.dynamicLink && <div className='share_float_button_right' onClick={() => setOpenModal(true)}>
                <ShareIcon style={{ color: 'white' }} />
            </div>}
            <Link className='no_decoration_link_card' to={`/event/${props?.type}/${props?.id}`}>
                <div className='sports_nearby_grid_card' style={{ backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 46.44%, rgba(0, 0, 0, 0.9) 81.03%) , url('${props.src}')` }}>
                    {(locationStatus === "granted" && !isNaN(getDistance(props?.coordinates?.lat, props?.coordinates?.lng, props?.latitude, props?.longitude))) && <div className='miles_in_card'><><img className='local_pin' alt='pin' src={pin} />{getDistance(props?.coordinates?.lat, props?.coordinates?.lng, props?.latitude, props?.longitude)} miles</> </div>}
                    <div className='card_event_details'>
                        <p className='card_details_date'>{props.date}</p>
                        <p className='card_details_event'>{abbreviate(props?.name, 22)}</p>
                        <p className='card_details_location' title={props?.location}>{abbreviate(props?.location, 30)}</p>
                    </div>
                </div>
            </Link>
            <StyledSnackbar
                xPos={snackbarData.xPos}
                yPos={snackbarData.yPos}
                title={snackbarData.title}
                status={snackbarData.status}
                open={open}
                onClose={handleClose}
            />
        </Link>
    )
}
