import React from 'react'
import TextField  from '@mui/material/TextField';
import swal from 'sweetalert';
import { MESSAGES } from '../../constants/ValidationMsgs';
import { AuthForgotPasswordServices } from '../../Services/Auth.services';
import { isValidEmail } from '../../Utils/validators';
import "./auth.css";
import { LoadingText } from '../LoadingText';

export const ForgotPassword = (props) => {

    const [formBody, setFormBody] = React.useState({
        email: ''
    });

    const [loader, setLoader] = React.useState(false);

    const [errors, setErrors] = React.useState({
        email: false
    });

    const handleChange = async (e, param) => {
        setFormBody({
            ...formBody,
            email: e.target.value
        });
        setErrors({
            email : !isValidEmail(e.target.value)
        })
    }

    const submitForm = async()=>{
        if(isValidEmail(formBody.email)){
            setLoader(true);
            try{
                const response = await AuthForgotPasswordServices(formBody);
                setLoader(false);
                if(response.status === 201){
                    props.setEmail(formBody.email);
                    props.setAuthEnum(3);
                }
            }catch(err){
                swal("Error", err.response?.data?.message || "", "error");
                setLoader(false);
            }
        }
        else{
            setErrors({
                email: !isValidEmail(formBody.email)
            }) 
        }
    }

    return (
        <div>
            <h1>Forgot Password</h1>
            <span className='auth_form_link'>Please enter your email to receive the instruction to reset your password.</span>
            
            <div className='auth_form_div'>
                <div className='login_textfield_div'>
                    <TextField 
                        color='warning' 
                        id="outlined-basic" 
                        style={{ width: '100%' }} 
                        label="Email" 
                        type="email"
                        variant="outlined" 
                        value={formBody.email} 
                        error={errors.email}
                        autoComplete="off"
                        helperText={errors.email ? formBody.email ? MESSAGES.INVALIDEMAIL : MESSAGES.EMAILREQUIRED : null}
                        onChange={(e) => handleChange(e, "email")} />
                </div>
                <button className='auth_button' onClick={submitForm} disabled={loader}>
                    <LoadingText text="Send now" loader={loader}/>
                </button>
            </div>
        </div>
    )
}
