import { capitalizeFirstLetter } from "./abbreviators";

export const filteredEvents = (arr, search) => {
    return (arr?.filter(event => event?.organizer?.name?.toLowerCase()?.includes(search?.toLowerCase()) || event?.name?.toLowerCase()?.includes(search?.toLowerCase()) || event?.eventSport?.find(eventSport => eventSport?.name?.toLowerCase()?.includes(search?.toLowerCase()))) || []);
}

export const mergeDuplicate = (places) => {
    const transformed = [];
    if (places?.length) {
        places?.forEach(place => {
            let match = false;
            transformed.forEach(tData => {
                if (
                    tData.latitude === place?.eventVenue[0]?.venue?.latitude &&
                    tData.longitude === place?.eventVenue[0]?.venue?.longitude
                ) {
                    match = true;
                    tData.data.push({
                        eventId: place?.id,
                        iconMedia: place?.eventSport[0]?.icon,
                        id: place?.eventVenue[0]?.venue?.id,
                        name: place?.name,
                        img: place?.eventImage[0]?.src,
                        games: place?.noOfGames,
                        refree: place?.refree,
                        type: place?.type,
                        venue: place?.eventVenue[0]?.venue?.name,
                        address: place?.eventVenue[0]?.venue?.address,
                        ageLimit: place?.ageLimit,
                        startDate: place?.eventVenue[0]?.startDate,
                        endDate: place?.eventVenue[0]?.endDate,
                        category: place?.eventSport[0]
                    });
                }
            });
            if (!match) {
                const newData = {
                    latitude: place?.eventVenue[0]?.venue?.latitude,
                    longitude: place?.eventVenue[0]?.venue?.longitude,
                    data: [
                        {
                            eventId: place?.id,
                            iconMedia: place?.eventSport[0]?.icon,
                            id: place?.eventVenue[0]?.venue?.id,
                            type: place?.type,
                            name: place?.name,
                            img: place?.eventImage[0]?.src,
                            ageLimit: place?.ageLimit,
                            games: place?.noOfGames,
                            refree: place?.refree,
                            venue: place?.eventVenue[0]?.venue?.name,
                            startDate: place?.eventVenue[0]?.startDate,
                            address: place?.eventVenue[0]?.venue?.address,
                            endDate: place?.eventVenue[0]?.endDate,
                            category: place?.eventSport[0]
                        },
                    ]
                };
                transformed.push(newData);
            }
        });
    }
    return (transformed);
};



export const mergeDuplicateOrganizersEvents = (places) => {
    const transformed = [];
    if (places?.length) {
        places?.forEach(place => {
            let match = false;
            transformed.forEach(tData => {
                if (
                    tData.latitude === place?.latitude &&
                    tData.longitude === place?.longitude
                ) {
                    match = true;
                    const eventDetail = place.eventData;
                    eventDetail.iconMedia = place?.iconMedia;
                    eventDetail.category = place?.name;
                    tData.data.push({
                        iconMedia: place?.iconMedia,
                        id: place?.id,
                        name: place?.name,
                        events: eventDetail,
                        bgColor: place?.bgColor 
                    });
                }
            });
            if (!match) {
                const eventDetail = place.eventData;
                eventDetail.icon = place?.iconMedia;
                eventDetail.category = place?.name;
                const newData = {
                    latitude: place?.latitude,
                    longitude: place?.longitude,
                    data: [
                        {
                            iconMedia: place?.iconMedia,
                            id: place?.id,
                            name: place?.name,
                            events: eventDetail,
                            bgColor: place?.bgColor 
                        },
                    ]
                };
                transformed.push(newData);
            }
        });
    }
    return (transformed);
};

export const getCategoryUsingFor = (category) => {
    return category === 'child' ? 'Youth' : capitalizeFirstLetter(category)
}


export const getUserDetailsIdentify = (data) => {
    return {
        userId: data?.id,
        name: data?.name,
        email: data?.email,
        location: data?.address,
        usingFor: getCategoryUsingFor(data?.usingFor || "")
    }
}

export const getUserDetailsPageView = (userData, page) => {
    return {
        userId: userData?.id,
        name: userData?.name,
        email: userData?.email,
        usingFor: getCategoryUsingFor(userData?.usingFor || ""),
        page: page
    }
}