export const isValidEmail = (email) => {
    const format =
    // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return format.test(email);
};

export const isStrongPassword = (text) => {
    try {
        const fieldVal = text.toString().trim();
        const format = /^(?=.*\d)(?=.*[!@#$%.^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return format.test(fieldVal);
    } catch (error) {
        console.log(error);
    }
    return false;
};