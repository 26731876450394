import React from 'react'
import { ForgotPassword } from './ForgotPassword';
import { LoginForm } from './LoginForm';
import { OtpForm } from './OtpForm';
import { RegisterForm } from './RegisterForm';
import { ResetPassword } from './ResetPssword';

export const AuthForm = (props) => {
    const [authEnum, setAuthEnum] = React.useState(0);
    const [email, setEmail] = React.useState("");
    const [otp, setOtp] = React.useState(0);
  return (
    authEnum === 0 ? <LoginForm setAuthEnum={setAuthEnum} handleClose={() => props?.handleClose()} /> : authEnum === 1 ? <RegisterForm setAuthEnum={setAuthEnum} handleClose={() => props?.handleClose()} /> : authEnum === 2 ? <ForgotPassword setAuthEnum={setAuthEnum} setEmail={setEmail} handleClose={() => props?.handleClose()} /> : authEnum === 3 ? <OtpForm setAuthEnum={setAuthEnum} setOtp={setOtp} email={email} handleClose={() => props?.handleClose()} /> : <ResetPassword email={email} otp={otp} handleClose={() => props?.handleClose()}/>
  )
}
