import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import "../../styles/components/ProfileForm.css";
import { isValidEmail } from '../../Utils/validators';
import { MESSAGES } from '../../constants/ValidationMsgs';
import { AuthUpdateUserInfoServices, AuthUserInfoServices, getAddress } from '../../Services/Auth.services';
import swal from 'sweetalert';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { localStorageEnums } from '../../constants/LocalStorageEnums';
import { LoadingModal } from '../LoadingModal';
import { LoadingText } from '../LoadingText';
import { googleAutocompleteStyles } from '../../styles/googleAutocompleteStyling';
import { SEGMENT_ACTIONS } from '../../Utils/Favored/actions';
import { combineCustomTrackSegmentPixel, combineIdentifySegmentPixel } from '../../Utils/Favored';

export const ProfileForm = (props) => {

    const [isOnboardingCompleted, setIsOnboardingCompleted] = React.useState(false);
    const [spinner, setSpinner] = React.useState(true);
    const [miniSpinner, setMiniSpinner] = React.useState(false);
    const [loader, setLoader] = React.useState(false);

    const [apiPlaceData, setApiPlaceData] = React.useState();

    let userData = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA));
    const [formBody, setFormBody] = React.useState({
        name: userData?.name,
        email: userData?.email,
        usingFor: userData?.usingFor,
        address: '',
        notification: false
    });

    const [coordinates, setCoordinates] = React.useState({
        lat: '',
        lng: ''
    });

    React.useEffect(() => {
        try {
            window.navigator.permissions.query({ name: 'geolocation' }).then(function (status) {
                if (status.state === "granted") {
                    window.navigator.geolocation.getCurrentPosition(async ({ coords }) => {
                        const { latitude, longitude } = coords;
                        setCoordinates({
                            lat: latitude,
                            lng: longitude
                        });
                    });
                }
            })
        } catch (err) {
            console.log(err);
        }
    }, []);

    const [address, setAddress] = React.useState('');

    const [errors, setErrors] = React.useState({
        name: false,
        email: false,
        location: false,
        usingFor: false
    });


    React.useEffect(() => {
        if (apiPlaceData) {
            geocodeByPlaceId(apiPlaceData.value.place_id)
                .then(results => {
                    setAddress(results[0].formatted_address);
                    setCoordinates({ lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() });
                })
                .catch(error => console.error(error));
        }
        // eslint-disable-next-line
    }, [apiPlaceData]);

    React.useEffect(() => {
        const fetchInfo = async () => {
            try {
                setSpinner(true);
                const response = await AuthUserInfoServices();
                const data = await response.data;
                if (response.status === 200) {
                    setFormBody({
                        email: data?.data?.email || "",
                        name: data?.data?.name || "",
                        usingFor: data?.data?.usingFor || "",
                        address: data?.data?.address || "",
                        notification: data?.data?.notification || false
                    });
                    setIsOnboardingCompleted(data?.data?.isProfileComplete || false);
                    setAddress(data?.data?.address || "");
                    setSpinner(false);

                }
            } catch (err) {
                setSpinner(false);
                swal("Error", err.response?.data?.message || "", "error");
            }
        }
        fetchInfo();
        // eslint-disable-next-line
    }, []);

    const getCurrentLocation = () => {
        try {
            window.navigator.permissions.query({ name: 'geolocation' }).then(function (status) {
                if (status.state === "granted") {
                    window.navigator.geolocation.getCurrentPosition(async ({ coords }) => {
                        const { latitude, longitude } = coords;
                        setCoordinates({
                            lat: latitude,
                            lng: longitude
                        });
                        setMiniSpinner(true);
                        const getAddressValue = await getAddress({ latitude: latitude, longitude: longitude });
                        setAddress(getAddressValue.title);
                        setMiniSpinner(false);
                    });
                }
            })
        } catch (err) {
            console.log(err);
        }
    }


    const handleChange = async (e, param) => {
        if (param === "email") {
            setFormBody({
                ...formBody,
                email: e.target.value
            });
            setErrors({
                ...errors,
                email: !isValidEmail(e.target.value)
            })
        }
        else {
            setFormBody({
                ...formBody,
                [param]: e.target.value
            });
        }
    }

    const submitForm = async () => {
        try {
            setLoader(true);
            const response = await AuthUpdateUserInfoServices(formBody, coordinates.lat, coordinates.lng, address, formBody.notification, isOnboardingCompleted);
            const data = await response.data;
            if (response.status === 201) {
                const { locLat, locLong, usingFor } = response.data.data;
                combineIdentifySegmentPixel(SEGMENT_ACTIONS.USER_UPDATES_PROFILE, data.data);
                if (!isOnboardingCompleted) {
                    combineCustomTrackSegmentPixel(SEGMENT_ACTIONS.USER_COMPLETES_ONBOARDING, {
                        isOnboardCompleted: true
                    });
                }
                swal("Success", data.message, "success").then(() => {
                    userData.address = address;
                    userData.locLat = locLat;
                    userData.locLong = locLong;
                    userData.usingFor = usingFor;
                    window.localStorage.setItem(localStorageEnums.USERDATA, JSON.stringify(userData));
                    window.location.reload();
                });
                setLoader(false);
            }
        } catch (err) {
            setLoader(false);
            swal("Error", err.response?.data?.message || "", "error");
        }
    }

    return (
        <div>
            <h1>Profile</h1>
            <LoadingModal loader={spinner}>
                <div className='auth_form_div'>
                    <div className='login_textfield_div'>
                        <TextField
                            color='warning'
                            disabled
                            id="outlined-basic"
                            style={{ width: '100%' }}
                            label="Name" variant="outlined"
                            value={formBody.name}
                            error={errors.name}
                            autoComplete="off"
                            helperText={errors.name ? MESSAGES.FULLNAMEREQUIRED : null}
                            onChange={(e) => handleChange(e, "name")} />
                    </div>
                    <div className='login_textfield_div'>
                        <TextField
                            color='warning'
                            disabled
                            id="outlined-basic"
                            style={{ width: '100%' }}
                            label="Email" variant="outlined"
                            value={formBody.email}
                            error={errors.email}
                            autoComplete="off"
                            helperText={errors.email ? formBody.email ? MESSAGES.INVALIDEMAIL : MESSAGES.EMAILREQUIRED : null}
                            onChange={(e) => handleChange(e, "email")} />
                    </div>
                    <div className='login_textfield_div'>
                        <FormControl style={{ width: '100%' }} color="warning">
                            <InputLabel id="demo-simple-select-label">Searching for</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formBody.usingFor}
                                label="Searching for"
                                onChange={(e) => handleChange(e, "usingFor")}
                            >
                                <MenuItem value={"adult"}>Adult Sporting Events: 21 and Up</MenuItem>
                                <MenuItem value={"child"}>Youth Sporting Events: 20 and Under</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className='login_textfield_div_flex'>
                        {miniSpinner ?
                            <div className='div_center'>
                                <CircularProgress color='warning' />
                            </div> : <div style={{ width: '80%' }} title={address}>
                                <GooglePlacesAutocomplete
                                    apiKey={process.env.REACT_APP_GCP_KEY}
                                    libraries={["places"]}
                                    selectProps={{
                                        placeholder: "Search venue location...",
                                        defaultInputValue: address,
                                        isClearable: "true",
                                        backspaceRemovesValue: "true",
                                        styles: googleAutocompleteStyles,
                                        apiPlaceData,
                                        onChange: setApiPlaceData
                                    }}
                                />
                            </div>}
                        <Button disabled={miniSpinner} style={{ width: '20%' }} onClick={getCurrentLocation} color='warning'>
                            <MyLocationIcon />
                        </Button>
                    </div>
                    <div className='notification_flex_div'>
                        <p>Get Notified</p>
                        <Switch checked={formBody.notification} onClick={() => setFormBody({ ...formBody, notification: !formBody.notification })} color="warning" />
                    </div>
                    <br />
                    <button className='auth_button' onClick={submitForm} disabled={loader}>
                        <LoadingText text="Save / update" loader={loader} />
                    </button>
                </div>
            </LoadingModal>
        </div>
    )
}
