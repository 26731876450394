import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

export const LoadingText = ({ text, loader }) => {
    return (
        <>
            {loader ? <CircularProgress size={"24px"} style={{ color: "white" }} /> : text}
        </>
    )
}
