import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFilteredEventsService, fetchSportsList } from '../../Services/Events.services';
import { insertCategories, insertDaysArray, insertEventsArray, insertFilters, insertGendersArray, insertLeagues, insertPickUp, insertSearchText, insertSports, insertSportsArray, insertTournaments, setLoader } from '../../store/features/Data';
import { capitalizeFirstLetter } from '../../Utils/abbreviators';
import { useStyles } from '../../styles/components/styles';
import "../../styles/components/searchBar.css";
import { ListItemIcon } from '@mui/material';
import { genderTranslation } from '../../Utils/genderHelper';

export const SearchBar = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const filters = useSelector((state) => state.dataManager.filters);
    const sports = useSelector((state) => state.dataManager.sports);
    const searchText = useSelector((state) => state.dataManager.searchText);
    const sportsArraySelector = useSelector((state) => state.dataManager.sportsArray);
    const eventsArraySelector = useSelector((state) => state.dataManager.eventsArray);
    const gendersArraySelector = useSelector((state) => state.dataManager.gendersArray);
    const daysArraySelector = useSelector((state) => state.dataManager.daysWeekArray);
    const [spinner, setSpinner] = React.useState(false);
    const [sportsArray, setSportsArray] = React.useState([]);
    const [daysWeekArray, setDaysWeekArray] = React.useState([]);
    const [gendersArray, setGendersArray] = React.useState([]);
    const [eventsArray, setEventsArray] = React.useState([]);
    const [sportsState, setSportsState] = React.useState(sports);
    const [sportName, setSportName] = React.useState([]);
    const [daysName, setDaysName] = React.useState([]);
    const [genderName, setGenderName] = React.useState([]);
    const [eventName, setEventName] = React.useState([]);

    const isAllSportsSelected =
        sportsArray.length > 0 && sportName.length === sportsArray.length;

    const isAllGenderSelected =
        gendersArray.length > 0 && genderName.length === gendersArray.length;

    const isAllDaysSelected =
        daysWeekArray.length > 0 && daysName.length === daysWeekArray.length;

    const isAllEventSelected =
        eventsArray.length > 0 && eventName.length === eventsArray.length;


    const [searchProps, setSearchProps] = React.useState({
        sports: [],
        daysWeek: [],
        gender: [],
        eventType: []
    });

    const [daysWeek] = React.useState([
        {
            id: 0,
            name: 'Sun'
        },
        {
            id: 1,
            name: 'Mon'
        },
        {
            id: 2,
            name: 'Tue'
        },
        {
            id: 3,
            name: 'Wed'
        },
        {
            id: 4,
            name: 'Thu'
        },
        {
            id: 5,
            name: 'Fri'
        },
        {
            id: 6,
            name: 'Sat'
        }
    ]);

    const [genders] = React.useState([
        {
            id: 0,
            name: 'male'
        },
        {
            id: 1,
            name: 'female'
        },
        {
            id: 2,
            name: 'coed'
        }
    ]);

    const [eventType] = React.useState([
        {
            id: 0,
            name: 'league'
        },
        {
            id: 1,
            name: 'pickup'
        },
        {
            id: 2,
            name: 'tournament'
        }
    ]);

    const handleChange = (props, e) => {
        if (props === "sports") {
            let lists = [];
            const { value } = e.target;
            if (value[value.length - 1] === "all") {
                if (sportName.length !== sports?.length) {
                    for (let i = 0; i < sports?.length; i += 1) {
                        lists.push(sports[i].id)
                    }
                    setSportName(lists);
                }
                else {
                    setSportName([]);
                }
                setSearchProps({ ...searchProps, sports: lists });
                setSportsArray(
                    typeof e.target.value === 'number' ? lists.split(',') : lists
                );
                return;
            }
            lists = e.target.value;
            setSearchProps({ ...searchProps, sports: lists });
            setSportsArray(
                typeof e.target.value === 'number' ? lists.split(',') : lists
            );
            setSearchProps({ ...searchProps, sports: lists });
        }
        if (props === "daysWeek") {

            let lists = [];
            const { value } = e.target;
            if (value[value.length - 1] === "all") {
                if (daysName.length !== daysWeek.length) {
                    for (let i = 0; i < daysWeek.length; i += 1) {
                        lists.push(daysWeek[i].id)
                    }
                    setDaysName(lists);
                }
                else {
                    setDaysName([]);
                }
                setSearchProps({ ...searchProps, daysWeek: lists });
                setDaysWeekArray(
                    typeof e.target.value === 'number' ? lists.split(',') : lists
                );
                return;
            }
            lists = e.target.value;
            setSearchProps({ ...searchProps, daysWeek: lists });
            setDaysWeekArray(
                typeof e.target.value === 'number' ? lists.split(',') : lists
            );
            setSearchProps({ ...searchProps, daysWeek: lists });
        }
        if (props === "gender") {
            let lists = [];
            const { value } = e.target;
            if (value[value.length - 1] === "all") {
                if (genderName.length !== genders.length) {
                    for (let i = 0; i < genders.length; i += 1) {
                        lists.push(genders[i].id)
                    }
                    setGenderName(lists);
                }
                else {
                    setGenderName([]);
                }
                setSearchProps({ ...searchProps, gender: lists });
                setGendersArray(
                    typeof e.target.value === 'number' ? lists.split(',') : lists
                );
                return;
            }
            lists = e.target.value;
            setSearchProps({ ...searchProps, gender: lists });
            setGendersArray(
                typeof e.target.value === 'number' ? lists.split(',') : lists
            );
            setSearchProps({ ...searchProps, gender: lists });
        }
        if (props === "eventType") {
            let lists = [];
            const { value } = e.target;
            if (value[value.length - 1] === "all") {
                if (eventName.length !== eventType.length) {
                    for (let i = 0; i < eventType.length; i += 1) {
                        lists.push(eventType[i].id)
                    }
                    setEventName(lists);
                }
                else {
                    setEventName([]);
                }
                setSearchProps({ ...searchProps, eventType: lists });
                setEventsArray(
                    typeof e.target.value === 'number' ? lists.split(',') : lists
                );
                return;
            }
            lists = e.target.value;
            setSearchProps({ ...searchProps, eventType: lists });
            setEventsArray(
                typeof e.target.value === 'number' ? lists.split(',') : lists
            );
            setSearchProps({ ...searchProps, eventType: lists });
        }
    }

    React.useMemo(() => {
        setSearchProps({
            sports: filters?.sports || [],
            daysWeek: filters?.daysWeek || [],
            gender: filters?.gender || [],
            eventType: filters?.eventType || []
        });
    }, [filters]);

    React.useMemo(() => {
        setSportsArray(sportsArraySelector);
    }, [sportsArraySelector]);

    React.useMemo(() => {
        setDaysWeekArray(daysArraySelector);
    }, [daysArraySelector]);

    React.useMemo(() => {
        setGendersArray(gendersArraySelector);
    }, [gendersArraySelector]);

    React.useMemo(() => {
        setEventsArray(eventsArraySelector);
    }, [eventsArraySelector]);

    const fetchFilteredEvents = async (search = "") => {
        try {
            setSpinner(true);
            dispatch(setLoader(true));
            let gendersSearchList = [];
            let eventTypeSearchList = [];
            for (let i = 0; i < searchProps?.gender?.length; i++)
                gendersSearchList.push(genders[searchProps?.gender[i]]?.name);
            for (let i = 0; i < searchProps?.eventType?.length; i++) {
                eventTypeSearchList.push(eventType[searchProps?.eventType[i]]?.name);
            }
            let body = {}
            if (searchProps?.sports?.length > 0)
                body.sport_id = searchProps?.sports;
            if (searchProps?.daysWeek?.length > 0)
                body.days_of_week = searchProps?.daysWeek;
            if (gendersSearchList?.length > 0)
                body.gender = gendersSearchList;
            if (eventTypeSearchList?.length > 0) {
                if (!props?.hideEventType)
                    body.type = eventTypeSearchList;
            }
            const response = await fetchFilteredEventsService(body, search);
            const data = await response.data;
            if (response.status === 201) {
                const { league, pickup, tournament, sports } = data?.data;
                dispatch(insertLeagues(league || {}));
                dispatch(insertPickUp(pickup || {}));
                dispatch(insertTournaments(tournament || {}));
                dispatch(insertFilters(searchProps || {}));
                dispatch(insertSportsArray(sportsArray || []));
                dispatch(insertCategories(sports || []));
                dispatch(insertDaysArray(daysWeekArray || []));
                dispatch(insertGendersArray(gendersArray || []));
                dispatch(insertEventsArray(eventsArray || []));
                setSpinner(false);
                dispatch(setLoader(false));
                window.scrollTo({ top: 700, left: 0, behavior: "smooth" });
            }
        } catch (err) {
            dispatch(setLoader(false));
            setSpinner(false);
            swal("Error", err.response?.data?.message || "", "error");
        }
    }

    React.useEffect(() => {
        const fetchList = async () => {
            try {
                const response = await fetchSportsList();
                if (response.status === 200) {
                    const data = await response.data;
                    setSportsState(data?.data?.data);
                    dispatch(insertSports(data?.data?.data || {}));
                }
            } catch (err) {
                console.log(err);
            }
        };
        if(sports.length === 0)
        fetchList();
        // eslint-disable-next-line
    }, []);

    const removeEventFilters = async (search = "") => {
        try {
            window.document.getElementById("searchInput").value = "";
            dispatch(insertSearchText(''));
            setSpinner(true);
            dispatch(setLoader(true));
            let body = {};
            setSearchProps({
                sports: [],
                daysWeek: [],
                gender: [],
                eventType: []
            });
            setSportsArray([]);
            setDaysWeekArray([]);
            setGendersArray([]);
            setEventsArray([]);

            const response = await fetchFilteredEventsService(body, search);
            const data = await response.data;
            if (response.status === 201) {
                const { league, pickup, tournament, sports } = data?.data;
                dispatch(insertLeagues(league || {}));
                dispatch(insertPickUp(pickup || {}));
                dispatch(insertTournaments(tournament || {}));
                dispatch(insertFilters({}));
                dispatch(insertSportsArray([]));
                dispatch(insertCategories( sports || []));
                dispatch(insertDaysArray([]));
                dispatch(insertGendersArray([]));
                dispatch(insertEventsArray([]));
                setSpinner(false);
                window.scrollTo({ top: 700, left: 0, behavior: "smooth" });
                dispatch(setLoader(false));
            }
        } catch (err) {
            setSpinner(false);
            swal("Error", err.response?.data?.message || "", "error");
            dispatch(setLoader(false));
        }
    }

    React.useEffect(() => {
        fetchFilteredEvents(searchText);
        // eslint-disable-next-line
    }, [searchText]);

    return (
        <div className='homepage_search_box'>
            <div className='homepage_search_box_flex_wrap'>
                <div className='homepage_search_box_flex_wrap_sub'>
                    <div className='homepage_formcontrol'>

                        <FormControl fullWidth color='warning'>
                            <InputLabel id="demo-multiple-checkbox-label">Sports</InputLabel>
                            <Select
                                disabled={spinner}
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                value={searchProps?.sports}
                                defaultValue={filters?.sports}
                                label="Sports"
                                onChange={(e) => handleChange('sports', e)}
                                multiple
                                MenuProps={{ className: classes.searchBarMenu }}
                                input={<OutlinedInput label="Sports" />}
                                renderValue={() => (
                                    <span>{sportsArray?.length > 0 ? `${sportsState?.find(sport => sport?.id === searchProps?.sports[0])?.name || ""} ${sportsArray?.length > 1 ? ` + ${sportsArray?.length - 1}` : ""} ` : null}</span>
                                )}
                            >
                                <MenuItem
                                    value="all"
                                    classes={{
                                        root: isAllSportsSelected ? classes.selectedAll : ""
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            classes={{ indeterminate: classes.indeterminateColor }}
                                            checked={isAllSportsSelected || sportsArray.length === sports?.length}
                                            indeterminate={
                                                sportsArray.length > 0 && sportsArray.length < sports?.length
                                            }
                                            color="warning"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{ primary: classes.selectAllText }}
                                        primary="Select All"
                                    />
                                </MenuItem>
                                {sportsState?.map((addon) => (
                                    <MenuItem
                                        key={addon?.id}
                                        value={addon?.id}
                                        style={{
                                            fontWeight: sportsArray.indexOf(addon?.id) > -1 ? '600' : '300',
                                            backgroundColor: sportsArray.indexOf(addon?.id) > -1 ? '#faf5e6' : '#fff',
                                        }}
                                    >
                                        <Checkbox checked={sportsArray.indexOf(addon?.id) > -1} color="warning" />
                                        <ListItemText
                                            primary={addon?.name}
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='homepage_formcontrol'>
                        <FormControl fullWidth color='warning'>
                            <InputLabel id="demo-multiple-checkbox-label">Days Of Week</InputLabel>
                            <Select
                                disabled={spinner}
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                value={searchProps.daysWeek}
                                defaultValue={filters?.daysWeek}
                                label="Days Of Week"
                                onChange={(e) => handleChange('daysWeek', e)}
                                multiple
                                MenuProps={{ className: classes.searchBarMenu }}
                                input={<OutlinedInput label="Days Of Week" />}
                                renderValue={() => (
                                    <span>{daysWeekArray.length > 0 ? `${daysWeek.find(day => day.id === searchProps.daysWeek[0])?.name || ""} ${daysWeekArray.length > 1 ? ` + ${daysWeekArray.length - 1}` : ""} ` : null}</span>
                                )}
                            >
                                <MenuItem
                                    value="all"
                                    classes={{
                                        root: isAllDaysSelected ? classes.selectedAll : ""
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            classes={{ indeterminate: classes.indeterminateColor }}
                                            checked={isAllDaysSelected || daysWeekArray.length === daysWeek.length}
                                            indeterminate={
                                                daysWeekArray.length > 0 && daysWeekArray.length < daysWeek.length
                                            }
                                            color="warning"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{ primary: classes.selectAllText }}
                                        primary="Select All"
                                    />
                                </MenuItem>
                                {daysWeek?.map((addon) => (
                                    <MenuItem
                                        key={addon.id}
                                        value={addon.id}
                                        style={{
                                            fontWeight: daysWeekArray.indexOf(addon.id) > -1 ? '600' : '300',
                                            backgroundColor: daysWeekArray.indexOf(addon.id) > -1 ? '#faf5e6' : '#fff',
                                        }}
                                    >
                                        <Checkbox checked={daysWeekArray.indexOf(addon.id) > -1} color="warning" />
                                        <ListItemText
                                            primary={addon.name}
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {props.hideEventType && <div className='homepage_formcontrol'>

                        <FormControl fullWidth color='warning'>
                            <InputLabel id="demo-multiple-checkbox-label">Gender</InputLabel>
                            <Select
                                disabled={spinner}
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                value={searchProps.gender}
                                defaultValue={filters?.gender}
                                label="Gender"
                                onChange={(e) => handleChange('gender', e)}
                                multiple
                                MenuProps={{ className: classes.searchBarMenu }}
                                input={<OutlinedInput label="Gender" />}
                                renderValue={() => (
                                    <span>{gendersArray.length > 0 ? `${genderTranslation(genders.find(gender => gender.id === searchProps.gender[0])?.name || "")} ${gendersArray.length > 1 ? ` + ${gendersArray.length - 1}` : ""} ` : null}</span>
                                )}
                            >
                                <MenuItem
                                    value="all"
                                    classes={{
                                        root: isAllGenderSelected ? classes.selectedAll : ""
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            classes={{ indeterminate: classes.indeterminateColor }}
                                            checked={isAllGenderSelected || gendersArray.length === genders.length}
                                            indeterminate={
                                                gendersArray.length > 0 && gendersArray.length < genders.length
                                            }
                                            color="warning"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{ primary: classes.selectAllText }}
                                        primary="Select All"
                                    />
                                </MenuItem>
                                {genders?.map((addon) => (
                                    <MenuItem
                                        key={addon.id}
                                        value={addon.id}
                                        style={{
                                            fontWeight: gendersArray.indexOf(addon.id) > -1 ? '600' : '300',
                                            backgroundColor: gendersArray.indexOf(addon.id) > -1 ? '#faf5e6' : '#fff',
                                        }}
                                    >
                                        <Checkbox checked={gendersArray.indexOf(addon.id) > -1} color="warning" />
                                        <ListItemText
                                            primary={genderTranslation(addon.name)}
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>}
                </div>
                {!props?.hideEventType &&
                    <div className='homepage_search_box_flex_wrap_sub'>
                        <div className='homepage_formcontrol'>

                            <FormControl fullWidth color='warning'>
                                <InputLabel id="demo-multiple-checkbox-label">Gender</InputLabel>
                                <Select
                                    disabled={spinner}
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    value={searchProps.gender}
                                    defaultValue={filters?.gender}
                                    label="Gender"
                                    onChange={(e) => handleChange('gender', e)}
                                    multiple
                                    MenuProps={{ className: classes.searchBarMenu }}
                                    input={<OutlinedInput label="Gender" />}
                                    renderValue={() => (
                                        <span>{gendersArray.length > 0 ? `${genderTranslation(genders.find(gender => gender.id === searchProps.gender[0])?.name || "")} ${gendersArray.length > 1 ? ` + ${gendersArray.length - 1}` : ""} ` : null}</span>
                                    )}
                                >
                                    <MenuItem
                                        value="all"
                                        classes={{
                                            root: isAllGenderSelected ? classes.selectedAll : ""
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                classes={{ indeterminate: classes.indeterminateColor }}
                                                checked={isAllGenderSelected || gendersArray.length === genders.length}
                                                indeterminate={
                                                    gendersArray.length > 0 && gendersArray.length < genders.length
                                                }
                                                color="warning"
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.selectAllText }}
                                            primary="Select All"
                                        />
                                    </MenuItem>
                                    {genders?.map((addon) => (
                                        <MenuItem
                                            key={addon.id}
                                            value={addon.id}
                                            style={{
                                                fontWeight: gendersArray.indexOf(addon.id) > -1 ? '600' : '300',
                                                backgroundColor: gendersArray.indexOf(addon.id) > -1 ? '#faf5e6' : '#fff',
                                            }}
                                        >
                                            <Checkbox checked={gendersArray.indexOf(addon.id) > -1} color="warning" />
                                            <ListItemText
                                                primary={genderTranslation(addon.name)}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>


                        </div>
                        <div className='homepage_formcontrol'>
                            <FormControl fullWidth color='warning'>
                                <InputLabel id="demo-multiple-checkbox-label">Event Type</InputLabel>
                                <Select
                                disabled={spinner}
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    value={searchProps.eventType}
                                    defaultValue={filters?.eventType}
                                    label="Event Type"
                                    onChange={(e) => handleChange('eventType', e)}
                                    multiple
                                    MenuProps={{ className: classes.searchBarMenu }}
                                    input={<OutlinedInput label="Event Type" />}
                                    renderValue={() => (
                                        <span>{eventsArray.length > 0 ? `${capitalizeFirstLetter(eventType.find(type => type.id === searchProps.eventType[0])?.name || "")} ${eventsArray.length > 1 ? ` + ${eventsArray.length - 1}` : ""} ` : null}</span>
                                    )}
                                >
                                    <MenuItem
                                        value="all"
                                        classes={{
                                            root: isAllEventSelected ? classes.selectedAll : ""
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                classes={{ indeterminate: classes.indeterminateColor }}
                                                checked={isAllEventSelected || eventsArray.length === eventType.length}
                                                indeterminate={
                                                    eventsArray.length > 0 && eventsArray.length < eventType.length
                                                }
                                                color="warning"
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.selectAllText }}
                                            primary="Select All"
                                        />
                                    </MenuItem>
                                    {eventType?.map((addon) => (
                                        <MenuItem
                                            key={addon.id}
                                            value={addon.id}
                                            style={{
                                                fontWeight: eventsArray.indexOf(addon.id) > -1 ? '600' : '300',
                                                backgroundColor: eventsArray.indexOf(addon.id) > -1 ? '#faf5e6' : '#fff',
                                            }}
                                        >
                                            <Checkbox checked={eventsArray.indexOf(addon.id) > -1} color="warning" />
                                            <ListItemText
                                                primary={capitalizeFirstLetter(addon?.name)}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>}
            </div>
            <div className='search_button_grp'>
                <button className='search_button' onClick={() => fetchFilteredEvents(searchText)}>
                    {spinner ? <CircularProgress style={{ color: "white" }} /> : <> <SearchIcon /> Search</>}
                </button>
                {(searchProps?.sports.length > 0 || searchProps?.daysWeek.length > 0 || searchProps?.eventType.length > 0 || searchProps?.gender.length > 0) && <Button color='warning' style={{ height: '53px' }} onClick={() => removeEventFilters("")}>
                    Clear
                </Button>}
            </div>
        </div>
    )
}
