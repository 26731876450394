import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

export const LoadingComponent = ({ children, loader }) => {
    return (
        <>
            {loader ? <div className='spinner_center_div'>< CircularProgress color='warning' /></div > : children}
        </>
    )
}
