import React from 'react'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from '../../../constants/DateFormat';

export const EventDates = (props) => {
  const endDateFlag = props?.endDate;
  const endTimeFlag = props?.endTimeNull || !endDateFlag;
  const startDate = moment(props?.startDate).format(DATE_FORMAT);
  const endDate = endDateFlag ? moment(props?.endDate).format(DATE_FORMAT) : "";
  const startTime = moment(props?.startDate).format(TIME_FORMAT);
  const endTime = (endTimeFlag) ? "" : moment(props?.endDate).format(TIME_FORMAT);

  return (
    <div className='event_details_date'>
      <CalendarTodayOutlinedIcon style={{ color: "#E074B2" }} /> <span className='event_details_date_title'>{startDate}{endDateFlag && " to "} {endDate}
        <br />
        <span className='event_details_date_subtitle'>{startTime} {!endTimeFlag && " to "} {endTime}</span>
      </span>
    </div>
  )
}
