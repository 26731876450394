import React from 'react'
import OtpInput from 'react-otp-input';
import { privacyAbbreviation } from '../../Utils/abbreviators';
import { inputStyle, useStyles } from '../../styles/components/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthForgotPasswordServices, AuthOTPVerificationServices } from '../../Services/Auth.services';
import swal from 'sweetalert';
import { isValidEmail } from '../../Utils/validators';
import { MESSAGES } from '../../constants/ValidationMsgs';
import { LoadingText } from '../LoadingText';

export const OtpForm = (props) => {
    const classes = useStyles();
    const [otp, setOtp] = React.useState("");
    const handleChange = (otp) => {
        setOtp(otp);
    }

    const [loader, setLoader] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);


    const resendOTP = async () => {
        if (isValidEmail(props?.email) && !spinner) {
            setSpinner(true);
            try {
                const response = await AuthForgotPasswordServices({ email: props?.email });
                setSpinner(false);
                if (response.status === 201) {
                    swal("Success", MESSAGES.RESEND_OTP_SUCCESS, "success");
                }
            } catch (err) {
                setSpinner(false);
                swal('Error', err.response?.data?.message || "", 'error');
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (otp) {
                setLoader(true);
                const response = await AuthOTPVerificationServices(props.email, otp);
                if (response.status === 201) {
                    props.setOtp(Number(otp));
                    props.setAuthEnum(4);
                    setLoader(false);
                }
            }
        } catch (err) {
            swal("Error", err.response?.data?.message || "", "error");
            setLoader(false);
        }
    }
    return (
        <div className='login_div'>
            <div className={classes.assignmentGrid}>
                <h1>Enter the OTP</h1>
                <p>Send to your email {privacyAbbreviation(props?.email, 5)}</p>
                <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={4}
                    isInputNum="true"
                    inputStyle={inputStyle}
                    separator={<span>-</span>}
                />
                <div className='resend_otp_div'>
                    {spinner ? <CircularProgress size={"24px"} style={{ color: "rgba(250, 166, 28, 0.9)" }} /> : <span onClick={resendOTP}>Resend OTP</span>}
                </div>
                <button className='auth_button' onClick={handleSubmit}>
                    <LoadingText text="Verify Account" loader={loader} />
                </button>
            </div>
        </div>
    )
}
