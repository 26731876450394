import React from "react";
import GoogleMapReact from 'google-map-react';
import "../../styles/components/googleMap.css";
import MarkerPin from "./MarkerPin";
import { multiSportColor } from "../../constants/colors";
import { mergeDuplicate } from "../../Utils/helpers";

export default function SimpleMap(props) {
    const defaultProps = {
        center: {
            lat: props?.lat,
            lng: props?.lng
        },
        zoom: 11
    };

    return (
        <div style={{ height: props?.height, width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GCP_KEY }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                options={{ scrollwheel: false }}
            >
                {!props?.denied && <MarkerPin
                    defaultMarker={true}
                    lat={props?.lat}
                    lng={props?.lng}
                    name={props?.mainMarkerTitle}
                    src={null}
                    color={props?.mainMarkerColor}
                />}
                {mergeDuplicate(props?.events)?.map((event, index) => {
                    return <MarkerPin
                        key={event?.data[0]?.eventId}
                        defaultMarker={false}
                        lat={event?.latitude}
                        lng={event?.longitude}
                        name={event?.data[0]?.name}
                        src={event?.data[0]?.iconMedia}
                        color={event.data.length > 1 ? multiSportColor :  event?.data[0]?.category.bgColor}
                        event={event?.event}
                        data={event.data}
                    />
                })}
            </GoogleMapReact>
        </div>
    );
}