
import instance from "../Interceptor";
import { fixedEncodeURIComponent } from "../Utils/abbreviators";
import headers from "../Utils/headers";



export const getVideoService = async () => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/home-screen-video`, {
        method: "GET",
        headers
    });
    return response;
}

export const fetchEventsList = async () => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/event`, {
        method: "GET",
        headers
    });
    return response;
}

export const getNotificationList = async () => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/notifications`, {
        method: "GET",
        headers
    });
    return response;
}


export const fetchSportsList = async () => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/sport/list`, {
        method: "GET",
        headers
    });
    return response;
}


export const fetchEventsDetailsById = async (id) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/event/${id}`, {
        method: "GET",
        headers
    });
    return response;
}

export const getFavouritesListService = async () => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/user/favourite-organizer-list`, {
        method: "GET",
        headers
    });
    return response;
}

export const fetchFilteredEventsService = async ({ sport_id, days_of_week, gender, type }, search="") => {
    const url = fixedEncodeURIComponent(search);
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/event/filter?search=${url}`, {
        method: "POST",
        headers,
        data: JSON.stringify({
            sport_id,
            type,
            gender,
            days_of_week
        })
    });
    return response;
}

export const fetchFilteredEventsBySportsIDService = async (sport_id) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/event/filter`, {
        method: "POST",
        headers,
        data: JSON.stringify({
            sport_id
        })
    });
    return response;
}

export const markAsReadService = async (sport_id) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/notifications/mark-as-read`, {
        method: "POST",
        headers
    });
    return response;
}

export const registerButtonApi = async (sport_id, id) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/event/register-count/${id}/${sport_id}`, {
        method: "POST",
        headers
    });
    return response;
}