
import React from 'react'
import "../../styles/components/homepage_main.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import { SportsCategoryCard } from '../Cards/SportsCategoryCard';

export const SportsCategory = (props) => {
    const responsiveOptions = {
        0: {
            items: 1,
            nav: true
        },
        480: {
            items: 2,
            nav: true
        },
        768: {
            items: 4,
            nav: true
        },
        900: {
            items: 5,
            nav: true
        }
    }

    return (
        <div className='hompage_subcomponent_category'>
            <div className='hompage_subcomponent_header'>
                <span className='location_title'>Sports</span>
            </div>
            {props?.sportsList?.length === 0 && <div className='no_events_available_div'>
                <p>No Sports available.</p>
            </div>}
            {props?.sportsList?.length > 0 && <OwlCarousel responsive={responsiveOptions} className='sports_category_grid' dots={true} dotsEach={true} loop={false} margin={10} nav={true} navText={["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"]}>
                {props?.sportsList?.map(({ id, name, iconMedia, count, bgColor }, index) => <SportsCategoryCard key={id} src={`${process.env.REACT_APP_CLOUDFRONT}/${iconMedia}`} name={name} themeColor={bgColor} totalCount={count} id={id} />)}
            </OwlCarousel>}
        </div>
    )
}
