import React from 'react'

export const SportsCategoryInfo = (props) => {
    return (
        <div className='event_details_location'>
            <div className='event_details_location_flex'>
                <img alt='sports' src={`${process.env.REACT_APP_CLOUDFRONT}/${props?.src}`} className='filter_png_img' />
                <span className='event_details_date_title' title={props?.title}>{props.title}<br />
                </span>
            </div>
        </div>
    )
}
