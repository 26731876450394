import React, { useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import swal from 'sweetalert';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useStyles, useStylesProfile } from '../../styles/components/styles';
import { MESSAGES } from '../../constants/ValidationMsgs';
import { AuthChangePasswordServices } from '../../Services/Auth.services';

export default function ChangePasswordForm() {
    const classes = useStyles();
    const classesCommon = useStylesProfile();

    const handleClickShowPassword1 = () => {
        setValues({
            ...values,
            showPassword1: !values.showPassword1
        });
    };
    const handleClickShowPassword2 = () => {
        setValues({
            ...values,
            showPassword2: !values.showPassword2
        });
    };
    const handleClickShowPassword3 = () => {
        setValues({
            ...values,
            showPassword3: !values.showPassword3
        });
    };

    const [cPwdWrg, setCPwdWrg] = useState('Password must be atleast 6 characters long.');
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [loading, setLoading] = useState({
        forPasswordVerification: false,
        forPasswordUpdate: false
    });

    const [values, setValues] = useState({
        cPassword: '',
        newPassword: '',
        confirmPassword: '',
        showPassword1: false,
        showPassword2: false,
        showPassword3: false
    });

    const [spinner, setSpinner] = useState(false);
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        if (prop === 'cPassword') {
            setErrors({
                ...errors,
                cPassword: !event.target.value
            });
        }
        if (prop === 'newPassword') {
            setErrors({
                ...errors,
                newPassword: event.target.value.length < 6
            });
        }
        if (prop === 'confirmPassword') {

            setErrors({
                ...errors,
                confirmPassword: values.newPassword !== event.target.value
            });
        }
    };

    const [errors, setErrors] = useState({
        cPassword: false,
        newPassword: false,
        confirmPassword: false
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (
            values.cPassword.length >= 6 &&
            values.newPassword === values.confirmPassword &&
            values.newPassword.length >= 6
        ) {
            setSpinner(true);
            setLoading({ ...loading, forPasswordVerification: true });
            try {
                const response = await AuthChangePasswordServices(values.newPassword);
                const data = await response.data;

                swal("Success", data.message, "success").then(()=>{
                    window.location.reload();
                })
            } catch (err) {
                if (err.response.status === 400) {
                    setCPwdWrg('Current password is incorrect.');
                    setErrors({ ...errors, cPassword: true });
                }
                if (err.response.status === 403) {
                    swal(err.response?.data?.message || "", '', 'error');
                }
                if (err.response.status === 403) {
                    swal(err.response?.data?.message || "", '', 'error');
                }
            }
        }
        else if (values.cPassword.length < 6) {
            setErrors({ ...errors, cPassword: values.cPassword.length < 6 });
        }
        else if (values.confirmPassword !== values.newPassword) {
            setErrors({ ...errors, confirmPassword: true });
        }
        else if (values.cPassword && values.newPassword.length < 6) {
            setErrors({ ...errors, newPassword: values.newPassword.length < 6 });
        }
        setSpinner(false);
    };

    return (
                            <div className={classes.boxMargin}>
                                <h2>Change Password</h2>
                                <Box
                                    onSubmit={handleSubmit}
                                    component="form"
                                    className={classesCommon.boxPadding}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <FormControl className={classes.fullWidthTextField} color="warning" variant="outlined">
                                        <InputLabel
                                            style={{ color: errors.cPassword ? '#D10000' : 'grey' }}
                                            htmlFor="outlined-adornment-cpassword"
                                        >
                                            Current Password
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-cpassword"
                                            type={values.showPassword1 ? 'text' : 'password'}
                                            value={values.cPassword}
                                            className={classes.fullWidth}
                                            onChange={handleChange('cPassword')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword1}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showPassword1 ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Current Password"
                                            autoComplete="off"
                                            error={errors.cPassword}
                                        />
                                        {errors.cPassword && (
                                            <FormHelperText error id="accountId-error">
                                                {values.cPassword.length === 0
                                                    ? MESSAGES.PASSWORDREGQUIRED
                                                    : errors.cPassword && cPwdWrg}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl className={classes.fullWidthTextField} color="warning" variant="outlined">
                                        <InputLabel
                                            style={{
                                                color: errors.newPassword ? '#D10000' : 'grey'
                                            }}
                                            htmlFor="outlined-adornment-newpassword"
                                        >
                                            New Password
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-newpassword"
                                            type={values.showPassword2 ? 'text' : 'password'}
                                            value={values.newPassword}
                                            className={classes.fullWidth}
                                            onChange={handleChange('newPassword')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword2}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showPassword2 ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="New Password"
                                            autoComplete="off"
                                            error={errors.newPassword}
                                        />
                                        {errors.newPassword && (
                                            <FormHelperText error id="accountId-error">
                                                {values.newPassword.length === 0
                                                    ? MESSAGES.PASSWORDREGQUIRED
                                                    : MESSAGES.INVALIDPASSWORD}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl className={classes.fullWidthTextField} color="warning" variant="outlined">
                                        <InputLabel
                                            style={{
                                                color: errors.confirmPassword ? '#D10000' : 'grey'
                                            }}
                                            htmlFor="outlined-adornment-confirm-password"
                                        >
                                            Confirm Password
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-confirm-password"
                                            type={values.showPassword3 ? 'text' : 'password'}
                                            value={values.confirmPassword}
                                            className={classes.fullWidth}
                                            onChange={handleChange('confirmPassword')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword3}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showPassword3 ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Confirm Password"
                                            autoComplete="off"
                                            error={errors.confirmPassword}
                                        />
                                        {errors.confirmPassword && (
                                            <FormHelperText error id="accountId-error">
                                                {values.confirmPassword.length === 0
                                                    ? MESSAGES.CONFIRMPASSWORDREQUIRED
                                                    : MESSAGES.INVALIDCONFIRMPASSWORD}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <div
                                        className={classesCommon.loadingDiv}
                                    >

                    <button className='auth_button' onClick={handleSubmit} disabled={spinner}>
                        {spinner ? <CircularProgress style={{ color: "white", fontSize: "9px" }} /> : "Update Password"}
                    </button>
                                    </div>
                                </Box>
                            </div>
    );
}
