export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


export function abbreviate(s, length) {
    return `${s?.slice(0, length)} ${s?.length > length ? '...' : ''}`;
}

export function privacyAbbreviation(s, length) {
    const indexofAt = s.indexOf('@');
    const beginOfAbbreviation = Math.abs(indexofAt - length);
    let stars = "";
    for (let i = beginOfAbbreviation; i < indexofAt; i++)
        stars += "*";
    return `${s?.slice(0, beginOfAbbreviation)}${stars}${s?.slice(indexofAt, s.length)}`;
}

export function renderAvatarTitle(firstName, lastName, email) {

    if (firstName && lastName) {
        return `${firstName[0]}${lastName[0]}`;
    }
    else if (firstName && !lastName) {
        return `${firstName[0]}`;
    }
    else {
        return `${email[0].toUpperCase()}`;
    }
}

export function fixedEncodeURIComponent(str) {
    let encodedUrl = encodeURIComponent(str);
    encodedUrl = encodedUrl.replace(/[!'()*]/g, (c) => `%${c.charCodeAt(0).toString(16)}`);
    return encodedUrl;
}