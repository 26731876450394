import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

export const LoadingModal = ({ children, loader }) => {
    return (
        <>
            {loader ? <div className='circular_spinner_div'>< CircularProgress color='warning' /></div > : children}
        </>
    )
}
