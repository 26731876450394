import React from 'react'
import { Link } from 'react-router-dom'

export const SportsCategoryCard = (props) => {
  return (
    <Link className='navlinks_link_black' to={`category/${props?.name}/${props?.id}`}>
      <div className='sports_category_grid_card' style={{ backgroundColor: `${props.themeColor}` }}>
        <img alt="img" src={props.src} className="sports_category_grid_card_img" />
        <div className='sports_category_title_div'>
          <span className='sports_category_title'>{props.name}</span>
          <span>{props.totalCount} Events Nearby</span>
        </div>
      </div>
    </Link>
  )
}
