import React, { useState } from 'react';
import "../../styles/components/Slider.css";
import defaultPic from "../../Images/assmbly_logo.jpeg";
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

const ImageSlider = ({ slides }) => {
    const [current, setCurrent] = useState(0);
    const length = slides.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    return (
        <section className='slider'>
            {
                length === 0 ?
                    <div className='slide active'
                    // style={{ backgroundImage: `url(${defaultPic})`, backgroundSize: 'cover' }}
                    >
                        <img src={defaultPic} className="image" alt="no pic" />
                    </div> :
                    slides.map((slide, index) => {
                        return (
                            <div
                                className={index === current ? 'slide active' : 'slide'}
                                key={index}
                            // style={{ backgroundImage: slide?.src ? `url(${process.env.REACT_APP_CLOUDFRONT}/${slide?.src})` : `url(${process.env.REACT_APP_CLOUDFRONT}/${slide?.image})`, backgroundSize: 'cover' }}
                            >
                                {length > 1 && <> <div className='left-arrow' onClick={prevSlide}><ArrowCircleLeftOutlinedIcon fontSize={'24px'} style={{ color: 'white', background: 'rgba(0,0,0,0.5)', borderRadius: '50%' }} /></div>
                                    <div className='right-arrow' onClick={nextSlide}><ArrowCircleRightOutlinedIcon fontSize={'24px'} style={{ color: 'white', background: 'rgba(0,0,0,0.5)', borderRadius: '50%' }} /></div> </>}
                                {index === current && (
                                    <img src={slide.src ? `${process.env.REACT_APP_CLOUDFRONT}/${slide?.src}` : `${process.env.REACT_APP_CLOUDFRONT}/${slide?.image}`} alt='travel' className='image' />
                                )}
                            </div>
                        );
                    })}
        </section>
    );
};

export default ImageSlider;