import { createSlice } from '@reduxjs/toolkit'

export const dataManager = createSlice({
  name: 'dataManager',
  initialState: {
    leagues : {},
    tournaments : {},
    pickup : {},
    sports:[],
    loader : false,
    categories: [],
    searchText: '',
    coordinates : {
      lat : '',
      lng : ''
    },
    filters:{
      sports : [],
      daysWeek : [],
      gender : [],
      eventType : []
    },
    gendersArray : [],
    eventsArray: [],
    sportsArray: [],
    daysWeekArray: [],
  },
  reducers: {
    insertLeagues: (state,actions) => {
      state.leagues = actions.payload;
      return state;
    },
    insertSports: (state, actions) => {
      state.sports = actions.payload;
      return state;
    },
    setLoader: (state, actions) => {
      state.loader = actions.payload;
      return state;
    },
    insertCategories: (state, actions) => {
      state.categories = actions.payload;
      return state;
    },
    insertPickUp: (state, actions) => {
      state.pickup = actions.payload;
      return state;
    },
    insertTournaments: (state, actions) => {
      state.tournaments = actions.payload;
      return state;
    },
    insertCoordinates: (state, actions) => {
      state.coordinates = actions.payload;
      return state;
    },
    insertFilters : (state, actions) => {
      state.filters = actions.payload;
      return state;
    },
    insertSportsArray: (state, actions) => {
      state.sportsArray = actions.payload;
      return state;
    },
    insertEventsArray: (state, actions) => {
      state.eventsArray = actions.payload;
      return state;
    },
    insertGendersArray: (state, actions) => {
      state.gendersArray = actions.payload;
      return state;
    },
    insertDaysArray: (state, actions) => {
      state.daysWeekArray = actions.payload;
      return state;
    },
    insertSearchText : (state, actions)=>{
      state.searchText = actions.payload;
      return state;
    }
  }
});


export const { insertLeagues, insertPickUp, insertTournaments, insertCoordinates, insertSports, insertFilters, insertSportsArray, insertEventsArray, insertGendersArray, insertDaysArray, insertSearchText, insertCategories, setLoader } = dataManager.actions;

export default dataManager.reducer;