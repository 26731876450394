export const bgColors = [
  "#E074B2",
  "#87D2DB",
  "#FAA61C",
  "#EFA09C",
  "#85B4EE",
  "#C8C7CC",
  "#EFA09C",
  "#87D2DB",
  "#EF9CC1",
  "#A09CEF",
  "#9CEFA0",
];

export const multiSportColor =  "#E074B2";