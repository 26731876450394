import React from 'react'
import ShareIcon from '@mui/icons-material/Share';
import { Link } from 'react-router-dom';
import { getDistance } from '../../Utils/DistanceCalculator';
import { abbreviate } from '../../Utils/abbreviators';
import { localStorageEnums } from '../../constants/LocalStorageEnums';
import TransitionsModal from '../Modal';
import Button from '@mui/material/Button';
import socialShareIcon from "../../Images/social-media.png"
import StyledSnackbar from '../StyledSnackbar';

export const PickupGamesCard = (props) => {
    const locationStatus = window.localStorage.getItem(localStorageEnums.LOCATIONSTATUS);
    const [open, setOpen] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const [snackbarData, setSnackBarData] = React.useState({
        xPos: 'center',
        yPos: 'bottom',
        title: '',
        status: 'success'
    });

    const copyCall = () => {
        setOpenModal(false);
        navigator.clipboard.writeText(props?.dynamicLink);
        setSnackBarData({
            xPos: 'center',
            yPos: 'bottom',
            title: 'Copied to clipboard',
            status: 'success'
        });
        handleClick();
    };

    return (
        <div>
            <TransitionsModal open={openModal} handleClose={() => setOpenModal(false)}>
                <div className='dynamic_link_img_div'>
                    <img className='dynamic_link_img' alt='social_share_img' src={socialShareIcon} />
                </div>
                <div className='dynamic_link_div'>
                    <span className='dynamic_link'>{abbreviate(props?.dynamicLink, 36)}</span>
                    <Button color='warning' onClick={copyCall}>Copy</Button>
                </div>
            </TransitionsModal>
            <div className='pickup_card'>
                <div className='pickup_card_img' style={{ backgroundImage: `url('${props?.src}')`, backgroundSize: 'cover' }}>
                    {props?.dynamicLink && <div className='share_float_button' onClick={() => setOpenModal(true)}>
                        <ShareIcon style={{ color: 'white' }} />
                    </div>}
                    <img src={props.src} className="pickup_cover" alt="img" />
                </div>
                <Link className='no_decoration_link_card' to={`/event/${props?.type}/${props?.id}`}>
                    <div className='pickup_details'>
                        <span className='pickup_red' title={props?.organizer}>{abbreviate(props?.organizer || "", 20)}</span>
                        <span className='pickup_name' title={props?.title}>{abbreviate(props?.title, 25)}</span>
                        <span className='pickup_distance'>{(locationStatus === "granted" && !isNaN(getDistance(props?.coordinates?.lat, props?.coordinates?.lng, props?.latitude, props?.longitude))) && `${getDistance(props?.coordinates?.lat, props?.coordinates?.lng, props?.latitude, props?.longitude)} miles`}</span>
                    </div>
                </Link>
            </div>
            <StyledSnackbar
                xPos={snackbarData.xPos}
                yPos={snackbarData.yPos}
                title={snackbarData.title}
                status={snackbarData.status}
                open={open}
                onClose={handleClose}
            />

        </div>
    )
}
