import instance from "../Interceptor";
import headers from "../Utils/headers";

export const fetchOrganizerInfoByID = async (id) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/organizer/info/${id}`, {
        method: "GET",
        headers,
    });
    return response;
}

export const fetchEventsListByOID = async (id) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/event/organizer/${id}`, {
        method: "GET",
        headers
    });
    return response;
}

export const FavUnFavOrg = async (id, value) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/user/favourite-unfavourite-organizer`, {
        method: "POST",
        headers,
        data: JSON.stringify({
            organizer_id: Number(id),
            is_favorite: (value)
        })
    });
    return response;
}