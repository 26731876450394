import React from 'react'
import "./auth.css";
import AppleLogin from 'react-apple-login';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import GoogleIcon from '@mui/icons-material/Google'
import { isStrongPassword, isValidEmail } from '../../Utils/validators';
import { MESSAGES } from '../../constants/ValidationMsgs';
import { appleLogin, AuthRegisterServices, getAddress, getGoogleUserDetails, googleLogin } from '../../Services/Auth.services';
import swal from 'sweetalert';
import { localStorageEnums } from '../../constants/LocalStorageEnums';
import { SCOPES } from '../../constants/Scopes';
import { useGoogleLogin } from '@react-oauth/google';
import { LoadingText } from '../LoadingText';
import { LoadingSpinnerComponent } from '../LoadingSpinnerComponent';
import { googleAutocompleteStyles } from '../../styles/googleAutocompleteStyling';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { SEGMENT_ACTIONS } from '../../Utils/Favored/actions';
import { combineIdentifySegmentPixel, onBoardingCustomEvent } from '../../Utils/Favored';
import * as Sentry from "@sentry/react";
import { Apple } from '@mui/icons-material';

export const RegisterForm = (props) => {

    const [coordinates, setCoordinates] = React.useState({
        lat: '',
        lng: ''
    });

    const [spinner, setSpinner] = React.useState(false);

    const [appleSpinner, setAppleSpinner] = React.useState(false);


    const [apiPlaceData, setApiPlaceData] = React.useState();

    React.useEffect(() => {
        try {
            window.navigator.permissions.query({ name: 'geolocation' }).then(function (status) {
                if (status.state === "granted") {
                    window.navigator.geolocation.getCurrentPosition(async ({ coords }) => {
                        const { latitude, longitude } = coords;
                        setCoordinates({
                            lat: latitude,
                            lng: longitude
                        });
                    });
                }
            })
        } catch (err) {
            console.log(err);
        }
    }, []);

    const [loader, setLoader] = React.useState(false);

    const [agree, setAgree] = React.useState(true);
    const [miniSpinner, setMiniSpinner] = React.useState(false);


    const [formBody, setFormBody] = React.useState({
        fullName: '',
        email: '',
        password: '',
        cpassword: ''
    });

    const [errors, setErrors] = React.useState({
        fullName: false,
        email: false,
        password: false,
        cpassword: false
    });

    const [agreeTCError, setAgreeTCError] = React.useState(false);

    const [values, setValues] = React.useState({
        showPassword: false,
        showCPassword: false,
    });

    const [address, setAddress] = React.useState('');

    const handleChange = async (e, param) => {
        if (param === "email") {
            setFormBody({
                ...formBody,
                email: e.target.value.trim()
            });

            setErrors({
                ...errors,
                email: !isValidEmail(e.target.value)
            })
        }
        else if (param === "password") {
            setFormBody({
                ...formBody,
                password: e.target.value.trim()
            });

            setErrors({
                ...errors,
                password: !isStrongPassword(e.target.value)
            })
        }
        else if (param === "cpassword") {
            setFormBody({
                ...formBody,
                cpassword: e.target.value.trim()
            });

            setErrors({
                ...errors,
                cpassword: formBody.password !== e.target.value
            })
        }
        else {
            setFormBody({
                ...formBody,
                [param]: e.target.value.trim().length > 0 ? e.target.value : e.target.value.trim()
            });
            setErrors({
                ...errors,
                [param]: !e.target.value.trim()
            })
        }
    }

    const handleClickShowPassword = (value) => {
        if (value === 1)
            setValues({
                ...values,
                showPassword: !values.showPassword,
            });
        else
            setValues({
                ...values,
                showCPassword: !values.showCPassword,
            });
    };

    React.useEffect(() => {
        try {
            window.navigator.permissions.query({ name: 'geolocation' }).then(function (status) {
                if (status.state === "granted") {
                    const fetchAddress = async () => {
                        const getAddressValue = await getAddress({ latitude: coordinates.lat, longitude: coordinates.lng });
                        setAddress(getAddressValue.title);
                    }
                    if (coordinates.lat) {
                        fetchAddress();
                    }
                }
            })
        } catch (err) {
            console.log(err);
        }
        // eslint-disable-next-line
    }, [coordinates.lat]);

    React.useEffect(() => {
        if (apiPlaceData) {
            geocodeByPlaceId(apiPlaceData.value.place_id)
                .then(results => {
                    setAddress(results[0].formatted_address);
                    setCoordinates({ lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() });
                })
                .catch(error => console.error(error));
        }
        // eslint-disable-next-line
    }, [apiPlaceData]);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const submitForm = async () => {
        if (formBody.fullName.trim() && isValidEmail(formBody.email) && isStrongPassword(formBody.password) && formBody.password === formBody.cpassword && agree) {
            try {
                setLoader(true);
                let addressSearched = address;
                if (!coordinates.lat && !coordinates.lng) {
                    const getAddressValue = await getAddress({ latitude: Number(29.772600), longitude: Number(-95.514500) });
                    addressSearched = (getAddressValue.title);
                }
                const body = {
                    fullName: formBody.fullName,
                    address: addressSearched,
                    password: formBody.password,
                    email: formBody.email,
                    loc_lat: 29.772600,
                    loc_long: -95.514500,
                };
                if (coordinates.lat && coordinates.lng) {

                    body.loc_lat = coordinates.lat;
                    body.loc_long = coordinates.lng;

                };
                const response = await AuthRegisterServices(body);
                const data = await response.data;
                setLoader(false);
                if (response.status === 201) {
                    Promise.all(combineIdentifySegmentPixel(SEGMENT_ACTIONS.USER_REGISTERS, data.data), onBoardingCustomEvent(SEGMENT_ACTIONS.USER_STARTS_ONBOARDING, {
                        isOnboardStarted: true
                    }));
                    swal("Success", data.message, "success").then(() => {
                        window.location.reload();
                    })
                }
            } catch (err) {
                setLoader(false);
                swal("Error", err.response?.data?.message || "", "error");
            }
        }
        else {
            setErrors({
                fullName: !formBody.fullName.trim(),
                email: !isValidEmail(formBody.email),
                password: !isStrongPassword(formBody.password),
                cpassword: formBody.password !== formBody.cpassword || !isStrongPassword(formBody.cpassword)
            })
            setAgreeTCError(!agree);
        }
    }

    const onSuccess = async (codeResponse) => {
        setSpinner(true);
        try {
            const response_google = await getGoogleUserDetails(codeResponse?.access_token);
            const user = await response_google.data;
            const response = await googleLogin(user, codeResponse?.access_token);
            const data = response.data;
            if (response.status === 201) {
                const { accessToken, user } = data?.data;
                window.localStorage.setItem(localStorageEnums.USERDATA, JSON.stringify(user));
                window.localStorage.setItem(localStorageEnums.TOKEN, accessToken);
                window.localStorage.setItem(localStorageEnums.USERID, user?.id);
                setSpinner(false);
                combineIdentifySegmentPixel(SEGMENT_ACTIONS.USER_REGISTERS, data?.data);
                if (accessToken) {
                    Sentry.setUser({
                        id: user?.id,
                        name: user?.name,
                        email: user?.name
                    });
                }
                swal("Success", "Login successful.", "success").then(() => window.location.reload());
            }
        }
        catch (err) {
            setSpinner(false);
            swal("Info", err.response?.data?.message || "", "info");
        };
    };
    const login = useGoogleLogin({
        flow: 'implicit',
        onSuccess: codeResponse => onSuccess(codeResponse),
        onError: err => {
            console.log(err)
        },
        scope: SCOPES
    });

    const getCurrentLocation = () => {
        try {
            window.navigator.permissions.query({ name: 'geolocation' }).then(function (status) {
                if (status.state === "granted") {
                    window.navigator.geolocation.getCurrentPosition(async ({ coords }) => {
                        const { latitude, longitude } = coords;
                        setCoordinates({
                            lat: latitude,
                            lng: longitude
                        });
                        setMiniSpinner(true);
                        const getAddressValue = await getAddress({ latitude: latitude, longitude: longitude });
                        setAddress(getAddressValue.title);
                        setMiniSpinner(false);
                    });
                }
            })
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div>
            <h1>Create Account</h1>
            <span className='auth_form_link' onClick={() => props.setAuthEnum(0)}>Already have an account? <span className='auth_link'> Sign in here.</span></span>
            <div className='auth_form_div'>
                <div className='login_textfield_div_two'>
                    <TextField
                        color='warning'
                        id="outlined-basic"
                        style={{ width: '48%' }}
                        label="Full Name"
                        variant="outlined"
                        value={formBody.fullName}
                        error={errors.fullName}
                        helperText={errors.fullName ? MESSAGES.FULLNAMEREQUIRED : null}
                        autoComplete="off"
                        onChange={(e) => handleChange(e, "fullName")} />
                    <TextField
                        color='warning'
                        id="outlined-basic"
                        style={{ width: '48%' }}
                        label="Email"
                        type="email"
                        variant="outlined"
                        value={formBody.email}
                        error={errors.email}
                        autoComplete="off"
                        helperText={errors.email ? formBody.email ? MESSAGES.INVALIDEMAIL : MESSAGES.EMAILREQUIRED : null}
                        onChange={(e) => handleChange(e, "email")} />
                </div>
                <div className='login_textfield_div_flex'>
                    {miniSpinner ?
                        <div className='div_center'>
                            <CircularProgress color='warning' />
                        </div> : <div style={{ width: '100%' }} title={address}>
                            <GooglePlacesAutocomplete
                                apiKey={process.env.REACT_APP_GCP_KEY}
                                libraries={["places"]}
                                selectProps={{
                                    placeholder: "Search venue location...",
                                    defaultInputValue: address,
                                    isClearable: "true",
                                    backspaceRemovesValue: "true",
                                    styles: googleAutocompleteStyles,
                                    apiPlaceData,
                                    onChange: setApiPlaceData
                                }}
                            />
                        </div>}
                    <Button disabled={miniSpinner} onClick={getCurrentLocation} color='warning'>
                        <MyLocationIcon />
                    </Button>
                </div>
                <div className='login_textfield_div'>
                    <FormControl color='warning' style={{ width: '100%' }} variant="outlined" error={errors.password}>
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={formBody.password}
                            style={{ width: '100%' }}
                            onChange={(e) => handleChange(e, 'password')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => handleClickShowPassword(1)}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                        {errors.password && (
                            <FormHelperText error id="accountId-error">
                                {formBody?.password?.length === 0
                                    ? MESSAGES.PASSWORDREGQUIRED
                                    : MESSAGES.INVALIDPASSWORD}
                            </FormHelperText>
                        )}

                    </FormControl>
                </div>
                <div className='login_textfield_div'>
                    <FormControl color='warning' style={{ width: '100%' }} variant="outlined" error={errors.cpassword}>
                        <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={values.showCPassword ? 'text' : 'password'}
                            value={formBody.cpassword}
                            style={{ width: '100%' }}
                            onChange={(e) => handleChange(e, 'cpassword')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => handleClickShowPassword(2)}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showCPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Confirm Password"
                        />
                        {(errors.cpassword && (formBody.password !== formBody.cpassword || !formBody.cpassword)) && (
                            <FormHelperText error id="accountId-error">
                                {formBody?.cpassword?.length === 0
                                    ? MESSAGES.CONFIRMPASSWORDREQUIRED
                                    : MESSAGES.INVALIDCONFIRMPASSWORD}
                            </FormHelperText>
                        )}
                    </FormControl>
                </div>
                <FormGroup style={{ width: '100%', display: 'flex', flexDirection: 'row', maxWidth: 'max-content', alignItems: 'center' }}>
                    <FormControlLabel style={{ marginRight: '0px' }} control={<Checkbox checked={agree} onChange={() => { setAgree(!agree); setAgreeTCError(!agree); }} color="warning" />} />
                    <span>I agree to <a className='terms_condition_Link' href="/terms-and-conditions">Terms</a> and <a className='terms_condition_Link' href="/privacy-policy">Conditions</a>.</span>
                    {agreeTCError && <FormHelperText style={{ color: '#d32f2f', marginBottom: '10px' }}>{MESSAGES.AGREE_TANDC}</FormHelperText>}
                </FormGroup>
                <button className='auth_button' onClick={submitForm} disabled={loader}>
                    <LoadingText text="Sign up" loader={loader} />
                </button>
            </div>
            <div className='divider' />
            <div className='social_links_div'>
                <p className='social_div_heading'>Or sign in with your social network</p>
                <div className='social_div_buttons'>
                    <LoadingSpinnerComponent loader={spinner}>
                        <button className='social_button' onClick={login}> <GoogleIcon style={{ marginRight: '5px' }} fontSize='18px' />    Google</button>
                    </LoadingSpinnerComponent>
                    <LoadingSpinnerComponent loader={appleSpinner}>
                        <AppleLogin
                            clientId={process.env.REACT_APP_APPLE_CLIENT_ID}
                            redirectURI={`https://${window.location.hostname}/`}
                            callback={async (response) => {
                                if (!response.error) {
                                    setAppleSpinner(false);
                                }
                                const responseLogin = await appleLogin(response);
                                const data = responseLogin.data;
                                if (responseLogin.status === 201) {
                                    const { accessToken, user } = data?.data;
                                    window.localStorage.setItem(localStorageEnums.USERDATA, JSON.stringify(user));
                                    window.localStorage.setItem(localStorageEnums.TOKEN, accessToken);
                                    window.localStorage.setItem(localStorageEnums.USERID, user?.id);
                                    setAppleSpinner(false);
                                    combineIdentifySegmentPixel(SEGMENT_ACTIONS.USER_LOGINS, user);
                                    if (accessToken) {
                                        Sentry.setUser({
                                            id: user?.id,
                                            name: user?.name,
                                            email: user?.name
                                        });
                                    }
                                    swal("Success", "Login successful.", "success").then(() => window.location.reload());
                                }
                            }}
                            responseType={"code"}
                            responseMode={"query"}
                            usePopup={true}
                            render={renderProps => (
                                <button className='social_button' onClick={() => { setAppleSpinner(true); renderProps.onClick(); }}> <Apple style={{ marginRight: '5px' }} fontSize='18px' />    Apple</button>
                            )}
                            designProp={
                                {
                                    height: 30,
                                    width: 140,
                                    color: "black",
                                    border: false,
                                    type: "sign-in",
                                    border_radius: 15,
                                    scale: 1,
                                    locale: "en_US",
                                }
                            }
                        />

                    </LoadingSpinnerComponent>
                </div>
            </div>
            <br />
            <span className='auth_form_link'>Want to register as an organizer? <span className='auth_link' onClick={() => window.open(`${process.env.REACT_APP_ADMIN_PANEL}/register`)}> Register here.</span></span>
        </div>
    )
}
