import { localStorageEnums } from "../constants/LocalStorageEnums";

const token = window.localStorage.getItem(localStorageEnums.TOKEN);
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const headers = {
    'Content-Type': 'application/json',
    "User-Agent": `timeZone/${(timeZone && timeZone) || ""}`,
    "X-Custom-user-agent": `timeZone/${(timeZone && timeZone) || ""} platform/web uniqueId/ version/1.0.`,
};

if (token !== null) {
    headers.Authorization = `Bearer ${token}`
}

export default headers;

