import React from 'react'
import "../../styles/components/Footer.css";
import logo from "../../Images/Logo_V.png"
import appleStore from "../../Images/appstore.png";
import googleStore from "../../Images/googleplay.png";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';
import { getConfigs } from '../../Services/Auth.services';

export const Footer = () => {
    const [configLinks, setConfigLinks] = React.useState();
    const [activeLinks, setActiveLinks] = React.useState({
        home: true,
        search: false,
        leagues: false,
        pickup: false,
        tournaments: false
    });

    React.useEffect(() => {

        const fetchConfigs = async () => {
            const response = await getConfigs();
            const data = await response.data;
            setConfigLinks(data?.data);
        }
        fetchConfigs();
    }, []);

    React.useEffect(() => {
        if (window.location.pathname === "/") {
            setActiveLinks({
                home: true,
                search: false,
                leagues: false,
                pickup: false,
                tournaments: false
            });
        }
        else if (window.location.pathname.includes("search")) {
            setActiveLinks({
                home: false,
                search: true,
                leagues: false,
                pickup: false,
                tournaments: false
            });
        }
        else if (window.location.pathname.includes("league")) {
            setActiveLinks({
                home: false,
                search: false,
                leagues: true,
                pickup: false,
                tournaments: false
            });
        }
        else if (window.location.pathname.includes("tournament")) {
            setActiveLinks({
                home: false,
                search: false,
                leagues: false,
                pickup: false,
                tournaments: true
            });
        }
        else if (window.location.pathname.includes("pickup")) {
            setActiveLinks({
                home: false,
                search: false,
                leagues: false,
                pickup: true,
                tournaments: false
            });
        }
        else {
            setActiveLinks({
                home: false,
                search: false,
                leagues: false,
                pickup: false,
                tournaments: false
            });
        }
        // eslint-disable-next-line
    }, [window.location.pathname]);


    return (
        <div className='footer'>
            <img src={logo} className="footer_logo" alt="img" />
            <ul className='navbar_div_navbar' style={{ "listStyle": "none" }}>
                <li>
                    <Link className="router_link" to="/" onClick={() => setActiveLinks({
                        home: true,
                        search: false,
                        leagues: false,
                        pickup: false,
                        tournaments: false
                    })}>
                        <div className="navlinks_link">
                            <div className='navlinks_link_div'>
                                <p className={activeLinks.home ? 'navlinks_link_div_head_active' : 'navlinks_link_div_head'}>HOME</p>
                            </div>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link className="router_link" to="/search-by-maps" onClick={() => setActiveLinks({
                        home: false,
                        search: true,
                        leagues: false,
                        pickup: false,
                        tournaments: false
                    })}>
                        <div className="navlinks_link">
                            <div className='navlinks_link_div'>
                                <p className={activeLinks.search ? 'navlinks_link_div_head_active' : 'navlinks_link_div_head'}>SEARCH BY MAP</p>
                            </div>
                        </div>
                    </Link>
                </li>
                <li >
                    <Link className="router_link" to="/upcoming-leagues" onClick={() => setActiveLinks({
                        home: false,
                        search: false,
                        leagues: true,
                        pickup: false,
                        tournaments: false
                    })}>
                        <div className="navlinks_link">
                            <div className='navlinks_link_div'>
                                <p className={activeLinks.leagues ? 'navlinks_link_div_head_active' : 'navlinks_link_div_head'}>UPCOMING LEAGUES</p>
                            </div>
                        </div>
                    </Link>
                </li>
                <li >
                    <Link className="router_link" to="/pickup-games" onClick={() => setActiveLinks({
                        home: false,
                        search: false,
                        leagues: false,
                        pickup: true,
                        tournaments: false
                    })}>
                        <div className="navlinks_link">
                            <div className='navlinks_link_div'>
                                <p className={activeLinks.pickup ? 'navlinks_link_div_head_active' : 'navlinks_link_div_head'}>PICKUP GAMES</p>
                            </div>
                        </div>
                    </Link>
                </li>
                <li >
                    <Link className="router_link" to="/upcoming-tournaments" onClick={() => setActiveLinks({
                        home: false,
                        search: false,
                        leagues: false,
                        pickup: false,
                        tournaments: true
                    })}>
                        <div className="navlinks_link">
                            <div className='navlinks_link_div'>
                                <p className={activeLinks.tournaments ? 'navlinks_link_div_head_active' : 'navlinks_link_div_head'}>UPCOMING TOURNAMENTS</p>
                            </div>
                        </div>
                    </Link>
                </li>
            </ul>
            <div className='app_stores_footer'>
                <a href={configLinks?.iosUrl}><img className='stores_img' alt="img" src={appleStore} /></a>
                <a href={configLinks?.androidUrl}><img className='stores_img' alt="img" src={googleStore} /></a>
            </div>
            <div className='footer_social_flex'>
                <a href={configLinks?.facebookUrl} className='footer_social_div'>
                    <FacebookIcon />
                </a>
                <a href={configLinks?.instaUrl} className='footer_social_div'>
                    <InstagramIcon />
                </a>
                <a href={configLinks?.twitterUrl} className='footer_social_div'>
                    <TwitterIcon />
                </a>
                <a href={configLinks?.linkedinUrl} className='footer_social_div'>
                    <LinkedInIcon />
                </a>
            </div>
            <div className='footer_heading'>
                © {new Date().getFullYear()}. Assymbly.  All rights reserved.  <a href={configLinks?.termsConditions} className="no_decoration_link">Terms & Conditions</a>   | <a href={configLinks?.privacyPolicy} className="no_decoration_link">Privacy Policy</a>
            </div>
            <div className='footer_heading'>
                Contact us  : <a href={`mailto:${configLinks?.contactUs}`} className="no_decoration_link">{configLinks?.contactUs}</a>
            </div>
        </div>
    )
}
