import React from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import "../../styles/NotFoundPage.css";
import { Button } from '@mui/material';

export const NotFoundPage = () => {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    return (
        <div>
            <Helmet>
                <title>Page Not Found | Assymbly</title>
            </Helmet>
            <div className='upcoming_leagues_background_img'>
                <div className='leagues_details'>
                    <div className='bg_event_date_details'>
                        Page Not Found
                    </div>
                    <div className='breadcrumb'>
                        <Link className="router_link" to="/">Home</Link> | <span className='lighter_breadcrumb'>Page Not Found</span>
                    </div>
                </div>
            </div>
            <div className='not_found_page_div'>
                <h1>The page you are looking for does not exists.</h1>
            </div>
            <div className="not_found_page_div_home_link">
                <Link className='not_found_page_div_link' to="/">
                    <Button color='warning' variant='contained'>
                        Home
                    </Button>
                </Link>
            </div>
        </div>
    )
}
