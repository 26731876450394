import React from 'react'
import { Helmet } from "react-helmet";
import ImageSlider from '../../components/Slider/Slider';
import "../../styles/EventDetails.css";
import teamPriceIcon from "../../Images/ring-one.png";
import individualPriceIcon from "../../Images/reduce-user.png";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import ic_venue_img from "../../Images/ic_venueImg.png";
import ShareIcon from '@mui/icons-material/Share';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import formatIcon from "../../Images/connection-point.png";
import games from "../../Images/sport.png";
import refree from "../../Images/Group 6944.png"
import ageIcon from "../../Images/Age_png.png";
import pickupLogo from "../../Images/pickup-logo.png";
import leagueLogo from "../../Images/league-logo.png";
import tournamentLogo from "../../Images/tournament-logo.png";
import FormControl from '@mui/material/FormControl';
import { EventDates } from './components/dates';
import { EVentLocation } from './components/location';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import { fetchEventsDetailsById, registerButtonApi } from '../../Services/Events.services';
import { useParams } from 'react-router-dom';
import { TYPES } from '../../constants/SportTypes';
import { LoadingSpinnerComponent } from '../../components/LoadingSpinnerComponent';
import { filteredEvents, getUserDetailsPageView } from '../../Utils/helpers';
import SliderModal from '../../components/SliderModal';
import TransitionsModal from '../../components/Modal';
import { localStorageEnums } from '../../constants/LocalStorageEnums';
import { UpcomingLists } from './HomePageComponents/UpcomingTournaments';
import { PickUpGamesLists } from './HomePageComponents/PickupGame';
import logo from "../../Images/assmbly_logo.jpeg";
import ImageSliderSm from '../../components/Slider/SliderSm';
import { SportsCategoryInfo } from './components/sportsCategory';
import { abbreviate, capitalizeFirstLetter } from '../../Utils/abbreviators';
import StyledSnackbar from '../../components/StyledSnackbar';
import { Button } from '@mui/material';
import socialShareIcon from "../../Images/social-media.png";
import maleLogo from "../../Images/male.png";
import femaleLogo from "../../Images/female.png";
import coedLogo from "../../Images/coed.png";
import { GenderCategoryInfo } from './components/genderCategoryInfo';
import { genderTranslation } from '../../Utils/genderHelper';
import { SEGMENT_ACTIONS } from '../../Utils/Favored/actions';
import { getCategoryUsingFor } from "../../Utils/helpers";
import { combineCustomTrackSegmentPixel, combinePageViewSegmentPixel } from '../../Utils/Favored';
import { OS } from '../../Utils/getDeviceInfo';

export const EventDetails = (props) => {
    const { id, event } = useParams();
    const [openModal, setOpenModal] = React.useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const searchText = useSelector((state) => state.dataManager.searchText);
    const [upcoming, setUpcoming] = React.useState([]);
    const [eventDetails, setEventDetails] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openPriceDesc, setOpenPriceDesc] = React.useState(false);
    const [openFormatDesc, setOpenFormatDesc] = React.useState(false);
    const userData = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA));
    const [venueIndex, setVenueIndex] = React.useState(0);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        combinePageViewSegmentPixel(getUserDetailsPageView(userData, "Event Details"));
        document.getElementById("searchInput").value=searchText;
        // eslint-disable-next-line
    }, [id]);

    React.useEffect(() => {
        const fetchList = async () => {
            try {
                setSpinner(true);
                setEventDetails();
                const response = await fetchEventsDetailsById(id);
                if (response.status === 200) {
                    const data = await response.data;
                    setEventDetails(data?.data?.event);
                    setUpcoming(data?.data?.upcoming);
                }
                setSpinner(false);
            } catch (err) {
                setSpinner(false);
                swal('Error', err.response?.data?.message || "", 'error');
            }
        };
        if (id) {
            fetchList();
        }
        // eslint-disable-next-line
    }, [id]);

    const registerButton = async () => {
        if (window.localStorage.getItem(localStorageEnums.TOKEN)) {
            const segmentObj = {
                eventId: id,
                userId: userData?.id,
                usingFor: getCategoryUsingFor(userData?.usingFor || "")
            }
            combineCustomTrackSegmentPixel(SEGMENT_ACTIONS.CLICKS_REGISTER_BTN, segmentObj)
            const response = await registerButtonApi(eventDetails?.eventSport[0]?.id, eventDetails?.id);
            if (response.status === 201 || 200){
                const allowPopUp = ["Windows", "Android"];
                const val = OS(window);
                if(allowPopUp.indexOf(val) !== -1)
                    openWebsite(eventDetails?.registrationLink, '_blank');
                else    
                    openWebsite(eventDetails?.registrationLink, '_self');
            }
        }
        else {
            props?.handleOpen();
        }
    }

    const handleClick = () => {
        setOpenSnack(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    };

    const [snackbarData, setSnackBarData] = React.useState({
        xPos: 'center',
        yPos: 'bottom',
        title: '',
        status: 'success'
    });

    const openWebsite = (website, newTab) => {
        if (website.includes("http")) {
            window.open(
                website,
                newTab,
                'noopener,noreferrer'
            )
        } else {
            window.open(
                `https://${website}`,
                newTab,
                'noopener,noreferrer'
            )
        }
    }

    const responsiveOptions = {
        0: {
            items: 1,
            nav: true
        },
        480: {
            items: 1,
            nav: true
        },
        768: {
            items: 2,
            nav: true
        },
        1014: {
            items: 2,
            nav: true
        },
        1015: {
            items: 2,
            nav: true
        }
    }

    const copyCall = () => {
        setOpenModal(false);
        navigator.clipboard.writeText(eventDetails?.dynamicLink);
        setSnackBarData({
            xPos: 'center',
            yPos: 'bottom',
            title: 'Copied to clipboard',
            status: 'success'
        });
        handleClick();
    };

    return (
        <div className='event_details_main_body' id="fb-fire-pixel">
            <Helmet>
                <title>{eventDetails?.name || ""} | Assymbly</title>
            </Helmet>
            <div className='event_details_main'>
                <SliderModal open={open} handleClose={() => setOpen(false)}>
                    <div className='event_details_main_flex'>
                        <div className='event_details_main_modal'>
                            <ImageSliderSm slides={eventDetails?.eventVenue[venueIndex]?.venue?.venueImage || []} />
                        </div>
                        <div className='event_details_main_modal_name'>
                            <h2>{eventDetails?.eventVenue[venueIndex]?.venue?.name || ""}</h2>
                            <div className='event_details_location_flex'>
                                <PlaceOutlinedIcon style={{ color: "#E074B2" }} /> <span className='event_details_date_title'>{eventDetails?.eventVenue[venueIndex]?.venue?.address}<br /></span>
                            </div>
                            <div className='event_details_venue_amenities'>
                                <p>Venue Amenities</p>
                                <div className='event_details_venue_amenities_flex_modal'>
                                    {
                                        eventDetails?.eventVenue[venueIndex]?.venue?.venueAmenity?.map(amenity => <div className='event_details_venue_amenities_flex_card' key={amenity?.amenity?.name}>
                                            <img alt={amenity?.amenity?.name} src={`${process.env.REACT_APP_CLOUDFRONT}/${amenity?.amenity?.iconUrl}`} className='icon_img' /> <span>{amenity?.amenity?.name}</span>
                                        </div>)
                                    }
                                </div>
                            </div>
                            <div className='event_details_venue_description_div'>
                                <p className='event_details_venue_description_title'>Venue Description</p>
                                <p className='event_details_venue_description'>{eventDetails?.eventVenue[venueIndex]?.venue?.description}</p>
                            </div>
                        </div>
                    </div>
                </SliderModal>
                {/* <PDFModal open={openPdf} handleClose={() => setOpenPdf(false)}>
                    <PDFReader url={`${process.env.REACT_APP_CLOUDFRONT}/${eventDetails?.formatUrl}`} />
                </PDFModal> */}
                <div className='event_details_flex_carousel'>
                    <div className='carousel_div'>
                        <LoadingSpinnerComponent loader={spinner}>
                            <ImageSlider slides={eventDetails?.eventImage || []} />
                        </LoadingSpinnerComponent>
                    </div>
                    <TransitionsModal open={openModal} handleClose={() => setOpenModal(false)}>
                        <div className='dynamic_link_img_div'>
                            <img className='dynamic_link_img' alt='social_share_img' src={socialShareIcon} />
                        </div>
                        <div className='dynamic_link_div'>
                            <span className='dynamic_link'>{abbreviate(eventDetails?.dynamicLink, 36)}</span>
                            <Button color='warning' onClick={copyCall}>Copy</Button>
                        </div>
                    </TransitionsModal>
                    <div className='event_details_div'>
                        {eventDetails ?
                            <>
                                <span className='event_detail_heading'>{eventDetails?.name} <ShareIcon className='share_icon_cursor' onClick={() => setOpenModal(!openModal)} /></span>
                                <Link to={`/organizer-details/${eventDetails?.organizer?.userId}`} className='no_decoration_link_href'>
                                    <div className='event_detail_organizer'>
                                        <img src={eventDetails?.organizer?.organizerLogo ? `${process.env.REACT_APP_CLOUDFRONT}/${eventDetails.organizer?.organizerLogo}` : logo} className='event_organizer_profile_pic' alt="Hi" /> <span>{eventDetails?.organizer?.name}</span>
                                    </div>
                                </Link>
                                <div className='divider_with_spacing' />
                                <SportsCategoryInfo title={eventDetails?.eventSport[0]?.name || ""} src={eventDetails?.eventSport[0]?.icon} />
                                <GenderCategoryInfo title={capitalizeFirstLetter(eventDetails?.type) || ""} src={eventDetails?.type === 'pickup' ? pickupLogo : eventDetails?.type === "league" ? leagueLogo : tournamentLogo} />
                                <GenderCategoryInfo title={genderTranslation(eventDetails?.gender)} src={eventDetails?.gender === 'male' ? maleLogo : eventDetails?.gender === "female" ? femaleLogo : coedLogo} />
                                <EventDates endTimeNull={eventDetails?.endTimeNull} startDate={eventDetails?.eventVenue[0]?.startDate} endDate={eventDetails?.eventVenue[0]?.endDate} />
                                <EVentLocation longitude={eventDetails?.eventVenue[venueIndex]?.venue?.longitude} latitude={eventDetails?.eventVenue[venueIndex]?.venue?.latitude} address={eventDetails?.eventVenue[venueIndex]?.venue?.address} city={eventDetails?.eventVenue[venueIndex]?.venue?.city} country={eventDetails?.eventVenue[venueIndex]?.venue?.country} postcode={eventDetails?.eventVenue[venueIndex]?.venue?.postcode} state={eventDetails?.eventVenue[venueIndex]?.venue?.state} />
                                <div className='event_details_venue_amenities'>
                                    {eventDetails?.eventVenue && <div className='venue_select_control_flex'><FormControl color='warning' style={{ width: '90%', marginTop: "15px" }}>
                                        <InputLabel id="demo-simple-select-label">Venue</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={venueIndex}
                                            label="Venue"
                                            onChange={(e) => setVenueIndex(e.target.value)}
                                        >{
                                                eventDetails?.eventVenue.map((venue, index) => <MenuItem key={index} value={index}>{venue?.venue?.name}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl><div className='icon_color_with_spacing' onClick={() => setOpen(!open)}>

                                            <img src={ic_venue_img} alt="ic_venue_img" /></div></div>}
                                    <p className='event_details_heading'>Venue Amenities</p>
                                    <div className='event_details_venue_amenities_flex'>
                                        {
                                            eventDetails?.eventVenue[venueIndex]?.venue?.venueAmenity?.map(amenity => <div className='event_details_venue_amenities_flex_card' key={amenity?.amenity?.name}>
                                                <img alt={amenity?.amenity?.name} src={`${process.env.REACT_APP_CLOUDFRONT}/${amenity?.amenity?.iconUrl}`} className='icon_img' /> <span>{amenity?.amenity?.name}</span>
                                            </div>)
                                        }
                                    </div>
                                </div>
                                <div className='divider_fees' />
                                <div className='event_details_venue_amenities'>
                                    <p className='event_details_heading'>League Details</p>
                                    <div className='event_details_rule_flex_div'>
                                        <div className='event_details_league_flex'>
                                            <div className='event_details_league_card' title={eventDetails?.formatDescription || ""} onClick={() => setOpenFormatDesc(!openFormatDesc)}>
                                                <img alt="format_icon" src={formatIcon} className='icon_color_0pad nav_icon' /> <span>Format<br /><span className='subheading_league_card'>{abbreviate(eventDetails?.formatDescription, 60)}</span></span>
                                            </div>
                                        </div>
                                        <div className='event_details_game_info_flex'>
                                            {eventDetails.noOfGames !== 0 && <div className='event_details_rules_card'>
                                                <img alt='games' src={games} className='icon_color nav_icon' /> <span>Games: {eventDetails?.noOfGames}</span>
                                            </div>}
                                            <div className='event_details_rules_card'>
                                                <img alt='refree' src={refree} className='icon_color nav_icon_whistle' /> <span>Referee: {eventDetails?.refree ? "Yes" : "No"}</span>
                                            </div>
                                            <div className='event_details_rules_card'>
                                                <img className='icon_color_img' alt="age limit icon" src={ageIcon} /><span>{eventDetails?.ageLimit}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <SliderModal open={openFormatDesc} handleClose={() => setOpenFormatDesc(false)}>
                                    <h2>Format Description</h2>
                                    <p>{eventDetails?.formatDescription}</p>
                                    {eventDetails?.formatUrl && <a href={`${process.env.REACT_APP_CLOUDFRONT}/${eventDetails?.formatUrl}`} target="_blank" rel="noreferrer" className='pdf_link'>Preview PDF</a>}
                                </SliderModal>
                                <TransitionsModal open={openPriceDesc} handleClose={() => setOpenPriceDesc(false)}>
                                    <h2>Price Description</h2>
                                    <p>{eventDetails?.priceDescription}</p>
                                </TransitionsModal>
                                <div className='divider_fees' />
                                <div className='fees_plan_div'>
                                    <p className='event_details_heading'>Fees Plan</p>
                                </div>
                                <OwlCarousel responsive={responsiveOptions} dots={true} dotsEach={true} className='event_details_rules_flex' margin={3} navText={["<div class='nav-btn prev-slide-fees'></div>", "<div class='nav-btn next-slide-fees'></div>"]}>
                                    {eventDetails?.eventPrice?.map((price, index) => <div className='ticket_price_div' key={index} style={{ textAlign: "center" }}><div>
                                        <h4>{price?.priceType}</h4>
                                    </div>
                                        <div className='event_details_rules_flex_sub_div'>
                                            {(price?.teamPrice >= 0 && eventDetails?.type !== 'pickup') && <div className='event_details_fees_card'>
                                                <img alt='price_icon' src={teamPriceIcon} className='icon_lg' />
                                                <div className='event_details_fees_card_right'>
                                                    <span>Teams</span>
                                                    <span className='event_details_fees_card_heading'>${price?.teamPrice}</span>
                                                </div>
                                            </div>}
                                            <div className='event_details_fees_card'>
                                                <img alt='price_icon' src={individualPriceIcon} className='icon_lg' />
                                                <div className='event_details_fees_card_right'>
                                                    <span>Individuals</span>
                                                    <span className='event_details_fees_card_heading'>${price?.individualPrice}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                                </OwlCarousel>
                                <div className='price_desc_div_clamped' title={eventDetails?.priceDescription}>{eventDetails?.priceDescription}</div>
                                <div className='register_button_div_md'>
                                    <button className='register_button' onClick={() => registerButton()}>
                                        Register
                                    </button>
                                </div> </> : <div className='loader_div'>
                                <LoadingSpinnerComponent loader={spinner}>
                                    <p>No data available</p>
                                </LoadingSpinnerComponent>
                            </div>}
                    </div>
                </div>
            </div>
            {event === TYPES.TOURNAMENT && <UpcomingLists type="Upcoming Tournaments" events={filteredEvents(upcoming, searchText) || []} background="white" linkTo={"tournaments"} />}
            {event === TYPES.LEAGUE && <UpcomingLists type="Upcoming Leagues" events={filteredEvents(upcoming, searchText) || []} background="white" linkTo={"leagues"} />}
            {event === TYPES.PICKUP && <PickUpGamesLists events={filteredEvents(upcoming, searchText) || []} background="white" />}
            <StyledSnackbar
                xPos={snackbarData.xPos}
                yPos={snackbarData.yPos}
                title={snackbarData.title}
                status={snackbarData.status}
                open={openSnack}
                onClose={handleClose}
            />
        </div>
    )
}
