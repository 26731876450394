import React from 'react'
import { Icon } from '@iconify/react'
import moment from 'moment';
import { SportsNearbyCard } from '../Cards/SportsNearbyCard';
import "../../styles/components/homepage_main.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import { DATE_FORMAT_WITH_TIME } from '../../constants/DateFormat';

export const SportsNearby = ({ city, leagues, pickup, tournament, coordinates, children }) => {

    const responsiveOptions = {
        0: {
            items: 1,
            nav: false
        },
        480: {
            items: 1,
            nav: false
        },
        768: {
            items: 2,
            nav: false
        },
        1014: {
            items: 2,
            nav: false
        },
        1015: {
            items: 3,
            nav: false
        }
    }

    return (
        <div className='hompage_subcomponent_nearby'>
            <div className='hompage_subcomponent_header'>
                {city.length > 0 && <div>
                    <p className='hompage_subcomponent_heading'>Sports Events Nearby</p>
                    <div className='location_flex_div'>
                        <Icon icon="fluent:location-12-filled" color="#c23c86" fontSize={"1.6rem"} />
                        <span className='location_title'>{city}</span>
                    </div>
                </div>}
                {children}
            </div>
            {(leagues.length !== 0 || pickup.length !== 0 || tournament.length !== 0) ?
                <OwlCarousel responsive={responsiveOptions} dots={true} dotsEach={true} className='sports_category_grid_dots' margin={10}>
                    {leagues.length > 0 &&
                        <SportsNearbyCard
                            id={leagues[0]?.id}
                            coordinates={coordinates}
                            latitude={leagues[0]?.eventVenue[0]?.venue?.latitude}
                            longitude={leagues[0]?.eventVenue[0]?.venue?.longitude}
                            type="league"
                        src={`${process.env.REACT_APP_CLOUDFRONT}/${(leagues[0]?.eventImage[0]?.src).replace(" ", "%20") }`}
                            name={leagues[0]?.name}
                            location={leagues[0]?.eventVenue[0]?.venue?.address}
                            dynamicLink={leagues[0]?.dynamicLink}
                            date={moment(leagues[0]?.eventVenue[0]?.startDate).format(DATE_FORMAT_WITH_TIME)} />}

                    {pickup.length > 0 && <SportsNearbyCard
                        id={pickup[0]?.id}
                        coordinates={coordinates}
                        latitude={pickup[0]?.eventVenue[0]?.venue?.latitude}
                        longitude={pickup[0]?.eventVenue[0]?.venue?.longitude}
                        type="pickup" src={`${process.env.REACT_APP_CLOUDFRONT}/${(pickup[0]?.eventImage[0]?.src).replace(" ", "%20")}`}
                        name={pickup[0]?.name} location={pickup[0]?.eventVenue[0]?.venue?.address}
                        dynamicLink={pickup[0]?.dynamicLink}
                        date={moment(pickup[0]?.eventVenue[0]?.startDate).format(DATE_FORMAT_WITH_TIME)} />}

                    {tournament.length > 0 && <SportsNearbyCard
                        id={tournament[0]?.id}
                        coordinates={coordinates}
                        latitude={tournament[0]?.eventVenue[0]?.venue?.latitude}
                        longitude={tournament[0]?.eventVenue[0]?.venue?.longitude}
                        type="tournament"
                        src={`${process.env.REACT_APP_CLOUDFRONT}/${(tournament[0]?.eventImage[0]?.src).replace(" ", "%20") }`}
                        name={tournament[0]?.name}
                        location={tournament[0]?.eventVenue[0]?.venue?.address}
                        dynamicLink={tournament[0]?.dynamicLink}
                        date={moment(tournament[0]?.eventVenue[0]?.startDate).format(DATE_FORMAT_WITH_TIME)} />}
                </OwlCarousel>
                :
                <div className='no_events_available_div'>
                    <p>No Events available.</p>
                </div>}
        </div>
    )
}
