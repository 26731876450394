import axios from 'axios';
import instance from '../Interceptor';
import headers from '../Utils/headers';

export const AuthRegisterServices = async (formBody, notification) => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/auth/register`,
    {
      method: 'POST',
      headers: headers,
      data: JSON.stringify({
        name: formBody.fullName,
        email: formBody.email,
        password: formBody.password,
        using_for: 'child',
        loc_lat: formBody.loc_lat,
        loc_long: formBody.loc_long,
        address: formBody.address,
        notification: true,
        role: 'user',
      }),
    }
  );
  return response;
};

export const AuthLoginServices = async (formBody) => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/auth/login`,
    {
      method: 'POST',
      headers: headers,
      data: JSON.stringify({
        email: formBody.email,
        password: formBody.password,
        role: ['user'],
      }),
    }
  );
  return response;
};

export const getGoogleUserDetails = async (token) => {
  const response = await instance(
    `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${token}`,
    {
      method: 'GET',
    }
  );
  return response;
};

export const googleLogin = async (user, token) => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/auth/social-login`,
    {
      method: 'POST',
      headers: headers,
      data: JSON.stringify({
        name: user.name ? user.name : `user${Date.now()}`,
        email: user.email,
        provider: 'google',
        accessToken: token,
        provider_id: user?.sub,
      }),
    }
  );
  return response;
};

export const appleLogin = async (resp) => {
  try {
    const { authorization, user } = resp;
    const response = await instance(
      `${process.env.REACT_APP_SERVER_API}/auth/social-login`,
      {
        method: 'POST',
        headers: headers,
        data: JSON.stringify({
          name: `${user?.name?.lastName || ''} ${user?.name?.firstName || ''}`,
          provider: 'apple',
          accessToken: authorization.id_token,
          provider_id: authorization?.code || authorization?.grant_code,
        }),
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const AuthForgotPasswordServices = async (formBody) => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/auth/forgot-password`,
    {
      method: 'POST',
      headers: headers,
      data: JSON.stringify({
        email: formBody.email,
        role: 'user',
      }),
    }
  );
  return response;
};

export const AuthUserInfoServices = async () => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/user/info`,
    {
      method: 'GET',
      headers: headers,
    }
  );
  return response;
};

export const AuthUpdateUserInfoServices = async (
  formBody,
  lattitude,
  longitude,
  address,
  notification,
  isOnboardingCompleted
) => {
  let request_body = {};
  if (lattitude && longitude) {
    request_body = {
      using_for: formBody.usingFor,
      loc_lat: lattitude,
      loc_long: longitude,
      address: address,
      notification: notification,
    };
  } else {
    request_body = {
      using_for: formBody.usingFor,
      notification: notification,
    };
  }
  if (!isOnboardingCompleted) {
    request_body.is_profile_complete = true;
  }
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/user/update`,
    {
      method: 'POST',
      headers: headers,
      data: JSON.stringify(request_body),
    }
  );
  return response;
};

export const AuthOTPVerificationServices = async (email, otp) => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/auth/verify-otp`,
    {
      method: 'POST',
      headers: headers,
      data: JSON.stringify({
        email: email,
        otp: Number(otp),
      }),
    }
  );
  return response;
};

export const AuthResetPasswordServices = async (email, otp, values) => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/auth/reset-password`,
    {
      method: 'POST',
      headers: headers,
      data: JSON.stringify({
        email: email,
        otp: Number(otp),
        password: values.password,
      }),
    }
  );
  return response;
};

export const AuthChangePasswordServices = async (value) => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/user/change-password`,
    {
      method: 'POST',
      headers: headers,
      data: JSON.stringify({
        password: value,
      }),
    }
  );
  return response;
};

export const getAddress = async (data) => {
  const { latitude, longitude } = data;
  const placeInfo = await getPlacesFromCoordinates(latitude, longitude);
  const address = getPlaceDetails(placeInfo, true);
  return address;
};

export const getPlaceDetails = (placeInfo, isGeocodingAPI) => {
  if (!isGeocodingAPI) {
    return {
      title: `${placeInfo?.result?.address_components[0]?.long_name || '-'}, ${
        placeInfo?.result?.address_components[1]?.long_name || '-'
      }`,
      mapId: placeInfo.result?.place_id,
      placeLocation: placeInfo.result?.geometry?.location,
    };
  } else {
    return {
      title: `${
        placeInfo?.results[0]?.address_components[0]?.long_name || '-'
      }, ${placeInfo?.results[0]?.address_components[1]?.long_name || '-'}`,
      mapId: placeInfo.results[0]?.place_id,
      placeLocation: placeInfo.results[0]?.geometry?.location,
    };
  }
};

export const getPlacesFromCoordinates = async (lat, lng) => {
  let apiKey = process.env.REACT_APP_GCP_KEY;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
  const response = await axios.get(url);
  return response.data;
};

export const getCityService = async ({ lat, lng }) => {
  const url = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lng}`;
  const response = await axios.get(url);
  return response.data;
};

export const getConfigs = async () => {
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/configs`,
    {
      method: 'GET',
      headers: headers,
    }
  );
  return response;
};


export const deleteAccountAPI = async ({ id }) => {
  
  const response = await instance(
    `${process.env.REACT_APP_SERVER_API}/user/${id}`,
    {
      method: 'DELETE',
      headers: headers
    }
  );
  return response;
};