import { getUserDetailsIdentify } from "../../helpers";

export const SegmentIdentifyUser = (data) => {
    window.analytics.identify(data.id, getUserDetailsIdentify(data));
}

export const SegmentTrackUser = (action, obj) => {
    window.analytics.track(action, obj);
}

export const SegmentPageAnalytics = (title, obj) => {
    window.analytics.page(title, obj);
}