import axios from 'axios';
import { localStorageEnums } from '../constants/LocalStorageEnums';



const instance = axios.create({
    headers: {}
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(localStorageEnums.TOKEN);
        if (token != null) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error?.response?.status === 401) {
            window.localStorage.clear();
            window.location.reload();
        }
        return Promise.reject(error);

    }
);

export default instance;
