import React from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useSelector } from 'react-redux';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { PickupGamesCard } from '../../components/Cards/PickupGamesCard';
import { LoadingComponent } from '../../components/LoadingComponent';
import TransitionsModal from '../../components/Modal';
import { SearchBox } from '../../components/SearchBox';
import { Button } from '@mui/material';
import { SearchBar } from '../../components/SearchBar';
import { combinePageViewSegmentPixel } from '../../Utils/Favored';
import { localStorageEnums } from '../../constants/LocalStorageEnums';
import { getUserDetailsPageView } from '../../Utils/helpers';

export const PickUpPage = () => {
    const pickup = useSelector((state) => state.dataManager.pickup);
    const loader = useSelector((state) => state.dataManager.loader);
    const searchText = useSelector((state) => state.dataManager.searchText);
    const coordinates = useSelector((state) => state.dataManager.coordinates);
    const [openSearchBox, setOpenSearchBox] = React.useState(false);
    const userData = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA));

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById("searchInput").value = searchText;
        combinePageViewSegmentPixel(getUserDetailsPageView(userData, "Pickup Games"));
        // eslint-disable-next-line
    }, [])

    return (
        <div className='main_div_leagues'>
            <Helmet>
                <title>Pickup Games | Assymbly</title>
            </Helmet>
            <div className='upcoming_leagues_background_img'>
                <div className='leagues_details'>
                    <div className='bg_event_date_details'>
                        Pickup Games
                    </div>
                    <div className='breadcrumb'>
                        <Link className="router_link" to="/">Home</Link> | <span className='lighter_breadcrumb'> Pickup Games </span>
                    </div>
                </div>
            </div>
            <TransitionsModal open={openSearchBox} handleClose={() => setOpenSearchBox(false)}>
                <SearchBox handleClose={() => setOpenSearchBox(false)} hideEventType={true} />
            </TransitionsModal>
            <Button className='search_box_mobile_view_button' onClick={() => setOpenSearchBox(true)}><FilterAltIcon style={{ color: 'black' }} /></Button>
            <SearchBar hideEventType={true} clearFilter={true} />
                { !loader && (pickup?.events || [])?.length === 0 &&
                    <div className='spinner_center_div'>
                        <p>No events available.</p>
                    </div>}
            <LoadingComponent loader={loader}>
            <div className='pickup_page_grid'>
                {(pickup?.events || [])?.map((event) => <PickupGamesCard key={event?.id} coordinates={coordinates} dynamicLink={event?.dynamicLink} latitude={event?.eventVenue[0]?.venue?.latitude} longitude={event?.eventVenue[0]?.venue?.longitude} id={event.id} type={event?.type} src={`${process.env.REACT_APP_CLOUDFRONT}/${event.eventImage[0]?.src}`} title={event.name} organizer={event?.organizer?.name} charge={event.individualPrice} />)}
            </div>
            </LoadingComponent>
        </div>
    )
}
