import React from 'react';
import '../../styles/components/MarkerPin.css';
import { HoveredCard } from './hoveredCard';

const MarkerPin = (props) => {
    const { color, name, src, defaultMarker, data } = props;
    const [toggle, setToggle] = React.useState(false);
    return (
        defaultMarker ?
            <>
                <div
                    className={` ${src === null ? "default_pin" : "pin"} bounce`}
                    style={{ backgroundColor: color, cursor: 'pointer' }}
                />
                <div className="pulse" /></> :
            <div onMouseOver={() => setToggle(true)} onMouseOut={() => setToggle(false)}>
                <div
                    className={` ${src === null ? "default_pin" : "pin"} bounce`}
                    style={{ backgroundColor: color, cursor: 'pointer' }}
                />
                {src && data.length === 1 ? <img alt={name} className='marker_img img_bounce' src={`${process.env.REACT_APP_CLOUDFRONT}/${src}`} /> : <h2 className='marker_img img_bounce'>+{data.length}</h2>}
                {(data?.length > 0 && toggle) && <div className='scroll_events_hovered_div'> {data?.map(ev => <HoveredCard key={ev.eventId} toggle={toggle} event={ev} name={name} category={data[0].category} />)}</div>}
                <div className="pulse" />
            </div >
    );
};

export default MarkerPin;