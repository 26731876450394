import { localStorageEnums } from "../../constants/LocalStorageEnums.js";
import { customTrackAppFlyer, identifyAppFlyer, pageViewAppFlyer } from "./AppFlyer/helper.js";
import { PixelIdentifyUser, PixelPageView, PixelCustomTrack } from "./MetaPixel/helpers.js";
import { SegmentIdentifyUser, SegmentPageAnalytics, SegmentTrackUser } from "./Segment/helpers.js";

const token = window.localStorage.getItem(localStorageEnums.TOKEN);

export const combineIdentifySegmentPixel = (action, data) => {
    PixelIdentifyUser(action,data);
    SegmentIdentifyUser(data);
    identifyAppFlyer(data?.userId || data.id);
}

export const combinePageViewSegmentPixel = (obj) => {
    if (!token)
        return;
    PixelPageView(obj);
    SegmentPageAnalytics(obj);
    pageViewAppFlyer(obj);
}

export const onBoardingCustomEvent = (action, data) => {
    PixelCustomTrack(action, data);
    SegmentTrackUser(action, data);
    customTrackAppFlyer(action, data);
}

export const combineCustomTrackSegmentPixel = (action, data) => {
    if (!token)
        return;
    PixelCustomTrack(action, data);
    SegmentTrackUser(action, data);
    customTrackAppFlyer(action, data);
}