import React from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getDistance } from '../../Utils/DistanceCalculator';
import { abbreviate } from '../../Utils/abbreviators';
import ShareIcon from '@mui/icons-material/Share';
import { localStorageEnums } from '../../constants/LocalStorageEnums';
import pin from "../../Images/local.png";
import "../../styles/components/Cards.css";
import TransitionsModal from '../Modal';
import Button from '@mui/material/Button';
import socialShareIcon from "../../Images/social-media.png"
import StyledSnackbar from '../StyledSnackbar';
import { DATE_FORMAT_WITH_TIME } from '../../constants/DateFormat';

export const UpcomingCard = (props) => {
    const [open, setOpen] = React.useState(false);
    const locationStatus = window.localStorage.getItem(localStorageEnums.LOCATIONSTATUS);
    const [openModal, setOpenModal] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const [snackbarData, setSnackBarData] = React.useState({
        xPos: 'center',
        yPos: 'bottom',
        title: '',
        status: 'success'
    });

    const copyCall = () => {
        setOpenModal(false);
        navigator.clipboard.writeText(props?.dynamicLink);
        setSnackBarData({
            xPos: 'center',
            yPos: 'bottom',
            title: 'Copied to clipboard',
            status: 'success'
        });
        handleClick();
    };


    return (
        <Link className='no_decoration_link_card' style={{ marginBottom: '5px', marginTop: '5px', paddingBottom: '5px' }}>
            <TransitionsModal open={openModal} handleClose={() => setOpenModal(false)}>
                <div className='dynamic_link_img_div'>
                    <img className='dynamic_link_img' alt='social_share_img' src={socialShareIcon} />
                </div>
                <div className='dynamic_link_div'>
                    <span className='dynamic_link'>{abbreviate(props?.dynamicLink, 36)}</span>
                    <Button color='warning' onClick={copyCall}>Copy</Button>
                </div>
            </TransitionsModal>
            <div className='upcoming_league_card_main'>
                {props?.dynamicLink && <div className='share_float_button_right_sm' style={{ top: '18px' }} onClick={() => setOpenModal(true)}>
                    <ShareIcon style={{ color: 'white' }} />
                </div>}
                <Link className='no_decoration_link_card' style={{ marginBottom: '5px', marginTop: '5px', paddingBottom: '5px' }} to={`/event/${props.type}/${props?.id}`}>

                    <div className='upcoming_league_card' style={{ backgroundImage: `url('${props?.src}')` }}>
                        {(locationStatus === "granted" && !isNaN(getDistance(props?.coordinates?.lat, props?.coordinates?.lng, props?.latitude, props?.longitude))) && <div className='miles_in_card'><><img className='local_pin' alt='pin' src={pin} /> {getDistance(props?.coordinates?.lat, props?.coordinates?.lng, props?.latitude, props?.longitude)} miles</> </div>}
                        <img src={props.src} className="upcoming_cover" alt="img" />
                        {props?.featured && <div className='featuring_in_card'>Featured</div>}
                    </div>
                    <p className='upcoming_league_card_date'>{moment(props?.date).format(DATE_FORMAT_WITH_TIME)}</p>
                    <span className='upcoming_league_card_name' title={props?.name}>{abbreviate(props?.name, 22)}</span>
                    <span className='upcoming_league_card_club'>{props?.club}</span>
                </Link>
            </div>
            <StyledSnackbar
                xPos={snackbarData.xPos}
                yPos={snackbarData.yPos}
                title={snackbarData.title}
                status={snackbarData.status}
                open={open}
                onClose={handleClose}
            />
        </Link>
    )
}
