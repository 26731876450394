import React from 'react'
import "../../styles/components/homepage_main.css";
import { UpcomingCard } from '../Cards/UpcomingCard';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const UpcomingLeagues = (props) => {

    const coordinates = useSelector((state) => state.dataManager.coordinates);

    return (
        <div className='hompage_subcomponent_grey' style={{ backgroundColor: `${props.background}` }}>
            <div className='hompage_subcomponent_header'>
                <div>
                    <span className='location_title'>Upcoming Leagues</span>
                </div>
                {props?.events?.length > 0 && <div className='view_all_link'>
                    <Link className='nodecoration_color_link' to={props.id ? `/upcoming-leagues/${props?.id}` : `/upcoming-leagues`}>
                        View All
                    </Link>
                </div>}
            </div>
            {props?.events?.length > 0 ? <div className='sports_category_grid'>
                {props?.events?.map((event, index) => <UpcomingCard key={event?.id} featured={index === 0} coordinates={coordinates} latitude={event?.eventVenue[0]?.venue?.latitude} longitude={event?.eventVenue[0]?.venue?.longitude} id={event?.id} type={event?.type} src={`${process.env.REACT_APP_CLOUDFRONT}/${event?.eventImage[0]?.src}`} name={event?.name} location={event?.address} date={event?.eventVenue[0]?.startDate} club={event?.organizer.name} dynamicLink={event.dynamicLink} />)}
            </div> :
                <div className='no_events_available_div'>
                    <p>No Events available.</p>
                </div>}
        </div>
    )
}
