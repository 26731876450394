import React from 'react'
import "../../styles/components/homepage_main.css";
import { PickupGamesCard } from '../Cards/PickupGamesCard';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const PickUpGames = (props) => {
    const coordinates = useSelector((state) => state.dataManager.coordinates);

    return (
        <div className='hompage_subcomponent' style={{ backgroundColor: `${props.background}` }}>
            <div className='hompage_subcomponent_header'>
                <div>
                    <span className='location_title'>Pickup Games</span>
                </div>
                {props?.events?.length > 0 && <div className='view_all_link'>
                    <Link className='nodecoration_color_link' to={props.id ? `/pickup-games/${props?.id}` : `/pickup-games`}>
                        View All
                    </Link>
                </div>}
            </div>
            {
                props?.events?.length > 0 ?
                    <div className='pickup_grid'>
                        {props?.events?.map((event) =>
                            <PickupGamesCard key={event?.id} coordinates={coordinates} latitude={event?.eventVenue[0]?.venue?.latitude} longitude={event?.eventVenue[0]?.venue?.longitude} id={event?.id} type={event?.type} src={`${process.env.REACT_APP_CLOUDFRONT}/${event?.eventImage[0]?.src}`} title={event?.name} organizer={event?.organizer?.name} charge={event?.individualPrice} dynamicLink={event?.dynamicLink} />)}
                    </div> :
                    <div className='no_events_available_div'>
                        <p>No Events available.</p>
                    </div>}
        </div>
    )
}
