import React from 'react'
import { UpcomingCard } from '../../../components/Cards/UpcomingCard';
import { Link } from 'react-router-dom';

export const UpcomingLists = (props) => {

    return (
        <div className='hompage_subcomponent_grey' style={{ backgroundColor: `${props.background}` }}>
            <div className='hompage_subcomponent_header'>
                <div>
                    <span className='location_title'>{props?.type}</span>
                </div>
                <div className='view_all_link'>
                    <Link className='nodecoration_color_link' to={props.id ? `/upcoming-${props?.linkTo}/${props?.id}` : `/upcoming-${props?.linkTo}`}>
                        View All
                    </Link>
                </div>
            </div>
            {
                props?.events?.length > 0 ?
                    <div className='sports_category_grid'>
                        {props?.events?.map((event, index) => <UpcomingCard key={event?.eventId} featured={index === 0} id={event.eventId} type={event?.type} src={`${process.env.REACT_APP_CLOUDFRONT}/${event?.logoUrl}`} name={event.name} location={event.address} date={event?.startDate} club={event.organizer.name} />)}
                    </div> :
                    <div className='no_events_available_div'>
                        <p>No Events available.</p>
                    </div>}
        </div>
    )
}
