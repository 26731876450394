import React from 'react'
import inactiveHome from "../../Images/inactive-home.png";
import activeHome from "../../Images/active-home.png";
import inactiveSearch from "../../Images/inactive-search.png";
import activeSearch from "../../Images/active-search.png";
import "../../styles/components/AppBar.css";
import { useHistory } from 'react-router-dom';
export const AppBar = () => {

    const history = useHistory();

    const [appIcons, setAppIcons] = React.useState({
        home: true,
        search: false,
    });

    const clickHandler = (prop) => {
        if (prop === "home") {
            history.push("/");
            setAppIcons({
                home: true,
                search: false
            })
        }
        else if (prop === "search") {
            history.push("/search-by-maps");
            setAppIcons({
                home: false,
                search: true
            })
        }
    }


    React.useEffect(() => {
        if (window.location.pathname.includes("search")) {
            setAppIcons({
                home: false,
                search: true
            });
        }
        else if (window.location.pathname === "/") {
            setAppIcons({
                home: true,
                search: false
            });
        }
        // eslint-disable-next-line
    }, [window.location.pathname]);

    return (
        <div className='appbar'>
            <div className='appbar_div'>
                <div className='app_tab'>

                    {appIcons.home ?
                        <img alt="app_bar_icons" className='appbar_icon' src={activeHome} onClick={() => clickHandler("home")} />
                        :
                        <img alt="app_bar_icons" className='appbar_icon' src={inactiveHome} onClick={() => clickHandler("home")} />}
                    <span className={appIcons.home ? "active_tab" : "inactive_tab"}>Home</span>
                </div>

                <div className='app_tab'>
                    {appIcons.search ?
                        <img alt="app_bar_icons" className='appbar_icon' src={activeSearch} onClick={() => clickHandler("search")} /> :
                        <img alt="app_bar_icons" className='appbar_icon' src={inactiveSearch} onClick={() => clickHandler("search")} />
                    }
                    <span className={appIcons.search ? "active_tab" : "inactive_tab"}>Search</span>
                </div>
            </div>
        </div>
    )
}
