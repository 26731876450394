export const identifyAppFlyer = (id) => {
    window.AF('pba', 'setCustomerUserId', id);
}

export const pageViewAppFlyer = (obj) => {
    window.AF('pba', 'event', { eventType: 'EVENT', eventName: 'Viewed', eventRevenue: 0, url: window.location.href,  ...obj });
}

export const customTrackAppFlyer = (action, obj) => {
    window.AF('pba', 'event', { eventType: 'EVENT', eventName: action, eventRevenue: 0, url: window.location.href, ...obj });
}