export const googleAutocompleteStyles = {
    control: () => ({
        border: 'none',
        display: 'flex',
        alignItems: "center",
        borderRadius: '5px',
        minHeight: '52px',
        maxWidth: window.innerWidth > '330px' ? '330px' : '300px',
        maxHeight: '52px',
        cursor: 'pointer',
        overflow: 'hidden',
        whiteSpace: "nowrap",
        overflowWrap: "break-word",
        textOverflow: "ellipsis"
    }),
    input: () => ({
        color: 'black',
        focus: 'yellow',
    }),
    menu: () => ({
        position: 'absolute',
        minWidth: '100%',
        boxShadow: '0 0 5px #ccc',
        backgroundColor: 'white',
        zIndex: '999999'
    }),
    option: () => ({
        color: 'black',
        backgroundColor: 'white',
        zIndex: '9999'
    }),
    singleValue: () => ({
        color: 'black',
        marginTop: '20px'
    }),
}