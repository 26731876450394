import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import "../../styles/components/Navbar.css";
import logoBig from "../../Images/Logo_H.png";
import SearchIcon from '@mui/icons-material/Search';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import profileImg from "../../Images/profile.png";
import settingImg from "../../Images/settings.png";
import deleteImg from "../../Images/delete.png";
import signout from "../../Images/signout.png";
import search from "../../Images/bx-search-alt.png"
import { localStorageEnums } from '../../constants/LocalStorageEnums';
import { abbreviate } from '../../Utils/abbreviators';
import { getFavouritesListService, getNotificationList, markAsReadService } from '../../Services/Events.services';
import swal from 'sweetalert';
import * as Sentry from "@sentry/react";
import { FavUnFavOrg } from '../../Services/OrganizerDetails.services';
import { LoadingSpinnerComponent } from '../LoadingSpinnerComponent';
import { insertSearchText } from '../../store/features/Data';
import { useDispatch } from 'react-redux';
import defaultLogo from "../../Images/assmbly_logo.jpeg"
import { Badge } from '@mui/material';
import { SEGMENT_ACTIONS } from '../../Utils/Favored/actions';
import { combineCustomTrackSegmentPixel } from '../../Utils/Favored';
import { getCategoryUsingFor } from "../../Utils/helpers";

let useClickOutside = (handler) => {
    let domNode = React.useRef();

    React.useEffect(() => {
        let maybeHandler = (event) => {
            if (!domNode.current.contains(event.target)) {
                handler();
            }
        };
        document.addEventListener("mousedown", maybeHandler);

        return () => {
            document.removeEventListener("mousedown", maybeHandler);
        };
    });

    return domNode;
};

export const Navbar = (props) => {
    const dispatch = useDispatch();
    const authorised = window.localStorage.getItem(localStorageEnums.TOKEN) ? true : false;
    const userData = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA));

    const [open, setOpen] = React.useState({
        search: false,
        heart: false,
        alert: false,
        profile: false
    });

    const [activeLinks, setActiveLinks] = React.useState({
        home: true,
        search: false,
        leagues: false,
        pickup: false,
        tournaments: false
    });

    const [favouriteList, setFavouriteList] = React.useState([]);
    const [broadcastList, setBroadcastList] = React.useState([]);
    const noScrollForPathNames = ["/upcoming-leagues", "/upcoming-tournaments"];


    const [spinnerFav, setSpinnerFav] = React.useState(true);
    const [spinnerAlert, setSpinnerAlert] = React.useState(false);

    const logoutHandler = () => {
        window.localStorage.removeItem(localStorageEnums.TOKEN);
        window.localStorage.removeItem(localStorageEnums.USERDATA);
        window.localStorage.removeItem(localStorageEnums.USERID);
        Sentry.setUser(null);
        window.location.reload();
    }

    const getFavouriteList = async () => {
        try {
            setSpinnerFav(true);
            const response = await getFavouritesListService();
            const data = await response.data;
            setFavouriteList(data?.data?.data || []);
            setSpinnerFav(false);
        } catch (err) {
            setSpinnerFav(false);
            swal("Error", err.response?.data?.message || "", "error");
        }
    }

    const fetchList = async () => {
        try {
            setSpinnerAlert(true);
            const response = await getNotificationList();
            if (response.status === 200) {
                const data = await response.data;
                setBroadcastList(data?.data?.notifications || []);
                await markAsReadService();
                setNotificationCount(0);
            }
            setSpinnerAlert(false);
        } catch (err) {
            setSpinnerAlert(false);
            swal('Error', err.response?.data?.message || "", 'error');
        }
    };

    const [notificationCount, setNotificationCount] = React.useState(0);


    React.useEffect(() => {
        const fetchList = async () => {
            try {
                setSpinnerAlert(true);
                const response = await getNotificationList();
                if (response.status === 200) {
                    const data = await response.data;
                    setBroadcastList(data?.data?.notifications || []);
                    setNotificationCount(data?.data?.notificationCount || 0);
                }
                setSpinnerAlert(false);
            } catch (err) {
                setSpinnerAlert(false);
                swal('Error', err.response?.data?.message || "", 'error');
            }
        };
        if (authorised)
            fetchList();
        // eslint-disable-next-line
    }, [])

    const toggleFavourite = async (id, value) => {
        try {
            await FavUnFavOrg(id, value);
            const segmentObj = {
                organizerId: id,
                userId: userData?.id,
                usingFor: getCategoryUsingFor(userData?.usingFor || ""),
                isFavorite: false
            }
            combineCustomTrackSegmentPixel(SEGMENT_ACTIONS.CLICKS_UNFAVOURITE_BTN, segmentObj);
            getFavouriteList();
        }
        catch (err) {
            swal("Error", err.response?.data?.message || "", "error");
        }
    }

    const togglePopUp = (prop, value) => {
        if (prop === "search") {
            if (!value) {
                const searchTag = document.getElementById('searchInput');
                searchTag.focus();
                if (!noScrollForPathNames.find(pathname => pathname === (window.location.pathname)))
                    window.scrollTo({ top: 650, left: 0, behavior: "smooth" });
            }
            else
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            setOpen({
                heart: false,
                search: !value,
                alert: false,
                profile: false
            });
        }
        else if (prop === "heart") {
            setOpen({
                heart: !value,
                search: false,
                alert: false,
                profile: false
            });
            getFavouriteList();
        }
        else if (prop === "alert") {
            setOpen({
                heart: false,
                search: false,
                alert: !value,
                profile: false
            });
            fetchList();
        }
        else {
            setOpen({
                heart: false,
                search: false,
                alert: false,
                profile: !value
            })
        }
    }

    let searchRefNode = useClickOutside(() => {
        if (open.search) {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
        setOpen({
            heart: false,
            search: false,
            alert: false,
            profile: false
        });
    });

    const changeHandler = (e) => {
        dispatch((insertSearchText(e.target.value)));
    };

    React.useEffect(() => {
        if (window.location.pathname === "/") {
            setActiveLinks({
                home: true,
                search: false,
                leagues: false,
                pickup: false,
                tournaments: false
            });
        }
        else if (window.location.pathname.includes("search")) {
            setActiveLinks({
                home: false,
                search: true,
                leagues: false,
                pickup: false,
                tournaments: false
            });
        }
        else if (window.location.pathname.includes("league")) {
            setActiveLinks({
                home: false,
                search: false,
                leagues: true,
                pickup: false,
                tournaments: false
            });
        }
        else if (window.location.pathname.includes("tournament")) {
            setActiveLinks({
                home: false,
                search: false,
                leagues: false,
                pickup: false,
                tournaments: true
            });
        }
        else if (window.location.pathname.includes("pickup")) {
            setActiveLinks({
                home: false,
                search: false,
                leagues: false,
                pickup: true,
                tournaments: false
            });
        }
        else {
            setActiveLinks({
                home: false,
                search: false,
                leagues: false,
                pickup: false,
                tournaments: false
            });
        }
        // eslint-disable-next-line
    }, [window.location.pathname]);

    const debouncedChangeHandler = useMemo(() => {
        return debounce(changeHandler, 600);
        // eslint-disable-next-line
    }, []);

    return (
        <header>
            <div className='navbar_div'>
                <div className='navbar_logo_div'>
                    <Link to="/" style={{ textDecoration: 'none' }} onClick={() => setActiveLinks({
                        home: true,
                        search: false,
                        leagues: false,
                        pickup: false,
                        tournaments: false
                    })}>
                        <img className='navbar_logo_name' src={logoBig} alt="logo_name" />
                    </Link>
                </div>
                <ul className='navbar_div_navbar'>
                    <li>
                        <Link className="router_link" to="/" onClick={() => setActiveLinks({
                            home: true,
                            search: false,
                            leagues: false,
                            pickup: false,
                            tournaments: false
                        })}>
                            <div className="navlinks_link">
                                <div className='navlinks_link_div'>
                                    <p className={activeLinks.home ? 'navlinks_link_div_head_active' : 'navlinks_link_div_head'} >HOME</p>
                                </div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link className="router_link" to="/search-by-maps" onClick={() => setActiveLinks({
                            home: false,
                            search: true,
                            leagues: false,
                            pickup: false,
                            tournaments: false
                        })}>
                            <div className="navlinks_link">
                                <div className='navlinks_link_div'>
                                    <p className={activeLinks.search ? 'navlinks_link_div_head_active' : 'navlinks_link_div_head'} >SEARCH BY MAP</p>
                                </div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link className="router_link" to="/upcoming-leagues" onClick={() => setActiveLinks({
                            home: false,
                            search: false,
                            leagues: true,
                            pickup: false,
                            tournaments: false
                        })}>
                            <div className="navlinks_link">
                                <div className='navlinks_link_div'>
                                    <p className={activeLinks.leagues ? 'navlinks_link_div_head_active' : 'navlinks_link_div_head'}>UPCOMING LEAGUES</p>
                                </div>
                            </div>
                        </Link>
                    </li>
                    <li >
                        <Link className="router_link" to="/pickup-games" onClick={() => setActiveLinks({
                            home: false,
                            search: false,
                            leagues: false,
                            pickup: true,
                            tournaments: false
                        })}>
                            <div className="navlinks_link">
                                <div className='navlinks_link_div'>
                                    <p className={activeLinks.pickup ? 'navlinks_link_div_head_active' : 'navlinks_link_div_head'}>PICKUP GAMES</p>
                                </div>
                            </div>
                        </Link>
                    </li>
                    <li >
                        <Link className="router_link" to="/upcoming-tournaments" onClick={() => setActiveLinks({
                            home: false,
                            search: false,
                            leagues: false,
                            pickup: false,
                            tournaments: true
                        })}>
                            <div className="navlinks_link">
                                <div className='navlinks_link_div'>
                                    <p className={activeLinks.tournaments ? 'navlinks_link_div_head_active' : 'navlinks_link_div_head'}>UPCOMING TOURNAMENTS</p>
                                </div>
                            </div>
                        </Link>
                    </li>
                </ul>
                <ul className='navbar_div_navbar_icons' ref={searchRefNode}>
                    <li>
                        <div className="navlinks_link">
                            <div className='navlinks_link_div'>
                                <img src={search} className='icon_link nav_icon' alt="search_icon" onClick={() => { togglePopUp("search", open.search); }} />
                                <div className='navbar_popper_triangle' style={{ opacity: !open.search ? '0' : '1' }}>
                                </div>
                            </div>

                            <div className='navbar_popper_div_center' style={{ height: !open.search ? '0px' : '100px', opacity: !open.search ? '0' : '1' }}>
                                <TextField id="searchInput" onChange={debouncedChangeHandler} inputProps={{
                                    style: { fontSize: 12 }
                                }} placeholder='Search by Event and Organizer name...' style={{ width: '90%' }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                    }} />
                            </div>
                        </div>
                    </li>
                    {authorised && <li>
                        <div className="navlinks_link">
                            <div className='navlinks_link_div'>
                                <FavoriteBorderIcon className='icon_link' onClick={() => togglePopUp("heart", open.heart)} />
                                <div className='navbar_popper_triangle' style={{ opacity: !open.heart ? '0' : '1' }}>
                                </div>
                                <div className='navbar_popper_div_mr' style={{ height: !open.heart ? '0px' : 'max-content', maxHeight: '250px', opacity: !open.heart ? '0' : '1' }}>
                                    <LoadingSpinnerComponent loader={spinnerFav}>
                                        {favouriteList.length === 0 ? <p style={{ color: 'black', fontSize: '15px' }}>No Favorites.</p> : favouriteList.map((fav, index) => <div className={fav.organizer.isDeactivated ? 'heart_div_row_disabled' : 'heart_div_row'} key={index}>
                                            <div className='align_heart'>
                                                <img alt="nav" className={fav.organizer.isDeactivated ? 'heart_logo_disabled' : 'heart_logo'} src={fav?.organizer?.organizerLogo ? `${process.env.REACT_APP_CLOUDFRONT}/${fav?.organizer?.organizerLogo}` : defaultLogo} />
                                                <Link to={`/organizer-details/${fav?.organizerId}`} className='no_decoration_link_card'> <span className={fav.organizer.isDeactivated ? 'heart_title_disabled' : 'heart_title'} onClick={() => setOpen({ ...open, heart: false })}>{abbreviate(fav?.organizer?.name, 15)}</span></Link>
                                            </div>
                                            <span className={fav.organizer.isDeactivated ? 'heart_subtitle_disabled' : 'heart_subtitle'}><FavoriteIcon className='icon_link' onClick={() => toggleFavourite(fav?.organizerId, false)} /></span>
                                        </div>)}
                                    </LoadingSpinnerComponent>
                                </div>
                            </div>
                        </div>
                    </li>}
                    {authorised && <li>
                        <div className="navlinks_link">
                            <div className='navlinks_link_div'>
                                <Badge badgeContent={notificationCount} color="warning">
                                    <NotificationsNoneOutlinedIcon onClick={() => togglePopUp("alert", open.alert)} className='icon_link' />
                                </Badge>
                                <div className='navbar_popper_triangle' style={{ opacity: !open.alert ? '0' : '1' }}></div>
                            </div>
                            <div className='navbar_popper_div_mr' style={{ height: !open.alert ? '0px' : 'max-content', maxHeight: '250px', opacity: !open.alert ? '0' : '1' }}>
                                <LoadingSpinnerComponent loader={spinnerAlert}>
                                    {broadcastList.length === 0 ? <p style={{ color: 'black', fontSize: '15px' }}>No Notifications.</p> : broadcastList.map((broadcast, index) => <div className={broadcast.isOrganizerDeactive ? 'alert_disabled' : 'alert_div_row_flex'} key={index}>
                                        <img src={broadcast.organizationLogo ? `${process.env.REACT_APP_CLOUDFRONT}/${broadcast.organizationLogo}` : defaultLogo} className={broadcast.isOrganizerDeactive ? "alert_disabled_img" : "alert_div_row_flex_icon"} alt='logo' />
                                        <div className='alert_div_row'>
                                            <span className={broadcast.isOrganizerDeactive ? 'alert_text_subheading_disabled' : 'alert_subtitle'} title={broadcast?.description}>{abbreviate(broadcast?.description, 65)}</span>
                                        </div>
                                    </div>)}
                                </LoadingSpinnerComponent>
                            </div>
                        </div>
                    </li>}
                    <li>
                        <div className="navlinks_link">
                            {authorised ?
                                <div className='navlinks_link_div'>
                                    <PersonOutlineOutlinedIcon className='icon_link' onClick={() => togglePopUp("profile", open.profile)} />
                                    <div className='navbar_popper_triangle' style={{ opacity: !open.profile ? '0' : '1' }}>
                                    </div>
                                </div> :
                                <div className='navlinks_link_div' onClick={() => { props.handleOpen() }} >
                                    <PersonAddIcon className='icon_link' />
                                </div>
                            }
                            <div className='navbar_popper_div' style={{ height: !open.profile ? '0px' : 'max-content', opacity: !open.profile ? '0' : '1', width: '180px', padding: '15px' }}>
                                <div className='profile_div_row' title={userData?.name || ""}>
                                    <img alt="nav" className='profile_logo' src={profileImg} />
                                    <span className='profile_title'>{userData?.name || ""}</span>
                                </div>
                                <div className='profile_div_row' onClick={() => {
                                    if (!window.localStorage.getItem(localStorageEnums.TOKEN))
                                        return;
                                    setOpen({
                                        heart: false,
                                        search: false,
                                        alert: false,
                                        profile: false
                                    });
                                    props.handleOpenProfile();
                                }}>
                                    <img alt="nav" className='profile_logo' src={settingImg} />
                                    <span className='profile_title'>Edit Profile</span>
                                </div>
                                <div className='profile_div_row' onClick={logoutHandler}>
                                    <img alt="nav" className='profile_logo' src={signout} />
                                    <span className='profile_title'>Sign Out</span>
                                </div>
                                <div className='profile_div_row' style={{ borderBottom: "none", paddingBottom: "0px" }} onClick={() => {
                                    if (!window.localStorage.getItem(localStorageEnums.TOKEN))
                                        return;
                                    setOpen({
                                        heart: false,
                                        search: false,
                                        alert: false,
                                        profile: false
                                    });
                                    props.handleOpenDeleteAccount();
                                }}>
                                    <img alt="nav" className='profile_logo' src={deleteImg} />
                                    <span className='profile_title'>Delete Account</span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </header>
    )
}

