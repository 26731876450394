import React from 'react'
import AppleLogin from 'react-apple-login';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import GoogleIcon from '@mui/icons-material/Google';
import { useGoogleLogin } from '@react-oauth/google';
import "./auth.css";
import { isValidEmail } from '../../Utils/validators';
import { MESSAGES } from '../../constants/ValidationMsgs';
import { appleLogin, AuthLoginServices, getGoogleUserDetails, googleLogin } from '../../Services/Auth.services';
import { localStorageEnums } from '../../constants/LocalStorageEnums';
import swal from 'sweetalert';
import { SCOPES } from '../../constants/Scopes';
import { LoadingText } from '../LoadingText';
import { LoadingSpinnerComponent } from '../LoadingSpinnerComponent';
import { SEGMENT_ACTIONS } from '../../Utils/Favored/actions';
import { combineIdentifySegmentPixel } from '../../Utils/Favored';
import * as Sentry from "@sentry/react";
import { Apple } from '@mui/icons-material';

export const LoginForm = (props) => {

    const [formBody, setFormBody] = React.useState({
        email: window.localStorage.getItem(localStorageEnums.EMAIL) || '',
        password: window.localStorage.getItem(localStorageEnums.PASSWORD) || ''
    });

    const [rememberMe, setRememberMe] = React.useState(window.localStorage.getItem(localStorageEnums.REMEMBER) || false);

    const [loader, setLoader] = React.useState(false);

    const [spinner, setSpinner] = React.useState(false);

    const [appleSpinner, setAppleSpinner] = React.useState(false);

    const [errors, setErrors] = React.useState({
        email: false,
        password: false
    });

    const [loginFailureResponse, setLoginFailureResponse] = React.useState({
        flag: false,
        msg: ''
    });

    const [values, setValues] = React.useState({
        showPassword: false,
    });

    const handleChange = async (e, param) => {
        if (param === "email") {
            setFormBody({
                ...formBody,
                email: e.target.value
            });
            setErrors({
                ...errors,
                email: !isValidEmail(e.target.value)
            })
        }
        else {
            setFormBody({
                ...formBody,
                [param]: e.target.value
            });
            setErrors({
                ...errors,
                password: !(e.target.value)
            })
        }
    }

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const submitForm = async () => {
        if (isValidEmail(formBody.email) && formBody.password) {
            try {
                setLoader(true);
                const response = await AuthLoginServices(formBody);
                const data = response.data;
                setLoader(false);
                if (response.status === 201) {
                    if (rememberMe) {
                        window.localStorage.setItem(localStorageEnums.REMEMBER, rememberMe);
                        window.localStorage.setItem(localStorageEnums.EMAIL, formBody.email);
                        window.localStorage.setItem(localStorageEnums.PASSWORD, formBody.password);
                    }
                    else {
                        window.localStorage.removeItem(localStorageEnums.REMEMBER);
                        window.localStorage.removeItem(localStorageEnums.EMAIL);
                        window.localStorage.removeItem(localStorageEnums.PASSWORD);
                    }
                    const { accessToken, user } = data?.data;
                    window.localStorage.setItem(localStorageEnums.TOKEN, accessToken);
                    window.localStorage.setItem(localStorageEnums.USERDATA, JSON.stringify(user));
                    combineIdentifySegmentPixel(SEGMENT_ACTIONS.USER_LOGINS, user);
                    if (accessToken) {
                        Sentry.setUser({
                            id: user?.id,
                            name: user?.name,
                            email: user?.name
                        });
                    }
                    props.handleClose();
                    swal("Success", "Login successful.", "success").then(() => window.location.reload());
                }
            } catch (err) {
                setLoader(false);
                setLoginFailureResponse({
                    flag: true,
                    msg: err.response.data.message
                })
            }
        }
        else {
            setErrors({
                ...errors,
                email: !isValidEmail(formBody.email),
                password: !formBody.password
            })
        }
    }


    const onSuccess = async (codeResponse) => {
        setSpinner(true);
        try {
            const response_google = await getGoogleUserDetails(codeResponse?.access_token);
            const user = await response_google.data;
            const response = await googleLogin(user, codeResponse?.access_token);
            const data = response.data;
            if (response.status === 201) {
                const { accessToken, user } = data?.data;
                window.localStorage.setItem(localStorageEnums.USERDATA, JSON.stringify(user));
                window.localStorage.setItem(localStorageEnums.TOKEN, accessToken);
                window.localStorage.setItem(localStorageEnums.USERID, user?.id);
                setSpinner(false);
                combineIdentifySegmentPixel(SEGMENT_ACTIONS.USER_LOGINS, user);
                if (accessToken) {
                    Sentry.setUser({
                        id: user?.id,
                        name: user?.name,
                        email: user?.name
                    });
                }
                swal("Success", "Login successful.", "success").then(() => window.location.reload());
            }
        }
        catch (err) {
            setSpinner(false);
            swal("Info", err.response?.data?.message || "", "info");
        };
    };
    const login = useGoogleLogin({
        flow: 'implicit',
        onSuccess: codeResponse => onSuccess(codeResponse),
        onError: err => {
            console.log(err)
        },
        scope: SCOPES
    });


    return (
        <div>
            <h1>Welcome Back</h1>
            <span className='auth_form_link' onClick={() => props.setAuthEnum(1)}>Don't have an account yet? <span className='auth_link'>Register here.</span></span>
            <div className='auth_form_div'>
                <div className='login_textfield_div'>
                    <TextField
                        color='warning'
                        id="outlined-basic"
                        style={{ width: '100%' }}
                        label="Email" variant="outlined"
                        type="email"
                        value={formBody.email}
                        error={loginFailureResponse.flag || errors.email}
                        autoComplete="off"
                        helperText={loginFailureResponse.flag ? loginFailureResponse.msg : errors.email ? MESSAGES.EMAILREQUIRED : null}
                        onChange={(e) => handleChange(e, "email")} />
                </div>
                <div className='login_textfield_div'>
                    <FormControl color='warning' style={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={formBody.password}
                            style={{ width: '100%' }}
                            onChange={(e) => handleChange(e, 'password')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                        {loginFailureResponse.flag ?

                            <FormHelperText error id="accountId-error">
                                {loginFailureResponse.msg}
                            </FormHelperText>
                            : errors.password && (
                                <FormHelperText error id="accountId-error">
                                    {MESSAGES.PASSWORDREGQUIRED}
                                </FormHelperText>
                            )}
                    </FormControl>
                </div>
                <FormGroup style={{ maxWidth: 'max-content' }}>
                    <FormControlLabel control={<Checkbox checked={rememberMe} color="warning" onChange={() => setRememberMe(!rememberMe)} />} label="Remember me" />
                </FormGroup>
                <button className='auth_button' onClick={submitForm} disabled={loader}>
                    <LoadingText text="Sign in" loader={loader} />
                </button>
            </div>
            <div className='auth_link_orange'>
                <p onClick={() => props.setAuthEnum(2)}>Forgot your password?</p>
            </div>
            <div className='divider' />
            <div className='social_links_div'>
                <p className='social_div_heading'>Or sign in with your social network</p>
                <div className='social_div_buttons'>
                    <LoadingSpinnerComponent loader={spinner}>
                        <button className='social_button' onClick={login}> <GoogleIcon style={{ marginRight: '5px' }} fontSize='18px' />    Google</button>
                    </LoadingSpinnerComponent>
                    <LoadingSpinnerComponent loader={appleSpinner}>
                        <AppleLogin
                            clientId={process.env.REACT_APP_APPLE_CLIENT_ID}
                            redirectURI={`https://${window.location.hostname}/`}
                            callback={async(response) => {
                                if (response.error) {
                                setAppleSpinner(false);
                                console.log(response.error);
                                }
                                const responseLogin = await appleLogin(response);
                                const data = responseLogin.data;
                                if (responseLogin.status === 201) {
                                    const { accessToken, user } = data?.data;
                                    window.localStorage.setItem(localStorageEnums.USERDATA, JSON.stringify(user));
                                    window.localStorage.setItem(localStorageEnums.TOKEN, accessToken);
                                    window.localStorage.setItem(localStorageEnums.USERID, user?.id);
                                    setAppleSpinner(false);
                                    combineIdentifySegmentPixel(SEGMENT_ACTIONS.USER_LOGINS, user);
                                    if (accessToken) {
                                        Sentry.setUser({
                                            id: user?.id,
                                            name: user?.name,
                                            email: user?.name
                                        });
                                    }
                                    swal("Success", "Login successful.", "success").then(() => window.location.reload());
                                }
                                setAppleSpinner(false);
                            }}
                            scope="email name"
                            responseType="code"
                            responseMode="query"
                            usePopup={true}
                            render={renderProps => (  
                                <button className='social_button' onClick={() =>{ setAppleSpinner(true); renderProps.onClick();}}> <Apple style={{ marginRight: '5px' }} fontSize='18px' />    Apple</button>
                            )}
                            designProp={
                                {
                                    height: 30,
                                    width: 140,
                                    color: "black",
                                    border: false,
                                    type: "sign-in",
                                    border_radius: 15,
                                    scale: 1,
                                    locale: "en_US",
                                }
                            }
                        />
                        
                    </LoadingSpinnerComponent>
                </div>
            </div>
        </div>
    )
}
